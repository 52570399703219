import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { loadCounterConfigAction } from '../actions/counter'
import { RootState } from '../reducers/rootReducer'
import Helmet from 'react-helmet'
import Header from '../layout/Header'
import Content from '../layout/Content';
import MobileFooterAction from '../layout/MobileFooterAction'

import TopContent from './counter/TopContent';
import WindowAccordion from './counter/WindowAccordion';
import FreeTimeModal from './counter/FreeTimeModal';
import { initOrder } from '../actions/order';
import DiscountBlock from './counter/DiscountBlock';
import star from '../static/img/Star.svg'
import Notification from '../components/Notification/Notification'
import { getOrderInitPayload } from '../utils/customSelectors';
import { COUNTER_CONFIG_ID } from '../utils/globals';
import PostalCodeValidation from './counter/PostalCodeValidation';
// import BasePriceInfoBlock from '../components/BasePriceInfoBlock';
import VideoGuide from './counter/VideoGuide';


const Kirkaslaskuri: React.FC<{}>  = () => {
    const {config /*, isLoading, error*/} = useSelector((state: RootState) => state.counter);
    const {windows} = useSelector((state: RootState) => state.order.order);
    const orderInitialValues = useSelector((state:RootState) => getOrderInitPayload(state));

    const dispatch = useDispatch();
 
    // useEffect(() => ReactGA.pageview(getPageUrl()),[])

    useEffect(() => {
        if(!config){
            dispatch(loadCounterConfigAction(COUNTER_CONFIG_ID));
        }
        if(orderInitialValues && config && windows.length === 0){
            dispatch(initOrder(orderInitialValues)); 
        }
    }, [dispatch, config, windows, orderInitialValues]);


    return (
        <>
            <Helmet>
                <title>Ikkunanpesun hintalaskuri - Kirkaslasi</title>
                <meta name="description" content="Laske ikkunanpesun hinta helposti parissa minuutissa ja tilaa pesu" />
                <meta name="keywords" content="Ikkunanpesun hinta, Ikkunanpesun hintalaskuri, Kirkaslasi, Ikkunanpesu, Ikkunanpesu netistä" />
            </Helmet>
            <Header/>
            <Content >
                <>
                    <PostalCodeValidation />
                    <VideoGuide playerWidth={720} playerHeight={405} />
                    <Notification />
                    {/* <BasePriceInfoBlock /> todo: check translations for this to use...*/}
                    <div className="landingpage-page-content p-grid">
                        <TopContent />
                        <WindowAccordion />
                        <div className="p-col-12 bottom star">
                            <img src={star} alt="tahti" />
                        </div>
                        <FreeTimeModal placeClass="bottom" />
                        <DiscountBlock placeClass="bottom" />
                    </div>
                </>
            </Content>
            <MobileFooterAction toPath="/tilauskalenteri"/>
        </>
    )
}

export default Kirkaslaskuri;
