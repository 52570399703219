export const GET_DISCOUNTS = "GET_DISCOUNTS";
export const GET_DISCOUNT = "GET_DISCOUNT";
export const POST_CREATE_DISCOUNT = "POST_CREATE_DISCOUNT";
export const PUT_UPDATE_DISCOUNT = "PUT_UPDATE_DISCOUNT";
export const DELETE_DISCOUNT = "DELETE_DISCOUNT";
export const VALIDATE_DISCOUNT_CODE = "VALIDATE_DISCOUNT_CODE";
export const SET_DISCOUNT_CODE = "SET_DISCOUNT_CODE";


export enum DISCOUNT_TYPE {
    UNDEFINED="UNDEFINED",
    PERCENT = "PERCENT",
    FIXED = "FIXED"
}

export enum DISCOUNT_CODE_STATES {
    NO_CODE = 'NO_CODE',
    VALID = 'VALID',
    INVALID = 'INVALID'
}