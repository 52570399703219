import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation_en from '../static/translations/translation_en.json'
import translation_fi from '../static/translations/translation_fi.json';

export const defaultNS = 'translation';

export const resources = {
    fi: {
        translation: translation_fi 
    },
    en: {
        translation: translation_en
    }
} as const;

// const lng = navigator ? navigator.language.substring(0, 2) : 'fi';

i18n.use(initReactI18next).init({
    lng: 'fi', // if you're using a language detector, do not define the lng option
    fallbackLng: 'fi',
    debug: false,
    defaultNS,
    resources,
    returnNull: false,
});

export default i18n;