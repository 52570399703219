import React, { /*MutableRefObject, useRef,*/ useState } from 'react'
import Layout from '../components/Layout'
//import Title from '../components/Title'
//import { Messages } from 'primereact/messages'
import { Menu } from 'primereact/menu';
import PostalAreaSettings from './postalarea/PostalAreaSettings'
import CleanersSettings from './cleaners/CleanersSettings';

export enum TAB_IDS {
    CLEANERSETTINGSCODE = 0,
    POSTALAREASETTINGS = 1,
}

const SettingsPage: React.FC<{}> = () => {

    const [activeTab, setActiveTab] = useState<number>(TAB_IDS.CLEANERSETTINGSCODE);
//    const messagesRef:MutableRefObject<any> = useRef(); 
    
    /*
    const pushMessage = (severity: string, msg: string) => {
        messagesRef.current.show({
            severity: severity,
            detail: msg,
            life: 15000
        });
    }*/

    let tabs = [
        {
            label: 'Postinumeroalueet',
            items: [{
                label: 'Postinumeroalueiden hallinta', 
                icon: 'pi pi-fw pi-plus', 
                command:()=> { setActiveTab(TAB_IDS.POSTALAREASETTINGS)}}]
        },
        {
            label: 'Pesijät',
            items: [{
                label: 'Pesijöiden hallinta', 
                icon: 'pi pi-fw pi-plus', 
                command:()=> { setActiveTab(TAB_IDS.CLEANERSETTINGSCODE)}}]
        },
    ]

    const getActiveTab = (tabNumber:number) => {
        
        switch (tabNumber){
            case TAB_IDS.CLEANERSETTINGSCODE: return <CleanersSettings />;
            case TAB_IDS.POSTALAREASETTINGS: return <PostalAreaSettings />;
            default: return <CleanersSettings />;
        }
    }


    return (
        <Layout>
            <div className="settings-page p-d-flex">
                <div className="settings-menu p-mr-2">
                        <Menu model={tabs} />
                </div>
                <div className="settings-tab p-mr-8">
                    {getActiveTab(activeTab)}
                </div>
                {/*<Messages ref={messagesRef} />
                <div className="content-header">
                    <Title type="h1">Asetukset</Title>
                </div>
                */}
            </div>
        </Layout>
    )
}

export default SettingsPage;