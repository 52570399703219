import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux';
import { postSuccessPayment } from '../../actions/payment'
import { ProgressSpinner } from 'primereact/progressspinner';
import Joi from 'joi'
import { RootState } from '../../reducers/rootReducer';
import Header from '../../layout/Header';
import Content from '../../layout/Content';
import ReactGA from 'react-ga4';
import { GA_CATEGORY, GA_ACTION } from '../../utils/analytics.constant';
import ReactPixel from 'react-facebook-pixel';
import { useTranslation } from 'react-i18next';

/*
https://localhost:3000/payment/success?
checkout-account=375917&
checkout-algorithm=sha256&
checkout-amount=13&
checkout-stamp=d2568f2a-e4c6-40ba-a7cd-1587022565883&
checkout-reference=9187445&
checkout-transaction-id=ee7db96e-7fb4-11ea-81b2-f3bbb4ac300d&
checkout-status=ok&checkout-provider=osuuspankki&
signature=e689d218fda0f13f264b3cc96e2af1959c4804e1fce67366ca3428cb9d88c1fd

https://localhost:3000/payment/success?checkout-account=375917&checkout-algorithm=sha256&checkout-amount=1390&checkout-stamp=d2568f2a-e4c6-40ba-a7cd-1589141698459&checkout-reference=1fd8237b-7235-41f5-85cc-ad27eca2205d&checkout-transaction-id=ebf80718-92fa-11ea-8fe5-33e5333ee761&checkout-status=ok&checkout-provider=osuuspankki&signature=1fd6ff183f78d850af977307354cb129aea6221697abe57243582ffc98e46142
http://localhost:3000/payment/success?checkout-account=375917&checkout-algorithm=sha256&checkout-amount=1860&checkout-stamp=d2568f2a-e4c6-40ba-a7cd-1607280569674&checkout-reference=1&checkout-transaction-id=c5b3b5e0-37f3-11eb-86bc-47079ff93f74&checkout-status=ok&checkout-provider=osuuspankki&signature=4c7754f521adad80134dadfb0afce277bce36c8cf9361e483315b76db06bc62c
*/

const SUCCESS_SCHEMA = Joi.object({
    "checkout-account": Joi.number().integer().required(),
    "checkout-algorithm": Joi.string().required(),
    "checkout-amount": Joi.number().integer().required(),
    "checkout-provider": Joi.string().required(),
    "checkout-reference": Joi.string().required(),
    "checkout-stamp": Joi.string().required(),
    "checkout-status": Joi.string().required(),
    "checkout-transaction-id": Joi.string().required(),
    signature: Joi.string().required(),
})

const SuccessPayment: React.FC<{}> = () => {
    const [reference, setReference] = useState('')
    const [successSent, setSuccessSent] = useState(false)
    const [priceToAnalytics, setPriceToAnalytics] = useState<number>(0)
    const { error, isLoading, paymentValid } = useSelector((state: RootState) => state.payment);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const parsedQueryString = queryString.parse(useLocation().search);

    // useEffect(() => ReactGA.pageview(getPageUrl()),[])

    useEffect(() => {
        if (!successSent) {
            dispatch(postSuccessPayment(validateAndParseQueryString(parsedQueryString)))
            setSuccessSent(true)
        }

    }, [dispatch, parsedQueryString, successSent])


    useEffect(() => {
        if (paymentValid && !error) {
            ReactGA.event({
                category: GA_CATEGORY,
                action: GA_ACTION.ORDER,
                label: 'ONLINE_PAYMENT',
                value: priceToAnalytics / 100
            })
            ReactPixel.track('Purchase', {
                currency: "EUR",
                value: priceToAnalytics
            });
            history.push(`/kiitos-tilauksesta/${reference}`);
        }
    }, [history, priceToAnalytics, paymentValid, error, reference])

    const validateAndParseQueryString = (queryString: queryString.ParsedQuery<string>) => {
        const validation = SUCCESS_SCHEMA.validate(queryString)
        if (validation.error) {
            console.log(validation.error)
            return false;
        }
        // Replace "-"" character with an "_" character
        // reference = order_id
        // @ts-ignore
        setReference(queryString['checkout-reference'])
        const convertedQString = JSON.parse(JSON.stringify(queryString).replace(/-/g, '_'));
        convertedQString['checkout_account'] = parseInt(convertedQString['checkout_account'], 10)
        convertedQString['checkout_amount'] = parseInt(convertedQString['checkout_amount'], 10)
        setPriceToAnalytics(convertedQString['checkout_amount']);
        return convertedQString;
    }

    return <>
        <Header />
        <Content >
            <div className="success-payment-page p-grid p-justify-center">
                {isLoading &&
                    <>
                        <div className="page-head p-col-12">
                            <div className="box">
                                <div className="page-title">{t('payment.confirming_payment')}...</div>
                            </div>
                        </div>
                        <div className="">
                            <div className="loading-spinner">
                                <ProgressSpinner />
                            </div>
                        </div>
                    </>
                }
                {error &&
                    <>
                        <h2 className="main-title">{t('payment.error_title')}</h2>
                        <div className="">
                            {t('payment.error_msg1')} {reference}. <br /> 
                            {t('payment.error_msg2')}                           
                        </div>
                    </>
                }
            </div>
        </Content>
    </>;
}

export default SuccessPayment;