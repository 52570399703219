import React from 'react'
import { useTranslation } from 'react-i18next'


const HouseHoldDeductionContent: React.FC = () => {
    const { t } = useTranslation();

    return <>
        <p>{t('household_text.text_part1')}</p>
        <p>{t('household_text.text_part2')}</p>
        <p>{t('household_text.text_part3')}</p>
        <p>{t('household_text.text_part4')}</p>
        <p>{t('additional_info')} 
            <a
                target="blank"
                className="link"
                href="https://www.vero.fi/henkiloasiakkaat/verokortti-ja-veroilmoitus/vahennykset/kotitalousvahennys/"
            > {t('household_text.verowebsite')}
            </a> {t('household_text.fromWebsite')}
        </p>
    </>
}

export default HouseHoldDeductionContent