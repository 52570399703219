import React from 'react';
import AdminHeader from './AdminHeader';

//import './adminLayout.scss';

const Layout = (props: {children: React.ReactNode } ) => {
    
    return (
        <div className="admin-root">
            <AdminHeader />
            <div className="main-container p-grid ">
                <div className="p-col box p-shadow-21">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Layout;
