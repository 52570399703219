//import moment from 'moment'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { initOrder } from '../actions/order';
import Content from '../layout/Content'
import Header from '../layout/Header'
import { RootState } from '../reducers/rootReducer';

import pesulasta from '../static/img/pesulasta.svg';
import star from '../static/img/Star.svg';
import { getOrderInitPayload } from '../utils/customSelectors';
import { Helmet } from 'react-helmet';
import { Dialog } from 'primereact/dialog';
import Terms from './address/Terms';
import { Trans, useTranslation } from 'react-i18next';

const OrderSuccessPage = () => {

    const { config } = useSelector((state: RootState) => state.counter);
    const orderInitialValues = useSelector((state: RootState) => getOrderInitPayload(state))
    const { order } = useSelector((state: RootState) => state.order)
    const [orderCleaned, setOrderCleaned] = useState<Boolean>(false)
    const [showTerms, setShowTerms] = useState<boolean | undefined>(false)
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isMall = !!order.mall;

    // useEffect(() => { 
    //     ReactGA.pageview(getPageUrl())
    // }, [])

    useEffect(() => {
        if (config && orderInitialValues && !orderCleaned) {
            setOrderCleaned(true);
            dispatch(initOrder(orderInitialValues));
        }
    }, [dispatch, orderCleaned, config, orderInitialValues]);

    const renderFooter = () => {
        return (
            <div>
                <Button label={t('thank')} onClick={() => setShowTerms(false)} autoFocus />
            </div>
        );
    }

    return <>
        <Helmet>
            <title>Kiitos tilauksesta - Ikkunanpesun hintalaskuri</title>
            <meta name="description" content="Laske ikkunanpesun hinta helposti parissa minuutissa ja tilaa pesu" />
            <meta name="keywords" content="Ikkunanpesun hinta, Ikkunanpesun hintalaskuri, Kirkaslasi, Ikkunanpesu, Ikkunanpesu netistä" />
        </Helmet>
        <Header />
        <Content >
            <div className="success-payment-page p-grid p-justify-center">
                <div className="page-head p-col-12">
                    <div className="box">
                        <div className="page-title">{t('success.title')}</div>
                    </div>
                </div>

                <div className="p-col-12">
                    <div className="page-content">
                        <img src={pesulasta} alt="lasta" className="spatula-img" />
                        <p className="yellow">
                            {!isMall &&
                                <Trans i18nKey='success.part1' />
                                // <>
                                //     Kello 8 alkavia pesuja lukuunottamatta kellonajat ovat suuntaa-antavia! 
                                //     <br /><br />
                                //     Ikkunanpesijä soittaa pesupäivänä hyvissä ajoin ennen pesua, kun osaa arvioida päivän aikatauluja tarkemmin. 
                                //     Toivomme, että siirrätte huonekalut ikkunoiden edustoilta pesun helpottamiseksi. 
                                //     Autamme ja noudatamme varovaisuutta tavaroiden siirtämisessä, mutta erityisesti arvotavaroiden siirtäminen on tilaajan vastuulla.
                                //     <br /><br />
                                //     Tuomme kaikki tarvittavat pesuaineet ja -välineet mukanamme!
                                // </>
                            }
                            {!isMall && <><br /></>} <br />{t('success.part2')}
                            <img src={star} alt="divider" className="divider" />
                        </p>
                        <h2>{t('success.part3')}</h2>
                        <p>
                            {t('success.part4')}
                        </p>
                        <h2>{t('terms.extra_title')}</h2>
                        <p>
                            <Trans i18nKey='success.part5' />
                            {/* Työ ei sisällä huonosti avautuvien tai viallisten ikkunoiden pesua, eikä pinttyneen lian - kuten pihakasvien orgaaninen lika, 
                            hyönteisten jätökset, liimatarrat tai muu kuin normaali likaantuminen - puhdistamista.
                            <br /><br />
                            Pinttyneen lian poisto on lisätyötä ja suoritetaan yleensä tuntiveloitteisesti. 
                            Voit keskustella mahdollisista lisätyön tarpeesta pesijän kanssa hänen saapuessaan tai jo etukäteen. */}
                            <span onClick={() => setShowTerms(true)} className="hide" >sopimusehdoistamme</span>
                        </p>
                        <Button
                            onClick={() => window.location.replace("https://kirkaslasi.fi")}
                            type="button"
                            label={t('success.return')} >
                        </Button>
                    </div>

                    <Dialog
                        header={t('terms.title')}
                        visible={showTerms}
                        footer={renderFooter()}
                        onHide={() => setShowTerms(false)}
                        className="term-modal"
                    >
                        <Terms consumer={true} shoppingMall={isMall} />
                    </Dialog>
                </div>
            </div>
        </Content>
    </>
}

export default OrderSuccessPage;