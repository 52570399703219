import { Button } from 'primereact/button';
import React, { useEffect,  useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IOrderWindow } from '../actions/order';
import { RootState } from '../reducers/rootReducer';
import { getMallOrderTotal, getOrderTotal, VERKKOTILAAJAN_ETU } from '../utils/customSelectors';
import FadeIn from 'react-fade-in';
import usePrevious from '../hooks/usePrevious';
import SlotEffect from '../components/SlotEffect';
import { priceAfterHouseholdDeduction } from '../utils/globals';
import { Dialog } from 'primereact/dialog';
import HouseHoldDeductionContent from '../components/HouseHoldDeductionContent';
import { useTranslation } from 'react-i18next';


const MobileFooterAction = (props: {toPath?:string}) => {
    
    const {total, discountTotal, rawTotal} = useSelector((state:RootState) => getOrderTotal(state));
    const { mall_total } = useSelector((state: RootState) => getMallOrderTotal(state))
    const {order} = useSelector((state:RootState) => state.order);
    const { base_price } = useSelector((state: RootState) => state.counter.config);
    const history = useHistory();
    const [orderDisabled, setOrderDisabled] = useState<boolean>(true);
    const [showHouseholdDeduction, setShowHouseHoldDeduction] = useState<boolean>(false);

    const { t } = useTranslation();

    const isShoppingMall: boolean = !!order.mall;

    const totalToShow = isShoppingMall ? mall_total : rawTotal === (base_price - VERKKOTILAAJAN_ETU) ? 0 : total;
    // const totalToShow = isShoppingMall ? mall_total : total === (base_price - VERKKOTILAAJAN_ETU) ? 0 : total < minimum_price ? minimum_price : total;

    const discountTotalToShow = total === 0 ? 0 : discountTotal;
    // const discountTotalToShow = totalToShow === 0 ? 0 : discountTotal < minimum_price ? minimum_price : discountTotal;

    const prevTotal = usePrevious(totalToShow || 0);
    const prevDiscountTotal = usePrevious(discountTotalToShow || 0);
    
    useEffect(() => {
        const windowAmount = order.windows.reduce((acc:number, window: IOrderWindow) => acc + window.amount,0)
        setOrderDisabled((windowAmount + order.balconyGlassAmount + order.terraceGlassAmount + order.highGlassAmount + order.highOpeningAmount > 0) ? false : true)
    }, [order.highGlassAmount, order.highOpeningAmount, order.balconyGlassAmount, order.terraceGlassAmount, order.windows])
    
    const discountClassName = isNaN(discountTotal) ? 'discount-price' : 'discount-applied';
    
    const renderFooter = () => {
        return (
            <div>
                <Button label={t('thank')} onClick={() => setShowHouseHoldDeduction(false)} autoFocus />
            </div>
        );
    }
        
    return (
        <div className="footer-wrapper">
            <Dialog
                header={t('household_text.title')} 
                visible={showHouseholdDeduction} 
                footer={renderFooter()} 
                onHide={() => setShowHouseHoldDeduction(false)}
            >
                <HouseHoldDeductionContent />
            </Dialog>
            <FadeIn visible={!orderDisabled}>
                <div id="footer">
                    <div className={isShoppingMall ? 'mall-footer left' : 'left'}>
                        {isShoppingMall ? 'Hinta / pesukerta: ' : (t('summary_part.total_price') + ' ')}
                        <span className={discountClassName}>
                            {order.highAccessibility !== false ? 
                                (isNaN(+discountTotalToShow) ? SlotEffect(prevTotal, totalToShow) : (totalToShow + `€`) ) 
                                : '-' }
                        </span>
                        {/*<span className="vat">(sis. Alv)</span> */}
                        {!isNaN(discountTotal) && <span className="discount-price">{order.highAccessibility !== false ? SlotEffect(prevDiscountTotal, discountTotalToShow): '-' }</span>}

                        { !isShoppingMall && 
                            <div className="kotitalousvahennys">
                                <span>
                                    <span className="hdc-link" onClick={() => setShowHouseHoldDeduction(true)}>{t('summary_part.with_household')}</span> {` `}
                                {order.highAccessibility !== false ?
                                    (priceAfterHouseholdDeduction( isNaN(discountTotal) ? totalToShow : discountTotalToShow) + '€' )
                                    : '-'
                                }
                                </span>
                            </div>
                        }
                    </div>
                    <div className="right">
                        <Button type="submit" onClick={() => props.toPath && history.push(props.toPath)} >{t('contact.order')}</Button>
                    </div>
                </div>
            </FadeIn>
        </div>
    );
};

export default MobileFooterAction
