import React from 'react';
import Helmet from 'react-helmet'
import Header from '../layout/Header'
import Content from '../layout/Content';
import Calendar from './calendar/Calendar';
import { CALENDAR_TYPE } from './calendar/enums';
import Notification from '../components/Notification/Notification'
import CalendarPostalcode from './calendar/CalendarPostalcode';
import { useTranslation } from 'react-i18next';

const CheckFreeTimes: React.FC<{}>  = () => {
    
    // useEffect(() => ReactGA.pageview(getPageUrl()),[])
    const { t } = useTranslation();


    return (
        <>
            <Helmet>
                <title>Katso vapaat ajat - Ikkunanpesun hintalaskuri</title>
                <meta name="description" content="Laske ikkunanpesun hinta helposti parissa minuutissa ja tilaa pesu" />
                <meta name="keywords" content="Ikkunanpesun hinta, Ikkunanpesun hintalaskuri, Kirkaslasi, Ikkunanpesu, Ikkunanpesu netistä" />
            </Helmet>
            <Header/>
            <Content>
                <>
                    <Notification />
                    <div className="calendar-page p-grid p-justify-center">
                        <div className="page-head p-col-12">
                            <div className="box">
                                <div className="calendar-page-title">{t('free_slots')}</div>
                            </div>
                        </div>
                        
                        <div className="calendar-postalcode p-col-12">
                            <CalendarPostalcode />
                        </div>
                        <div className="calendar p-col-fixed">
                            <div className="box">
                                <Calendar type={CALENDAR_TYPE.CHECK} />
                            </div>
                        </div>
                    </div>
                </>
            </Content>
        </>
    )
}

export default CheckFreeTimes;