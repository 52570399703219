import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../hooks/useWindowDimension';

import kirkaslasi_logo from '../static/img/Kirkaslasi_logo.svg';
import flag_fin from '../static/img/flag_fin.png';
import flag_eng from '../static/img/flag_eng.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';


const Header = () => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    const history = useHistory();
    const { width } = useWindowDimensions();

    const breakPoint = 768;
    const mobile = width < breakPoint;

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n])


    return (
        <div className="header-wrapper">
            <div className={`header-content p-d-flex p-jc-${mobile ? "center" : "start"}`}>
                <div className="p-col-align-center">
                    <div className="box">
                        <img className="header-logo" src={kirkaslasi_logo} alt="Kirkaslasi logo" onClick={() => history.push('./')} />
                    </div>
                </div>
                <Button onClick={(e: any) => setLanguage(language === 'fi' ? 'en' : 'fi')} className="language-button" tooltip={mobile ? undefined : language === 'en' ? 'suomi' : 'English'} tooltipOptions={{ showDelay: 500, position: 'left' }}>
                    <img alt="flag" src={language === 'en' ? flag_fin : flag_eng} height={'100%'} width={'100%'}></img>
                </Button>
            </div>
        </div>
    )
}

export default Header;
