import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Messages } from 'primereact/messages'
import Layout from "../components/Layout";
import * as PrimeCalendar  from 'primereact/calendar';
import moment from 'moment';
//import { CALENDAR_FI } from "../../utils/globals";
import { useDispatch, useSelector } from "react-redux";
import { deleteDisabledDateAction, loadDisabledDatesAction, saveDisabledDateTimes } from "../../actions/calendar";
import { RootState } from "../../reducers/rootReducer";
import Title from "../components/Title";
import { IcleanerRow } from "../settings/cleaners/CleanersSettings";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

const AdminCalendar: React.FC<{}> = () => {

    const {disabled_dates/*, error, isLoading, date_created*/} = useSelector((store: RootState) => store.calendar);
    const dispatch = useDispatch();
    const [activeCleaner, setActiveCleaner] = useState<any>();
    const [disabledTimesInActiveDay, setDisabledTimesInActiveDay] = useState();
    const [activeDay, setActiveDay] = useState<any>([moment()])
    const [editableTimes, setEditableTimes] = useState<any>([]);
    const messagesRef:MutableRefObject<any> = useRef(); 

    const timeOptions = useMemo(() => [
        {label: '8:00', value: '08:00'},
        {label: '8:30', value: '08:30'},
        {label: '9:00', value: '09:00'},
        {label: '9:30', value: '09:30'},
        {label: '10:00', value: '10:00'},
        {label: '10:30', value: '10:30'},
        {label: '11:00', value: '11:00'},
        {label: '11:30', value: '11:30'},
        {label: '12:00', value: '12:00'},
        {label: '12:30', value: '12:30'},
        {label: '13:00', value: '13:00'},
        {label: '13:30', value: '13:30'},
        {label: '14:00', value: '14:00'},
        {label: '14:30', value: '14:30'},
        {label: '15:00', value: '15:00'},
        {label: '15:30', value: '15:30'},
        {label: '16:00', value: '16:00'},
    ],[])


    useEffect(() => {
        dispatch(loadDisabledDatesAction());
    },[dispatch])

    // update disabled_dates after time slots saved
    // otherwise multiple new time slots created
    useEffect(() => {
        if(disabled_dates && activeCleaner){
            disabled_dates.map((cleaner:IcleanerRow) =>
            {
                if(cleaner.id === activeCleaner.id ){
                    setActiveCleaner(cleaner)
                }
                return true;
            })
        }
    },[disabled_dates, activeCleaner])

    useEffect(() => {
        if(activeCleaner){
            const formattedActiveDay = moment(activeDay[0]).format('YYYY-MM-DD');
            
            const disabledTimesInSelectedDay = activeCleaner.disabled_dates.filter((disabled_day: any) => {
                const start_time = moment(disabled_day.start_datetime).format('YYYY-MM-DD');
                const end_time = moment(disabled_day.end_datetime).format('YYYY-MM-DD');
                return formattedActiveDay === start_time ||
                formattedActiveDay === end_time ||
                (
                    moment(start_time).isBefore(formattedActiveDay, 'day') && 
                    moment(end_time).isAfter(formattedActiveDay, 'day')
                )
            }, [])
            const editables = disabledTimesInSelectedDay.filter((day:any) => day.modifier !== 'KBOT',[] )
            const notEditables = disabledTimesInSelectedDay.filter((day:any) => day.modifier === 'KBOT',[] )
            setEditableTimes(editables)
            setDisabledTimesInActiveDay(notEditables)
        }
    },[activeDay, activeCleaner])
    

    const userCalendars = () =><div className="p-grid p-justify-start action-grid cleaner-calendar-links">
            {disabled_dates.map((cleaner:IcleanerRow) => 
                <div key={cleaner.id} className="p-field">
                    <Button 
                        label={cleaner.name} 
                        onClick={() => setActiveCleaner(cleaner)} 
                        className={`p-button-outlined p-button-secondary ${(activeCleaner && activeCleaner.id === cleaner.id ) ? 'active':''}`} />
                </div>
            )}</div>
    
    const getDisabledTimesComponents = (dayRows:any = [], type: string = '') => 
        <div>{dayRows.map((dayRow:any) => 
            <p key={dayRow.id}>
                {moment(dayRow.start_datetime).format('DD.MM.YYYY HH:mm:ss') + " - " + moment(dayRow.end_datetime).format('DD.MM.YYYY HH:mm:ss')} 
            </p> 
            )}
        </div> 

    const handleTimeChange = (obj:any) => {
        const edited = editableTimes.map((timeRecord:any) =>timeRecord.id === obj.id ?
            {...timeRecord, start_datetime: obj.start, end_datetime: obj.end} : timeRecord
        )
        setEditableTimes(edited);
    }
    

    const addNewTime = () => {
        const newTime = {
            id: 'new-'+ new Date(),
            start_datetime: moment(activeDay[0]).set({hour: 8, minute: 0,second:0,millisecond:0}).format('YYYY-MM-DDTHH:mm:ssZ'),
            end_datetime: moment(activeDay[0]).set({hour: 15, minute: 59,second:0,millisecond:0}).format('YYYY-MM-DDTHH:mm:ssZ'),
            maintaining_group_id: activeCleaner.maintaining_group_id
        }
        setEditableTimes([...editableTimes, newTime])
    }

    const saveTimes = () => {
        if(activeCleaner){
            dispatch(saveDisabledDateTimes(editableTimes, activeCleaner.id))
        }

    }

    const removeDateTime = (id:string) => {
        if((id + "").lastIndexOf('new-', 0) !== 0) {
            dispatch(deleteDisabledDateAction(id));
        }
        const newTimes = editableTimes.filter((row:IcleanerRow, i:number) => row.id !== id)
        setEditableTimes(newTimes);
    }
    
    const getEditableTimeRows = (rows:any) =>
        <div>{rows.map((row:any) => {
                const startRemainder = -(moment(row.start_datetime).minute() % 30);
                const endRemainder = 30 - (moment(row.end_datetime).minute() % 30);
                return <div key={row.id} className="calendar-time-row">
                    <Dropdown 
                        value={moment(row.start_datetime).add(startRemainder, 'minutes').format('HH:mm')}
                        options={timeOptions}
                        onChange={(e) =>handleTimeChange({
                            id:row.id, 
                            start: moment(row.start_datetime).set({hour: e.value.split(':')[0],
                                     minutes: e.value.split(':')[1]}).format('YYYY-MM-DDTHH:mm:ssZ'), 
                            end: row.end_datetime
                        })} 
                        optionLabel="label"
                    />
                    - 
                    <Dropdown 
                        value={moment(row.end_datetime).add(endRemainder, 'minutes').format('HH:mm')}
                        options={timeOptions}
                        onChange={(e) =>handleTimeChange({
                            id:row.id, 
                            end: moment( row.end_datetime).set({hour: e.value.split(':')[0],
                                     minutes: (e.value.split(':')[1] - 1) }).format('YYYY-MM-DDTHH:mm:ssZ'), 
                            start: row.start_datetime
                        })} 
                         optionLabel="label"
                    />
                    <span onClick={() => removeDateTime(row.id)} className="remove-time pi pi-fw  pi-minus-circle"  />
            </div>}
        )}</div>

        
        const dateTemplate = (date:any) => {
            // Primereact months start counting from ZERO !!!!
            const template_fixed_month = date.month+1
            const dayString = date.year+"-"+((template_fixed_month+"").length === 1 ? '0': '')+template_fixed_month+"-"+((date.day+"").length === 1 ? '0':'')+date.day
            const formattedDayString = moment(dayString).format('YYYY-MM-DD').toString();
                        
            if (activeCleaner) {
                const flag = activeCleaner.disabled_dates.filter((dd:any) => {
                    return moment(dd.start_datetime).format('YYYY-MM-DD').toString() === formattedDayString || 
                    moment(dd.end_datetime).format('YYYY-MM-DD').toString() === formattedDayString
                })
                
                return (
                    <span className={flag.length > 0 ? 'markings' : ''}>{date.day}</span>
                );
            }
    
            return date.day;
        }
       
    return (
        <Layout>
            <Messages ref={messagesRef} />
            <Title type="h1" >Valitse kalenterin ei käytössä olevat päivät</Title>
            {userCalendars()}
            <div>
                <div className="admin-calendar">
                    <PrimeCalendar.Calendar
                        value={activeDay} 
                        onChange={(e) => setActiveDay(e.value instanceof Array ? e.value.slice(-1) : e.value )}
                        selectionMode="multiple" 
                        dateTemplate={dateTemplate}
                        readOnlyInput
                        inline
                        disabledDays={[0,6]}
                    ></PrimeCalendar.Calendar>
                </div>
                <div className="disabled-dates-list">
                    {getDisabledTimesComponents(disabledTimesInActiveDay)}
                    {getEditableTimeRows(editableTimes)}
                    {activeCleaner && 
                        <div>
                            <Button onClick={() => addNewTime()} label="Lisää" />
                            <Button onClick={() => saveTimes()} label="Tallenna Muutokset" />
                        </div>
                    }
                </div>
            </div>


        </Layout>
    )
}

export default AdminCalendar;

/**
 
    const pushMessage = (severity: string, msg: string) => {
        messagesRef.current.show({
            severity: severity,
            detail: msg,
            life: 15000
        });
    }
 */
 /*
    created: "2021-06-30T13:49:01.400Z"
    end_datetime: "2021-07-05T20:59:00.000Z"
    id: 19
    maintaining_group_id: "5df09e93-dcf4-479b-ae91-3c205eed084f"
    modified: "2021-06-30T13:49:01.400Z"
    modifier: "KBOT"
    start_datetime: "2021-07-04T21:00:00.000Z"
    */
/*
    // eslint-disable-next-line
    useEffect(() => {
        setDates1(disabled_dates.map((date: IDisabledDate) => moment(date.disabled_date).toDate()));
        setDates2(disabled_dates.map((date: IDisabledDate) => date.disabled_date));
    },[setDates1,setDates2,disabled_dates])

    
    useEffect(() => { 
        if(dates1 ){
            setDates2(
                dates1
                .sort((a:any,b:any) => a - b)
                .map((date:any) => <span key={date}>{moment(date).format('D.M.YYYY').toString()}</span>))
        }}
    ,[dates1])
*/