import React, { FC, useState, useEffect, FocusEvent, useMemo, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import * as Yup from "yup";
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { INVOICE_CHANNEL, ORDER_ADDRESS_STATUS, PAYMENT_TYPE } from '../../reducers/order';
import Summarize, { PAYMENT_TYPE_DISCOUNT_CLASS, REPEATED_ORDER_DISCOUNT_CLASS } from '../calendar/Summarize';
import { Dialog } from 'primereact/dialog';
import postalCitiesCodes from '../../admin/settings/postinumerot.json'
import Terms from './Terms';
import { useHistory } from 'react-router-dom';
import { BILLING_FEES } from './Address';
import { MALL_PLACEHOLDER } from '../counter/MallAccordion';
import moment from 'moment';
import 'moment/locale/fi'; // Import Finland's locale
import { MAKSUTAPA_ALENNUS, REPEATED_ORDER_DISCOUNT, getOrderTotal } from '../../utils/customSelectors';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { priceAfterHouseholdDeduction } from '../../utils/globals';
import { Trans, useTranslation } from 'react-i18next';
import i18n from '../../i18n/config'

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// const nameRegExp = /^\S+\s\S+.*$/ // Minimum of two words
const nameRegExp = /^\S+(\s+\S+)+$/

export const AddressSchema = () => {
    const { t } = i18n;
    return Yup.object().shape({
        name: Yup.string()
            .trim()
            .min(5, t('validation.name_min'))
            .max(56, t('validation.name_max'))
            .matches(nameRegExp, t('validation.name_format'))
            .required(t('validation.required')),
        phone: Yup.string()
            .trim()
            // .matches(phoneRegExp, 'Syötä kelvollinen puhelinnumero')
            .min(6, t('validation.proper_phone'))
            .max(18, t('validation.phone_max'))
            .required(t('validation.required')),
        email: Yup.string()
            .trim()
            .max(128, t('validation.email_max'))
            .email(t('validation.proper_value'))
            .required(t('validation.required')),
        streetAddress: Yup.string()
            .min(4, '')
            .required(t('validation.required')),
        postalCode: Yup.string()
            .matches(/^[0-9]+$/, t('validation.postalCode_format'))
            .min(5, t('validation.proper_value'))
            .max(5, t('validation.proper_value'))
            .required(t('validation.required')),
        message: Yup.string()
            .max(1028),
        company: Yup.boolean(),
        companyName: Yup.string()
            .when('company', {
                is: true,
                then: Yup.string()
                    .max(128, t('validation.companyName_max'))
                    .required(t('validation.required')),
            }),
        companyVat: Yup.string()
            .when('company', {
                is: true,
                then: Yup.string()
                    .min(9, t('validation.proper_companyVat'))
                    .max(9, t('validation.proper_companyVat'))
                    .required(t('validation.required')),
            }),
        company_einvoice_address: Yup.string()
            .when(['company', 'invoice_channel'], {
                is: (company, invoice_channel) => company === true && invoice_channel === INVOICE_CHANNEL.EINVOICE,
                then: Yup.string()
                    .min(12, t('validation.proper_company_einvoice_address'))
                    .max(12, t('validation.proper_company_einvoice_address'))
                    .required(t('validation.required')),
            }),
        company_operator_id: Yup.string()
            .when(['company', 'invoice_channel'], {
                is: (company, invoice_channel) => company === true && invoice_channel === INVOICE_CHANNEL.EINVOICE,
                then: Yup.string()
                    .min(7, t('validation.proper_company_operator_id'))
                    .max(17, t('validation.proper_company_operator_id'))
                    .required(t('validation.required')),
            }),
        billing: Yup.boolean(),
        billingName: Yup.string()
            .min(2, t('validation.billingName_min'))
            .when('billing', {
                is: true,
                then: Yup.string()
                    .required(t('validation.required')),
            }),
        invoice_channel: Yup.mixed<INVOICE_CHANNEL>()
            .oneOf(Object.values(INVOICE_CHANNEL), t('validation.required'))
            .required(t('validation.required')),

        billingStreetAddress: Yup.string()
            .when('billing', {
                is: true,
                then: Yup.string()
                    .min(4, '')
                    .required(t('validation.required')),
            }),
        billingPostalCode: Yup.string()
            .when('billing', {
                is: true,
                then: Yup.string()
                    .matches(/^[0-9]+$/, t('validation.billingPostalCode_format'))
                    .min(5, t('validation.proper_value'))
                    .max(5, t('validation.proper_value'))
                    .required(t('validation.required')),
            }),
        billingPostalCity: Yup.string()
            .when('billing', {
                is: true,
                then: Yup.string()
                    .min(2, t('validation.proper_value'))
                    .required(t('validation.required')),
            }),
        terms: Yup.bool()
            .required(),
        marketing_permission: Yup.bool()
            .required(),
        repeated_order: Yup.bool()
            .required(),
        clean_interval: Yup.number(),
        mall_starting_month: Yup.number(),
        order_address_status: Yup.mixed<ORDER_ADDRESS_STATUS>()
            .oneOf(Object.values(ORDER_ADDRESS_STATUS)),
    })
}


const AddressForm: FC<{}> = (props: any) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        //isLoading
    } = props;

    const history = useHistory()
    const [focusElementId, setFocusElementId] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [terms, setTerms] = useState<boolean>(false);
    const [showRepeatedIntervalSelector, setShowRepeatedIntervalSelector] = useState<boolean>(false);
    const { total, discountTotal, rawTotal } = useSelector((state: RootState) => getOrderTotal(state))

    const { t } = useTranslation();

    const mobileSummarizePage = useRef<HTMLDivElement>(null);
    // const invoiceElement = useRef<HTMLDivElement>(null);

    const [showTerms, setShowTerms] = useState<boolean>(false)

    const extendBlur = (e: FocusEvent<(HTMLInputElement | HTMLTextAreaElement)>) => {
        handleBlur(e);
        setFocusElementId('')
    }
    /*
        const beforeSubmit = async (payment_type: string) => {
            await setFieldValue('paymentType', payment_type);
            await setFieldValue('city', city);
            return;
        }*/

    let cityCodes = useMemo(() => Object.keys(postalCitiesCodes).map((postalNumber) =>
    ({
        code: postalNumber,
        // @ts-ignore
        city: postalCitiesCodes[`${postalNumber}`]
    }), []), [])

    // Set city field value based on given postal code
    useEffect(() => {

        const citicode = cityCodes.filter((citicode: any) => citicode.code === values.postalCode, [])[0]
        if (!citicode) {
            console.log("Postalcode not set. redirect to homepage");
            history.push('/')
        } else {
            setFieldValue('city', citicode.city);
            setCity(citicode.city)
        }
    }, [cityCodes, history, setFieldValue, values.postalCode])

    useEffect(() => {
        let elementNames: string[] | null = Object.keys(errors);
        if (!isSubmitting && elementNames?.length > 0) {
            const elNameToFocus = elementNames[0];
            const elToFocus = document.getElementsByName(elNameToFocus)[0];
            if (elNameToFocus === 'invoice_channel') {
                scrollToElement(elToFocus)
                // Focus would go to select-component so we have to dig the input element.
                // const inputEl = invoiceElement.current?.getElementsByTagName('input');
                // if (inputEl) { inputEl[0].focus(); }
            } else {
                elToFocus.focus(); // HTML focus event also scrolls to element.
            }
        }
        return () => {
            if (isSubmitting)
                elementNames = null;
        };
    }, [isSubmitting])

    const scrollToElement = (el: Element) => el.scrollIntoView();

    const ErrorMessage = ({ message }: { message: string }) => <span className="p-inline-message-text" >{message}</span>

    const Field = (type: string, field: string, label: string, cols: string = '12', disabled = false) =>
        <div className={`p-col-${cols}`}>
            <div className={`box ${(errors[field] && touched[field]) && ' error'}`}>
                <InputText
                    onChange={handleChange}
                    onBlur={(e) => extendBlur(e)}
                    value={values[field]}
                    name={field}
                    id={field}
                    // autoFocus={field === 'name'}
                    onFocus={() => setFocusElementId(field)}
                    disabled={disabled}
                />
                <label htmlFor={field} className={`placeholder ${(values[field] || focusElementId === field) && 'focus-or-value'}`}>{label}</label>
                {errors[field] && touched[field] && <ErrorMessage message={errors[field]} />}
            </div>
        </div>

    const AdditionalInfo =
        <>
            {values.isMall &&
                <div className="p-col-12">
                    <div className="subtitle">
                        Lisätietoja
                    </div>
                </div>
            }
            <div className="p-col-12">
                <div className="box">
                    <InputTextarea
                        onChange={handleChange}
                        onBlur={(e) => extendBlur(e)}
                        value={values.message}
                        className="message-area"
                        name="message"
                        id="message"
                        onFocus={() => setFocusElementId('message')}
                        rows={4}
                        autoResize={true}
                        placeholder={values.isMall ? MALL_PLACEHOLDER : t('tabs.additional_info_placeholder')}
                    />
                    {!values.isMall &&
                        <label htmlFor="message" className={`placeholder ${(values.message || focusElementId === 'message') && 'focus-or-value'}`}>{t('additional_info')}</label>
                    }
                </div>
            </div>
        </>

    // console.log(values.pageElement, mobileSummarizePage.current);
    const getElementByClass = (className: string) => values.pageElement ? values.pageElement.current?.querySelector(className) : null;
    const getElementByClassMob = (className: string) => mobileSummarizePage.current ? mobileSummarizePage.current.querySelector(className) : null;

    // HACK solution to add updated price with discounts to the UI. These discounts are added to the order after the form is submitted.
    // Smoother solution would be to add these discounts to the order before submitting the form, but updating state in the form is not allowed, or atleast tricky.
    const paymentTypeDiscountElement = getElementByClass(`.${PAYMENT_TYPE_DISCOUNT_CLASS}`);
    const repeatedOrderDiscountElement = getElementByClass(`.${REPEATED_ORDER_DISCOUNT_CLASS}`);
    const totalSumElem = getElementByClass('.total-sum-total');
    const totalDisSumElem = getElementByClass('.total-sum-discount');
    const discountSumElem = getElementByClass('.discount-sum');
    // We have either one or two summarize pages in the dom...
    const paymentTypeDiscountElementMob = getElementByClassMob(`.${PAYMENT_TYPE_DISCOUNT_CLASS}`) as HTMLElement;
    const repeatedOrderDiscountElementMob = getElementByClassMob(`.${REPEATED_ORDER_DISCOUNT_CLASS}`) as HTMLElement;
    const totalSumElemMob = getElementByClassMob('.total-sum-total');
    const totalDisSumElemMob = getElementByClassMob('.total-sum-discount');
    const discountSumElemMob = getElementByClassMob('.discount-sum');

    const updatePriceUi = (repeatedDiscount?: boolean, paymentTypeDiscount?: boolean) => {
        if (!values.pageElement && !mobileSummarizePage.current) {
            // console.log('updatePriceUi: elements not found', paymentTypeDiscountElement, repeatedOrderDiscountElement, paymentTypeDiscountElementMob, repeatedOrderDiscountElementMob);
            return;
        }

        const showRepeated = repeatedDiscount && REPEATED_ORDER_DISCOUNT !== 0;
        const showPaymentType = paymentTypeDiscount && MAKSUTAPA_ALENNUS !== 0;

        if (values.pageElement) {
            if (paymentTypeDiscountElement)
                paymentTypeDiscountElement.style.display = showPaymentType ? 'block' : 'none';
            if (repeatedOrderDiscountElement) 
                repeatedOrderDiscountElement.style.display = showRepeated ? 'block' : 'none';
        }
        if (mobileSummarizePage.current) {
            if (paymentTypeDiscountElementMob)
            paymentTypeDiscountElementMob.style.display = showPaymentType ? 'block' : 'none';
            if (repeatedOrderDiscountElementMob) 
            repeatedOrderDiscountElementMob.style.display = showRepeated ? 'block' : 'none';
        }

        const addDiscount = repeatedDiscount || paymentTypeDiscount;
        let discountSum = 0;
        if (paymentTypeDiscount) {
            discountSum += MAKSUTAPA_ALENNUS;
        }
        if (repeatedDiscount) {
            discountSum += REPEATED_ORDER_DISCOUNT;
        }

        // console.log('updatePriceUi');
        // console.log(totalSumElem, totalDisSumElem, discountSumElem, totalSumElemMob, totalDisSumElemMob, discountSumElemMob);


        if (totalSumElem || totalSumElemMob) { // Total sum without discount code added.
            const price = addDiscount ? total - discountSum : total;
            const priceString = `${price} €`
            if (totalSumElem)
                totalSumElem.innerHTML = priceString;
            if (totalSumElemMob)
                totalSumElemMob.innerHTML = priceString;
        }
        if (totalDisSumElem || totalDisSumElemMob) { // Sum with discount code added.
            const price = addDiscount ? discountTotal - discountSum : discountTotal;
            const priceString = `${price} €`;
            if (totalDisSumElem)
                totalDisSumElem.innerHTML = priceString;
            if (totalDisSumElemMob)
                totalDisSumElemMob.innerHTML = priceString;
        }
        if (discountSumElem || discountSumElemMob) { // Sum after household deduction.
            const discountTotalToShow = total === 0 ? 0 : discountTotal
            const price = isNaN(discountTotal) ? total : discountTotalToShow
            const discountedPrice = Math.floor(addDiscount ? price - discountSum : price);
            const priceString = `${priceAfterHouseholdDeduction(discountedPrice)} €`;
            if (discountSumElem)
                discountSumElem.innerHTML = priceString;
            if (discountSumElemMob)
                discountSumElemMob.innerHTML = priceString;
        }
    }


    // const AllInvoiceOptions = [
    //     { name: 'Verkkopankkimaksu (Maksu tilauksen yhteydessä)', value: INVOICE_CHANNEL.UNDEFINED },
    //     { name: `Verkkolasku (+${BILLING_FEES.EINVOICE.toFixed(2)} €)`, value: INVOICE_CHANNEL.EINVOICE },
    //     { name: `Sähköpostilasku (+${BILLING_FEES.ONLINE.toFixed(2)} €)`, value: INVOICE_CHANNEL.EMAIL },
    //     { name: `Paperilasku (+${BILLING_FEES.PAPER.toFixed(2)} €)`, value: INVOICE_CHANNEL.PAPER },
    // ];

    const AllInvoiceOptions = [
        { name: t('contact.payment_online'), value: INVOICE_CHANNEL.UNDEFINED },
        { name: t('contact.payment_einvoice'), value: INVOICE_CHANNEL.EINVOICE },
        // { name: `Sähköpostilasku`, value: INVOICE_CHANNEL.EMAIL },
        { name: t('contact.payment_paper'), value: INVOICE_CHANNEL.PAPER },
    ];

    const invoiceOptionsForMalls = [
        { name: `Verkkolasku`, value: INVOICE_CHANNEL.EINVOICE },
        // { name: `Sähköpostilasku`, value: INVOICE_CHANNEL.EMAIL },
        { name: `Paperilasku (+${BILLING_FEES.PAPER.toFixed(2)} €)`, value: INVOICE_CHANNEL.PAPER },
    ];

    // For malls, List of next seven months
    moment.locale('fi'); // TODO: set finnish locale globally in app...
    const monthOptions: Array<{ name: string, value: number }> = Array.from({ length: 7 }, (_, i) => {
        const today = moment(), day = today.date();
        const addOn = day < 10 ? i : i + 1;
        const date = today.add(addOn, 'months'), monthNumber = date.month(), year = date.year();
        const month = date.format('MMMM'), monthName: string = month.charAt(0).toUpperCase() + month.slice(1);
        return {
            name: `${(monthNumber < 9 ? '0' : '') + (monthNumber + 1) + ' ' + monthName + ' ' + year}`,
            value: monthNumber
        };
    });

    // Allow einvoice only for company users
    const InvoiceOptions = AllInvoiceOptions.filter((Itype: any) => (
        (values.company && Itype.value === INVOICE_CHANNEL.EINVOICE) ||
        Itype.value !== INVOICE_CHANNEL.EINVOICE))


    const RepeatedOptions = Array.from({ length: 4 }, (_, i) => { return { name: ((i + 1) * 3) + t('contact.months'), value: (i + 1) * 3 } });

    const renderFooter = () => {
        return (
            <div>
                <Button label={t('thank')} onClick={() => setShowTerms(false)} autoFocus />
            </div>
        );
    }

    return <>
        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(e) }}>

            {values.isMall ?
                <>
                    <div className="p-col-12 mall-starting-month-wrapper">
                        <div className='box'>
                            <label className="subtitle" htmlFor="starting_month">Aloituskuukausi</label><br></br>
                            <Dropdown
                                optionLabel="name"
                                optionValue="value"
                                id='mall_starting_month'
                                name="mall_starting_month"
                                options={monthOptions}
                                showOnFocus={true}
                                onChange={e => {
                                    setFieldValue('mall_starting_month', e.target.value);
                                }}
                                value={values.mall_starting_month} />
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="subtitle">
                            Yrityksen tiedot
                        </div>
                    </div>

                    {Field("text", "companyName", "Yrityksen nimi")}
                    {Field("text", "companyVat", "Y-tunnus")}


                    {Field("text", "name", "Yhteyshenkilö")}
                    {Field("text", "phone", "Puhelinnumero")}
                    {Field("text", "email", "Sähköposti")}



                </> :
                <>

                    {Field("text", "name", t('contact.name'))}
                    {Field("text", "phone", t('contact.tel'))}
                    {Field("text", "email", t('contact.email'))}

                    <div className="p-col-12">
                        <div className="subtitle">
                            {t('contact.target_info')}
                        </div>
                    </div>

                    {Field("text", "streetAddress", t('contact.street_address'), "12")}

                    <div className="p-col-6 postal-code">
                        <div className={`box ${(errors.postalCode && touched.postalCode) && ' error'}`}>
                            <InputText
                                onChange={handleChange}
                                onBlur={(e) => extendBlur(e)}
                                value={values.postalCode}
                                name="postalCode"
                                id="postalCode"
                                disabled={true}
                                onFocus={() => setFocusElementId('postalCode')}
                            />
                            <label htmlFor="postalCode" className={`placeholder ${(values.postalCode || focusElementId === 'postalCode') && 'focus-or-value'}`}>{t('postal_code')}</label>
                            {errors.postalCode && touched.postalCode && <ErrorMessage message={errors.postalCode} />}
                        </div>
                    </div>
                    <div className="p-col-6 postal-city">
                        <div className="box">
                            <span className="">{city}</span>
                        </div>
                    </div>
                    <div className="p-col-12 terms">
                        <div className="box">
                            <Checkbox
                                inputId='company-check'
                                name="company"
                                onChange={(e) => {
                                    setFieldValue('company', e.checked);
                                    if (!e.checked && values.invoice_channel === INVOICE_CHANNEL.EINVOICE) {
                                        setFieldValue('invoice_channel', null); // For some reason if the value is set as undefined, it does not trigger error even the value is undefined at initialization.
                                        setFieldValue('payment_type', PAYMENT_TYPE.ONLINE); // Set default value
                                    }
                                }}
                                checked={values.company}
                                disabled={values.isMall} />
                            <label htmlFor="company-check">{t('contact.company')}</label>
                        </div>
                    </div>
                    {values.company &&
                        <>
                            {Field("text", "companyName", t('contact.company_name'))}
                            {Field("text", "companyVat", t('contact.vat'))}
                        </>
                    }
                </>
            }

            {AdditionalInfo}

            {/* {!values.isMall &&
                <div className="p-col-12 terms">
                    <div className="box">
                        <Checkbox
                            inputId='international-cleaner'
                            name="international_cleaner"
                            onChange={(e) => setFieldValue('international_cleaner', !values.international_cleaner)}
                            checked={values.international_cleaner} />
                        <label htmlFor="international-cleaner">{t('contact.english_speaking')}</label>
                    </div>
                </div>
            } */}

            <div className="p-col-12 invoice-channel-wrapper">
                <div className={`box ${(errors['invoice_channel'] && touched['invoice_channel']) && ' error'}`}>
                    {/* <label className="subtitle" htmlFor="invoice_channel">Maksutapa</label> */}
                    <Dropdown
                        placeholder={t('contact.select_payment')}
                        optionLabel="name"
                        optionValue="value"
                        id='invoice_channel'
                        name="invoice_channel"
                        options={values.isMall ? invoiceOptionsForMalls : InvoiceOptions}
                        showOnFocus={true}
                        onChange={(e) => {
                            setFieldValue('invoice_channel', e.target.value)
                            setFieldValue('paymentType',
                                e.target.value === INVOICE_CHANNEL.UNDEFINED ? PAYMENT_TYPE.ONLINE : PAYMENT_TYPE.BILL
                            )
                            if (!values.isMall) {
                                updatePriceUi(values.repeated_order, e.target.value === INVOICE_CHANNEL.UNDEFINED);
                            }
                        }}
                        value={values.invoice_channel} />
                    {errors['invoice_channel'] && touched['invoice_channel'] && <ErrorMessage message={errors['invoice_channel']} />}
                </div>
            </div>

            {(values.company && values.invoice_channel === INVOICE_CHANNEL.EINVOICE) &&
                <>
                    {Field("text", "company_einvoice_address", t('contact.payment_address'))}
                    {Field("text", "company_operator_id", t('contact.payment_operator'))}
                </>
            }
            {(
                values.invoice_channel === INVOICE_CHANNEL.PAPER ||
                values.invoice_channel === INVOICE_CHANNEL.EMAIL) &&
                <>
                    <div className="p-col-12 terms">
                        <div className="box">
                            <Checkbox
                                inputId='billing-check'
                                name="billing"
                                onChange={(e) => setFieldValue('billing', !values.billing)}
                                checked={values.billing} />
                            <label htmlFor="billing-check">{t('contact.different_billing')}</label>
                        </div>
                    </div>
                    {values.billing &&
                        <>
                            {Field("text", "billingName", t('contact.billing_name'))}
                            {Field("text", "billingStreetAddress", t('contact.billing_address'))}
                            {Field("text", "billingPostalCode", t('postal_code'), "6 billing-postal-row left")}
                            {Field("text", "billingPostalCity", t('postal_area'), "6 billing-postal-row")}
                        </>
                    }
                </>
            }

            <div className="mobile-summarize" ref={mobileSummarizePage}>
                <Summarize page="mobile" />
            </div>

            <div className="divider"></div>

            {!values.isMall &&
                <div className="p-col-12 repeated-order">
                    <div className="box">
                        <div className="subtitle">{t('contact.recurring_title')}</div>
                        <p>
                            <Trans i18nKey='contact.recurring_text' />
                        </p>
                        <div className="checkbox-wrapper">
                            <Checkbox
                                inputId='repeated_order'
                                name="repeated_order"
                                onChange={(e) => {
                                    setFieldValue('repeated_order', e.target.checked)
                                    updatePriceUi(e.target.checked, values.invoice_channel === INVOICE_CHANNEL.UNDEFINED);
                                }}
                                checked={values.repeated_order}
                            />
                            <label htmlFor="repeated_order">
                                {t('contact.recurring_check')} {!showRepeatedIntervalSelector && t('contact.year')}
                            </label>
                            <div className='change-interval'>
                                {!showRepeatedIntervalSelector &&
                                    <a href="/" onClick={(e: any) => { e.preventDefault(); setShowRepeatedIntervalSelector(true) }}>({t('contact.change_cycle')})</a>
                                }

                                {showRepeatedIntervalSelector &&
                                    <div className="">
                                        <Dropdown
                                            placeholder="12 kk"
                                            optionLabel="name"
                                            optionValue="value"
                                            id='clean_interval'
                                            name="clean_interval"
                                            options={RepeatedOptions}
                                            onChange={(e) => setFieldValue('clean_interval', e.target.value)}
                                            value={values.clean_interval} />
                                        {t('contact.ending')}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="p-col-12 terms">
                <div className="box checkbox">
                    <Checkbox
                        inputId='marketing_permission'
                        name='marketing_permission'
                        value={values.marketing_permission}
                        onChange={(e) => setFieldValue('marketing_permission', !values.marketing_permission)}
                        checked={values.marketing_permission} />
                    <label htmlFor="marketing_permission" className='p-checkbox-label'>{t('contact.marketing')}</label>
                </div>
            </div>
            <div className="p-col-12 terms">
                <div className="box checkbox">
                    <Checkbox
                        inputId='terms'
                        name="terms"
                        value={terms}
                        onChange={(e) => setTerms(!terms)}
                        checked={terms} />
                    <label htmlFor="terms">
                        {t('contact.accept') + ' '} <a href="/" rel="noreferrer" onClick={(e: any) => { e.preventDefault(); setShowTerms(true); }}>{t('contact.terms_of_order')}</a>
                    </label>
                </div>
            </div>

            <div className="p-col-12 terms privacy-terms">
                <div className="box checkbox">
                    <label htmlFor="terms">
                        {t('contact.privacy_text') + ' '} <a href="https://kirkaslasi.fi/tietosuojaseloste/" rel="noreferrer" target="_blank">{t('contact.from_here')}</a>
                    </label>
                </div>
            </div>
            <div className="p-col-12 submit">
                <div className="box">
                    <Button
                        disabled={!terms || isSubmitting /*|| !values.invoice_channel*/}
                        label={t(values.invoice_channel === INVOICE_CHANNEL.UNDEFINED ? 'contact.continue_to_pay' : 'contact.do_order')}
                        type="submit">
                    </Button>
                </div>
            </div>
        </form>
        <Dialog
            header={t('terms.title')}
            visible={showTerms}
            footer={renderFooter()}
            onHide={() => setShowTerms(false)}
            className="term-modal"
        >
            <Terms consumer={!values.company} shoppingMall={values.isMall} />
        </Dialog>
    </>
};

export default AddressForm;