import React from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { RootState } from '../../reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
//import SlotEffect from '../../components/SlotEffect';
import { Button } from 'primereact/button';
import { changeAmountAction, changeCleaningSetAction, IOrderWindow } from '../../actions/order';
import pesu_perus from '../../static/img/malls/pesu_perus.svg';
import pesu_yllapito from '../../static/img/malls/pesu_yllapito.svg';
import pesu_loisto from '../../static/img/malls/pesu_loisto.svg';
import star_mall from '../../static/img/malls/star_mall.svg';
import nayteikkuna from '../../static/img/malls/nayteikkuna.gif';
// import nayteikkuna_iso from '../../static/img/malls/nayteikkuna_iso_vanha.png';
import { MALL_CLEANING_SET } from '../../reducers/order';

export const MALL_PLACEHOLDER = "Esim: Aukioloajat, itäsiivessä, yläkerrassa";

const cleaningSetOptions = [
    { 
        title: 'Ylläpito',
        image: pesu_yllapito,
        text: `Kun haluat, että sinun ei tarvitse huolehtia näyteikkunasi pesusta.`, 
        text_info: `Pesu 2 kuukauden välein.`, 
        text_extra: 'Sisäpesu erikseen tilattuna',
        text_value: '',
        base_value: null,
        value: MALL_CLEANING_SET.MAINTAIN
    },
    { 
        title: 'Perus', 
        image: pesu_perus,
        text: `Kun haluat varmistua, että näyteikkunasi on aina putipuhdas.`, 
        text_info: `Pesu kerran kuukaudessa.`, 
        text_extra: 'Sisältää vuosittaisen sisäpesun',
        text_value: `Arvo `, 
        base_value: 120,
        value: MALL_CLEANING_SET.BASIC 
    },
    { 
        title: 'Loisto', 
        image: pesu_loisto,
        text: `Kun haluat erottua muista näyteikkunasi puhtaudella, sisältä ja ulkoa.`, 
        text_info: `Pesu 2 kertaa kuukaudessa.`, 
        text_extra: 'Sisältää 2 sisäpesua vuosittain',
        text_value: 'Arvo ',
        base_value: 240,
        value: MALL_CLEANING_SET.SHINE 
    }
];

const mallUINames: any = {'itis': 'Itis', 'jumbo': 'Jumbo', 'sello': 'Sello', 'isoomena': 'Iso Omena', 'muuostoskeskus': 'Muu ostoskeskus'}


const MallAccordion: React.FC<{ selectMall: any }> = (props: any) => {
    const { config: { windows }, isLoading, error } = useSelector((state: RootState) => state.counter);
    const { order, isLoading: orderIsLoading } = useSelector((state: RootState) => state.order);

    const dispatch = useDispatch();

    // const { width } = useWindowDimensions();
    // Set first accordion tab visible on desktop page load
    // const [activeIndex, setActiveIndex] = useState(() => width > 992 ? 0 : 0)

    const getTabtitle = ({ title, mallTitle = false }: { title: string, mallTitle?: boolean }): React.ReactNode => {
        return <div className="tab-tile">
            <div className="left">
                <span className="title">{title}</span>
                {order.mall && mallTitle && 
                    <span className="title mall-name">
                        {' (' + mallUINames[order.mall.mall_name] }
                        <button className='dropdown-button' onClick={(e) => { e.stopPropagation(); props.selectMall() }}>
                            <i className="pi pi-caret-down" ></i>
                        </button>
                        {')'}
                    </span>}
            </div>
        </div>
    }

    const WindowComponents = (typeof (windows) === 'object' && !isLoading && !orderIsLoading && !error && order?.windows?.length > 0) ?
        windows.map((window: any) => {
            const windowSelections = order.windows.filter((orderWin: IOrderWindow) => window.id === orderWin.windowId)[0];
            let currentAmount: number = windowSelections?.amount;

            return <div className="accordion-tab-col p-col mall-window-col" key={window.id}>
                <div className="title window">Lasien ja ovien määrä</div>
                <div className="image">
                    <img src={nayteikkuna} alt={window.title} />
                </div>
                <div className="ac-tab-content-action-wrapper">
                    <Button
                        onClick={() => currentAmount > 0 ?
                            dispatch(changeAmountAction(windowSelections?.windowId, (currentAmount - 1))) : false}
                        className="minus-btn btn"
                        type="button"
                        label=""
                    >
                        <i className="pi pi-minus" ></i>
                    </Button>
                    <input
                        className="amount"
                        // type needs to be text(default) to let setSelection work. Will there be some downsides for this change??
                        maxLength={4}
                        onClick={(event: any) => { 
                            event.target.setSelectionRange(0, event.target.value.length)
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = parseInt(e.target.value);
                            if (value !== windowSelections.amount) {                           
                                dispatch(changeAmountAction(windowSelections?.windowId, ((!isNaN(value) || value > 0) ? value : 0)));
                            }
                        }}
                        // type="number"
                        value={windowSelections?.amount}
                    />
                    <Button
                        onClick={() => dispatch(changeAmountAction(windowSelections?.windowId, (currentAmount + 1)))}
                        className="plus-btn btn"
                        type="button"
                    >
                        <i className="pi pi-plus" ></i>
                    </Button>
                </div>
            </div>
        }) : <></>
    
    const getValueForSet = (base: number | null) => {
        const x = order.windows[0]?.amount - 10;
        return (x > 0) && base ? (base + x * 12) : base; 
    }
    
    const CleaningSets = cleaningSetOptions.map(set => {
        const setTitle = set.title.toLowerCase()
        return <div className="accordion-tab-col p-col mall-cleaning-sets" key={set.value}>
            <div className={setTitle + " title window"}>{set.title}</div>
            <div className="image-container">
                <img src={set.image} alt={set.title} />
            </div>
            <div className={setTitle + ' ad-text'}>
                {set.text} <br /><br />
                {set.text_info} <br /><br />
            </div>
            <div className={setTitle + ' extra-text-wrapper'}>
                <span className={setTitle + ' extra-text'}> {set.text_extra} </span>
                {setTitle !== 'ylläpito' &&
                    <div className='value-banner-wrapper'>
                        <span className='value-banner'>{set.text_value + getValueForSet(set.base_value) + '€'} </span>
                    </div>
                }
                {setTitle === 'perus' &&
                    <div className='perus value-star-wrapper'>
                        <img src={star_mall} alt='' />
                    </div>
                }
                {setTitle === 'loisto' &&
                    <div className='loisto value-star-wrapper'>
                        <img src={star_mall} alt='' />
                        <img src={star_mall} alt='' />
                    </div>
                }
            </div>
            <div className={(order.mall?.cleaning_set === set.value ? 'selected' : '') + " ac-tab-content-action-wrapper select-button-wrapper"}>
                <Button
                    onClick={() => order.mall?.cleaning_set !== set.value && dispatch(changeCleaningSetAction(set.value))} 
                    type="submit"
                    label={order.mall?.cleaning_set === set.value ? 'Valittu' : 'Valitse'}
                    icon={order.mall?.cleaning_set === set.value && "pi pi-check"}
                    iconPos="right"
                    className={(order.mall?.cleaning_set === set.value ? 'selected' : '') + ' btn cleaning-set-button'}
                    disabled={isLoading}
                >
                    
                </Button>
            </div>
            {setTitle === 'perus' &&
                <div className='mall-suosituin-flag'>
                    <span>Suosituin</span>
                </div>
            }
        </div>
    });
    


    return (
        <div className="shopping-mall-content counter-windows p-col-12 p-lg-9 p-md-12 p-sm-12">
            <Accordion multiple activeIndex={[0]} /*onTabChange={(e) => setActiveIndex(e.index)}*/>
                <AccordionTab header={getTabtitle({ title: 'Tilaustiedot', mallTitle: true })}>
                    <div className="content">
                        <div className="ac-tab-content-wrapper">
                            <div className="p-grid p-justify-start mall-content-wrapper">
                                <div className='mall-windows-wrapper'>
                                    {WindowComponents}
                                </div>
                                <div className='mall-cleaning-sets-wrapper'>
                                    {CleaningSets}
                                </div>
                            </div>
                        </div>
                    </div>
                </AccordionTab>
                <AccordionTab header={getTabtitle({ title: "Mitä tilaukseen sisältyy?" })}>
                    <div className='mall-order-includes-tab-wrapper'>
                        <span className='sub-title'>Jokainen pesukerta sisältää: </span>
                            <ul>
                                <li>Työ sisältää tilattujen näyteikkunoiden <b>ulkopinnan</b> pesun</li>
                                {/* <li>Pesupakettien pohjahinta 49 e sisältää 1-10 näyteikkunaa</li> */}
                                <li>Pesu sisältää kertapesun perinteisellä ikkunanpesun pesurilla sekä lastalla.</li>
                                <li>Pinttyneen tai normaalista likaantumisesta poikkeava pinttyneen lian poisto on lisätyötä, eikä se sisälly tilaukseen.</li>
                                <li>Kaikki ikkunanpesussa tarvittavat välineet ja aineet tulevat Kirkaslasilta.</li>
                            </ul>
                        <span className='sub-title'>Jos tilaukseen sisältyy lisäksi sisäpintojen pesu: </span>
                            <ul>
                                <li>Näyteikkunoiden sisäpinnat pestään tilatun paketin mukaisesti.</li>
                                <li>Tavaroiden ja mallinukkejen tms. siirtely ikkunan edestä on asiakkaan vastuulla.</li>
                                <li>Ensimmäinen sisäpintojen pesu suoritetaan, kun sopimuksen tekohetkestä on 6 kk.</li>
                            </ul>
                        <span className='sub-title'>Muuta huomioitavaa: </span>
                            <ul>
                                <li>Mahdolliset varsinaisen näyteikkunan yläpuolella olevat pienemmät lasit pestään sisäpesun yhteydessä.</li>
                            </ul>
                    </div>
                </AccordionTab>
            </Accordion>
        </div>
    )
}

export default MallAccordion;