import * as React from 'react'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {Password} from 'primereact/password';
import { Message } from 'primereact/message';

import * as Yup from "yup";

export let userEditSchema = Yup.object().shape({
    email: Yup.string()
        .max(128, 'Sähköposti saa sisältää enintään 128 merkkiä')
        .email("Sähköposti ei ole kelvollinen")
        .required("Sähköposti on pakollinen tieto"),
    name: Yup.string()
        .min(3, 'Nimi on liian lyhyt')
        .max(56, 'Nimi saa sisältää enintään 56 merkkiä')
        .required("Nimi on pakollinen tieto"),
    password: Yup.string()
        .min(8, 'Salasanan on oltava yli 7 merkkiä!'),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), undefined], 'Salasanojen täytyy täsmätä')
})

export const userCreateSchema = Yup.object().shape({
    email: Yup.string()
        .max(128, 'Sähköposti saa sisältää enintään 128 merkkiä')
        .email("Sähköposti ei ole kelvollinen")
        .required("Sähköposti on pakollinen tieto"),
    name: Yup.string()
        .min(3, 'Nimi on liian lyhyt')
        .max(56, 'Nimi saa sisältää enintään 56 merkkiä')
        .required("Nimi on pakollinen tieto"),
    password: Yup.string()
        .min(8, 'Salasanan on oltava yli 7 merkkiä!')
        .required('Salasana on pakollinen tieto'),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), undefined], 'Salasanojen täytyy täsmätä')
})

const UserForm: React.FC<{}> = (props: any) => {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      isLoading
    } = props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="p-field p-grid">
            <label htmlFor="name" className="p-col-fixed label" >Name</label>
            <div className="p-col-5">
                <InputText
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    name="name"
                    id="name"
                />
                {errors.name && touched.name && <Message className="form-field-error" severity="error" text={errors.name} />}
            </div> 
        </div>
          
        <div className="p-field p-grid">
            <label htmlFor="email" className="p-col-fixed label">Email</label>
            <div className="p-col-5">
                <InputText
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    name="email"
                    id="email"
                />
                {errors.email && touched.email && <Message className="form-field-error" severity="error" text={errors.email} />}
            </div> 
        </div>

        <div className="p-field p-grid">
            <label htmlFor="password" className="p-col-fixed label">Salasana</label>
            <div className="p-col-5">
                <Password
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    name="password"
                    id="password" 
                />
                {errors.password && touched.password && <Message className="form-field-error" severity="error" text={errors.password} />}
            </div> 
        </div>

        <div className="p-field p-grid">
            <label htmlFor="passwordConfirmation" className="p-col-fixed label">Salasana uudelleen</label>
            <div className="p-col-5">
                <InputText
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.passwordConfirmation}
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                />
                {errors.passwordConfirmation && touched.passwordConfirmation && <Message className="form-field-error" severity="error" text={errors.passwordConfirmation} />}
            </div> 
        </div>
          
        <Button disabled={isLoading} type="submit">Submit</Button>
       
      </form>
    );
  };

  export default UserForm;