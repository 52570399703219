import { GET, POST, PUT, DELETE } from '../utils/api';
//import { ThunkAction } from 'redux-thunk'
//import { Action } from 'redux'

import {
    GET_DISCOUNT,
    GET_DISCOUNTS,
    POST_CREATE_DISCOUNT,
    PUT_UPDATE_DISCOUNT,
    DELETE_DISCOUNT,
    VALIDATE_DISCOUNT_CODE,
    SET_DISCOUNT_CODE
} from '../admin/discount/constants';
import { IReduxAction } from './types';
//import { RootState } from '../reducers/rootReducer';
/*
interface IDiscountCode {
    code: string
}

 * Discount action creators
 */


export const loadDiscountsAction = (): IReduxAction => ({
    type: GET_DISCOUNTS,
    payload: GET(`discount`)
})

export const loadDiscountAction = (discountId: number): IReduxAction => ({
    type: GET_DISCOUNT,
    payload: GET(`discount/${discountId}`)
})

export const createDiscountAction = (body: object): IReduxAction => ({
    type: POST_CREATE_DISCOUNT,
    payload: POST(`discount`, body)
})

export const updateDiscountAction = (discountId: number, body: object): IReduxAction => ({
    type: PUT_UPDATE_DISCOUNT,
    payload: PUT(`discount/${discountId}`, body)
})

export const deleteDiscountAction = (discountId: number): IReduxAction => ({
    type: DELETE_DISCOUNT,
    payload: DELETE(`discount/${discountId}`)
})

export const validateDiscountCode = (body: object): IReduxAction =>({
    type: VALIDATE_DISCOUNT_CODE,
    payload: POST('discount/validate', body)
})

export const setDiscountCode = (code: string) => ({
    type: SET_DISCOUNT_CODE,
    payload: code
})
