export const GET_SETTING = "GET_SETTING";
export const GET_SETTINGS = "GET_SETTINGS";
export const POST_CREATE_SETTINGS = "POST_CREATE_SETTING";
export const GET_SUGGESTIONS = "GET_SUGGESTIONS";
export const DELETE_SETTINGS = "DELETE_SETTINGS";
export const GET_POSTAL_AREAS = "GET_POSTAL_AREAS";
export const CREATE_POSTAL_AREAS = "CREATE_POSTAL_AREAS";
export const DELETE_POSTAL_AREA = "DELETE_POSTAL_AREA";
export const GET_CLEANERS = "GET_CLEANERS";
export const CREATE_CLEANERS = "CREATE_CLEANERS";
export const DELETE_CLEANERS = "DELETE_CLEANERS";
