import { Dialog } from 'primereact/dialog';
import queryString from 'query-string'
import React, { useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { validatePostalcode } from '../../actions/order';

const SKIP_POSTALCODE_VALIDATION = 'skip'

enum RESPONSE_STATES  {
    'DEFAULT',
    'NOT_VALIDATED',
    'VALID',
    'NOT_VALID',
    'VALIDATING'
}

const PostalCodeValidation: React.FC<{}> = () => {
    const {postalCode, isLoading} = useSelector((state:RootState) => state.order.order.address)
    const [checkPostalVisible, setCheckPostalVisible] = useState<boolean>(true)
    const [compPostalCode, setCompPostalCode] = useState<string>('');
    const [showResponse, setShowResponse] = useState<RESPONSE_STATES>(RESPONSE_STATES.NOT_VALIDATED)

    const {pc} = queryString.parse(useLocation().search);
    const localstorage_pc = localStorage.getItem('showPostalCodeValidation')

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if( pc === SKIP_POSTALCODE_VALIDATION ){
            setCheckPostalVisible(false)
            localStorage.setItem('showPostalCodeValidation','0')
        }

        if(isLoading || postalCode === ''){
            setShowResponse(RESPONSE_STATES.NOT_VALIDATED)
        }else if(postalCode){
            setShowResponse(RESPONSE_STATES.VALID)
        }else {
            setShowResponse(RESPONSE_STATES.NOT_VALID)    
        }
    },[postalCode, isLoading, pc])

    const closeDialog = () => {
        setCheckPostalVisible(false)
        localStorage.setItem('showPostalCodeValidation','0')
    }

    const validatePostalCode = (argpostalCode: string) => {
        dispatch(validatePostalcode(argpostalCode))
    }

    const renderFooter = () => {
        return (
            <div>
                {showResponse === RESPONSE_STATES.NOT_VALIDATED &&
                    <Button 
                        label={t('ready')} 
                        onClick={() => validatePostalCode(compPostalCode)}
                        disabled={compPostalCode.length !== 5 }
                        autoFocus />
                }
                {showResponse !== RESPONSE_STATES.NOT_VALIDATED &&
                    <Button label={t('close')} onClick={() => closeDialog()} autoFocus />
                }
            </div>
        );
    }

    const getHeader = () => showResponse === RESPONSE_STATES.NOT_VALID ? t('welcome_part.too_bad') : t('welcome_part.welcome');
    
    return <div className="postalcode-validation">
        <Dialog
            header={getHeader()}
            visible={checkPostalVisible && localstorage_pc !== '0'} 
            footer={renderFooter()} 
            onHide={() => closeDialog()}
        >
            <>

                {showResponse === RESPONSE_STATES.NOT_VALIDATED &&
                    <>
                        <p>{t('welcome_part.check_availability')}</p>
                        <p>
                            <InputText
                                className='box'
                                type="text"
                                onChange={(e:any) => setCompPostalCode(e.target.value.replace(/[^0-9]/g, ''))}
                                value={compPostalCode}
                                maxLength={5}
                                placeholder={t('postal_code')}
                                name='pc-validation'
                                id='pc-validation'
                            />
                        </p>
                    </>
                }
                {showResponse === RESPONSE_STATES.NOT_VALID &&
                    <p>
                        <Trans i18nKey="welcome_part.not_offer_cleaning" />
                    </p>
                }
                {showResponse === RESPONSE_STATES.VALID &&
                    <p>
                        <Trans i18nKey="welcome_part.offer_cleaning" />
                    </p>
                }
            </>
        </Dialog>
    </div>
}

export default PostalCodeValidation