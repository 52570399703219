import { withFormik } from "formik";
import { Messages } from "primereact/messages";
import React, { MutableRefObject, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loadOrderAction } from "../../actions/order";
import { RootState } from "../../reducers/rootReducer";
import Layout from "../components/Layout";
import Title from "../components/Title";
import OrderForm, { orderEditSchema } from "./OrderForm";

const OrderEdit: React.FC<{}> = () => {
    
    const params: any = useParams();
    const dispatch = useDispatch()
    const {order, isLoading, /*updated,*/ error } = useSelector((state: RootState) => state.order); 
    const messagesRef:MutableRefObject<any> = useRef(); 

    useEffect(() => {
        dispatch(loadOrderAction(params.orderId));
    },[dispatch, params.orderId])

    useEffect(() => {
        if(error){
            pushMessage('error', `Order save failed. Try again!`)
        }
        /*if(updated){
            pushMessage('success', `Order updated succesfully.`)
        }*/
    },[error, ])
         
    const OrderEnhancedForm = withFormik({
        mapPropsToValues: () => (order),
        validationSchema: orderEditSchema,
        handleSubmit: (values, { setSubmitting, setFieldError }) => {
            //ispatch(updateDiscountAction(order.id, values))
            
        },
        displayName: 'OrderEditForm',
    })(OrderForm);
    

    const pushMessage = (severity: string, msg: string) => {
        messagesRef.current.show({
            severity: severity,
            detail: msg,
            life: 15000
        });
    }
    return (
        <Layout>
            <Messages ref={messagesRef} />
            <Title type="h1">Edit Order #{params.discountId}</Title>
            <OrderEnhancedForm />
            {isLoading && 
                <div>Loading..</div>
            }
        </Layout>
    )
}

export default OrderEdit;
