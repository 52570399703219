import React from 'react';
import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import * as Yup from "yup";
//import { CALENDAR_FI} from '../../utils/globals';
import moment from 'moment';
import { Button } from 'primereact/button';
import { NOTIFICATION_TYPE } from './NotificationList';
import { InputTextarea } from 'primereact/inputtextarea';


export const notificationEditSchema =  Yup.object().shape({
    message: Yup.string()
        .required("Viesti on pakollinen tieto"),
    type: Yup.mixed<NOTIFICATION_TYPE>()
        .oneOf(Object.values(NOTIFICATION_TYPE)),
    start_showing: Yup.date()
        .required('Alkamisaika on pakollinen tieto'),
    stop_showing: Yup.date()
        .required('Päättymisaika on pakollinen tieto')
})

export const notificationCreateSchema = notificationEditSchema;

const NotificationTypes = [
    {name: 'Normaali', value: NOTIFICATION_TYPE.NORMAL},
    {name: 'Kiirellinen', value: NOTIFICATION_TYPE.URGENT},
    {name: 'Info', value: NOTIFICATION_TYPE.INFO},
]

const NotificationForm: React.FC<{}> = (props: any) => {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      isLoading,
      setFieldValue
    } = props;
    
    return (
      <form onSubmit={handleSubmit}>        
        <div className="p-field p-grid">
            <label htmlFor="type" className="p-col-fixed label" >Notifikaation Tyyppi</label>
            <div className="p-col-12">
                <Dropdown
                    placeholder="Valitse tyyppi"
                    optionLabel="name"
                    optionValue="value"
                    options={NotificationTypes}
                    onChange={handleChange}
                    //onBlur={handleBlur}
                    value={values.type}
                    name="type"
                    id="type"
                />
                {errors.type && touched.type && <Message className="form-field-error" severity="error" text={errors.type} />}
            </div> 
        </div>
        <div className="p-field p-grid">
            <label htmlFor="start_showing" className="p-col-fixed label" >Näytetään alkaen (päivän alusta)</label>
            <div className="p-col-12">
                <Calendar 
                    showTime
                    dateFormat="dd.mm.yy"
                    value={values.start_showing ? moment(values.start_showing).toDate() : undefined}
                    // @ts-ignore
                    onChange={(e) => setFieldValue('start_showing',moment(e.value.toString()).toDate())}
                    //locale={CALENDAR_FI}
                    id="start_showing"
                />
                {errors.start_showing && touched.start_showing && <Message className="form-field-error" severity="error" text={errors.start_showing} />}
            </div> 
        </div>
        <div className="p-field p-grid">
            <label htmlFor="stop_showing" className="p-col-fixed label" >Näyttäminen päättyy (valitun päivän alussa)</label>
            <div className="p-col-12">
                <Calendar 
                    showTime
                    dateFormat="dd.mm.yy"
                    value={values.stop_showing ? moment(values.stop_showing).toDate() : undefined}
                    // @ts-ignore
                    onChange={(e) => setFieldValue('stop_showing',moment(e.value.toString()).toDate())}
                    //locale={CALENDAR_FI}
                    id="stop_showing"
                />
                {errors.stop_showing && touched.stop_showing && <Message className="form-field-error" severity="error" text={errors.stop_showing} />}
            </div> 
        </div>
        <div className="p-field p-grid">
            <label htmlFor="message" className="p-col-fixed label" >Viesti</label>
            <div className="p-col-12">
                <InputTextarea
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                    name='message'
                    id='message'
                    cols={60}
                    rows={5}
                />
                {errors.message && touched.message && <Message className="form-field-error" severity="error" text={errors.message} />}
            </div> 
        </div>
        <div className="p-field p-grid">
            <label htmlFor="extra_info" className="p-col-fixed label" >&quot;Lue lisää&quot; - popupin teksti ( voi sisältää html - tageja ) </label>
            <div className="p-col-12">
                <InputTextarea
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.extra_info}
                    name='extra_info'
                    id='extra_info'
                    cols={60}
                    rows={5}
                />
                {errors.extra_info && touched.extra_info && <Message className="form-field-error" severity="error" text={errors.extra_info} />}
            </div> 
        </div>
        {/*
        <div className="p-field p-grid">
            <div className="p-col-12">
                <Checkbox 
                    checked={values.active}
                    onChange={(e) => setFieldValue('active',!values.active)}
                    inputId="active"
                />
                <label htmlFor="active" className="p-col-fixed label" >Aktiivinen</label>
                {errors.active && touched.active && <Message className="form-field-error" severity="error" text={errors.active} />}
            </div> 
        </div>*/}
        
        <Button disabled={isLoading} type="submit">Tallenna</Button>
       </form>
    )
}

export default NotificationForm;