import React from 'react';
import { Menubar } from 'primereact/menubar';
import LogoutButton from '../auth/LogoutButton';
import kirkaslasilogo from '../../static/img/Kirkaslasi-Ruskea_pallologo.png';
import { useHistory } from 'react-router-dom';


const AdminHeader = () => {

   const history = useHistory();

   const navigateToPage = (path: string) => {
		history.push(path);
	}

    const items = [
      {
         label:'Dashboard',
         icon:'pi pi-fw pi-calendar',
         command: () => navigateToPage('/admin/dashboard')
      },
      {
         label:'Tilaukset',
         command: () => navigateToPage('/admin/order/list'),
         icon:'pi pi-fw pi-table'
      },
      {
         label:'Kirkaslaskuri',
         command: () => navigateToPage('/admin/counter/edit/1'),
         icon:'pi pi-fw pi-table'
      },
      {
         label:'Alennukset',
         command: () => navigateToPage('/admin/discount/list'),
         icon:'pi pi-fw pi-angle-double-down'
      },
      {
         label:'Kalenteri',
         command: () => navigateToPage('/admin/calendar'),
         icon:'pi pi-fw pi-calendar-times'
      },
      {
         label:'Notifikaatiot',
         command: () => navigateToPage('/admin/notification/list'),
         icon:'pi pi-fw pi-angle-double-down'
      },
      {
         label: `Users`,
         icon:'pi pi-fw pi-user',
         command: () => navigateToPage('/admin/user/list'),
      },
      {
         label: `Settings`,
         icon:'pi pi-fw pi-cog',
         command: () => navigateToPage('/admin/settings'),
      }
     ];

     //const start = () => <img alt="logo" src={kirkaslasilogo} height="40" className="p-mr-2" />;
     const start = () => <img alt="logo" src={kirkaslasilogo} height="40" width="40" className="p-mr-2" />;
   
    return <div>
            <Menubar 
               model={items} 
               start={start} 
               end={() => <LogoutButton />} 
            />
        </div>
    
}

export default AdminHeader;
