import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPostalAreas, getPostalAreas, removePostalArea } from "../../../actions/settings";
import { RootState } from "../../../reducers/rootReducer";

import Title from '../../components/Title'
import postalCitiesCodes from '../postinumerot.json'

export interface IpostalAreaRow {
    id: string|number
    name: string
    postalCodes: Array<string>
}


const PostalAreaSettings: React.FC<{}> = () => {

    const {postalAreas} = useSelector((state:RootState) => state.settings)
    const [PostalAreaRows, setPostalAreaRows] = useState<Array<IpostalAreaRow>>([]);
    const dispatch = useDispatch();

    useEffect(() => {dispatch(getPostalAreas())}, [dispatch])
    useEffect(() => {setPostalAreaRows(postalAreas)}, [postalAreas])
        
    let postalOptionValues = useMemo(() => Object.keys(postalCitiesCodes).map((postalNumber) =>  
        ({
            // @ts-ignore
            label: postalNumber + " - " + postalCitiesCodes[`${postalNumber}`], 
            value: postalNumber  
        }),[]), [])

    const addNewArea = () => {
        setPostalAreaRows([
            ...PostalAreaRows, 
            {
                id: "new-" + new Date().getTime(), // unique value
                name: "",
                postalCodes: []
            } 
        ])
    }

    const removeRow = (id:any) => {
        if((id + "").lastIndexOf('new-', 0) !== 0) {
            dispatch(removePostalArea(id));
        }
        const newRows = PostalAreaRows.filter((row:IpostalAreaRow, i:number) => row.id !== id)
        setPostalAreaRows(newRows);
    }

    const handleChange = (key:string, value:string|Array<string>, id:string|number) => {
        let newRows = PostalAreaRows.map((row:IpostalAreaRow, i:number) => {
            if(row.id === id ) { 
                // @ts-ignore
                row[`${ key }`] = value 
            }
            return row;
        })
        setPostalAreaRows(newRows)
    }

    const getCleanerAreaRows = (rowsData:Array<IpostalAreaRow> ) => rowsData.map((row:IpostalAreaRow, index: number) => 
        <div className="p-grid p-justify-start" key={row.id}>
            <div className="p-field">
                <label htmlFor="name-field" className="p-col-fixed label" >Nimi</label>
                <InputText
                    className="name-field"
                    name="name"
                    value={row.name}
                    onChange={(e) => handleChange('name', e.target.value, row.id)}
                />
            </div>

            <div className="p-field">
                <label htmlFor="postal-code-field" className="p-col-fixed label" >Postinumeroalueet</label>
                <MultiSelect
                    value={row.postalCodes}
                    options={postalOptionValues}
                    onChange={(e) => handleChange('postalCodes', e.target.value, row.id)}
                    optionLabel="label"
                    filter
                    virtualScrollerOptions={{ itemSize: 34 }}
                />
            </div>
            <div className="p-field">
                <Button className="remove-row" onClick={() => removeRow(row.id)} label="-" /> 
            </div>
        </div>,[])
    
    return ( 
        <div>
            <div className="content-header">
                <Title type="h2">Postinumeroalueiden hallinta</Title>
            </div>
            <div className="tab-content">
                {getCleanerAreaRows(PostalAreaRows)}
                <div className="p-grid p-justify-start action-grid">
                    <div className="p-field">
                        <Button onClick={() => addNewArea()} label="Lisää uusi" />
                    </div>
                    <div className="p-field">
                        <Button onClick={() =>dispatch(createPostalAreas(PostalAreaRows))} label="Tallenna muutokset" />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PostalAreaSettings;