import React, { useEffect } from 'react';
import Helmet from 'react-helmet'
import Header from '../layout/Header'
import Content from '../layout/Content';
//import Footer from '../layout/Footer';
import Progressbar from '../components/Progressbar';
import Summarize from './calendar/Summarize';
import Address from './address/Address';
import Notification from '../components/Notification/Notification'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AddressPage: React.FC<{}>  = () => {

    const pageElement = React.useRef<HTMLDivElement>(null); // This would be safer to be just Summarize component, but would need to wrap it in div and it would crash the style sets.(doable though)
    const { t } = useTranslation();

    // useEffect(() => ReactGA.pageview(getPageUrl()),[])
    // Scroll to top on page load
    useEffect(() => {
        if (pageElement.current) {
            pageElement.current.scrollIntoView({ block: 'start' });
        }
    }, []);


    const history = useHistory();
    const prevPage = () => history.goBack(); //push('/tilauskalenteri') // Changed due to need of /ostari page.
    return (
        <>
            <Helmet>
                <title>Tilauksen tiedot - Ikkunanpesun hintalaskuri</title>
                <meta name="description" content="Laske ikkunanpesun hinta helposti parissa minuutissa ja tilaa pesu" />
                <meta name="keywords" content="Ikkunanpesun hinta, Ikkunanpesun hintalaskuri, Kirkaslasi, Ikkunanpesu, Ikkunanpesu netistä" />
            </Helmet>
            <Header/>
            <Content >
                <>
                    <Notification />
                    <div className="address-page p-grid p-justify-center" ref={pageElement}>
                        <div className="page-head p-col-12">
                            <div className="box">
                                <Progressbar step={2} />
                                <div className="calendar-page-title p-col-12 p-lg-8 addr">
                                    {t('contact.order_info')}
                                </div>
                            </div>
                        </div>
                        <Summarize page="address" />
                        <div className="address p-col-10 p-lg-5">
                            <button className="addr-prev-btn" onClick={() => prevPage()}>
                                <span className="prev-page-icon pi pi-chevron-left"></span>
                            </button>
                            <div className="box">
                                <Address addressPageElement={pageElement} />    
                            </div>
                        </div>
                    </div>
                </>
            </Content>
            {/*<Footer toPath="/"/>*/}
        </>
    )
}

export default AddressPage;