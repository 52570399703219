import React from 'react'
import pesuri from '../../static/img/Amparikuva.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { getTimeSlotUI, isFirstSlotOfDay } from '../calendar/Calendar';
import { Trans, useTranslation } from 'react-i18next';


const DeliveryBlock = (props: { shoppingMall?: boolean }) => {

    const isShoppingMall = props.shoppingMall ? true : false;
    const {shippingDay} = useSelector((state:RootState) => state.order.order);
    const { t } = useTranslation();


    return <div className="shipping-date-row">
        <img src={pesuri} alt="sanko ja luuta" />
        { !isShoppingMall &&
        <>
            <span>{t('summary_part.chosen_time')}</span>
            <span className="date">{getTimeSlotUI(shippingDay, isShoppingMall)}</span>
            {(isFirstSlotOfDay(shippingDay) || isShoppingMall) ? '' : <Trans i18nKey='calendar.starting_time_guide' />}
        </>
        }
    </div>
}

export default DeliveryBlock;