import { InputTextarea } from 'primereact/inputtextarea';
import React, {FocusEvent} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateAdditionalInfo } from '../../actions/order';
import { RootState } from '../../reducers/rootReducer';
import { useTranslation } from 'react-i18next';

const AdditionalInfo = () => {
    const {order, isLoading} = useSelector((state:RootState) => state.order);
    const dispatch = useDispatch()
    const { t } = useTranslation();

    return <>
        {!isLoading &&
            <div className="ac-tab-content-wrapper additional-info">
                <div className="p-grid p-justify-start">
                    <div className="accordion-tab-col p-col-12 p-sm-6 p-lg-6">
                        <InputTextarea 
                            placeholder={t('tabs.additional_info_placeholder')}
                            className="textarea-additional-info"
                            rows={6} 
                            cols={40}
                            value={order.additionalInfo}
                            onChange={(e: FocusEvent<HTMLTextAreaElement>) => dispatch(updateAdditionalInfo(e.target.value)) }
                        />
                    </div>
                    {/* <div className="accordion-tab-col p-col-6 p-sm-6 p-lg-6">
                        <FileInsertion />
                    </div> */}
                </div>
            </div>
        }
    </>
}

export default AdditionalInfo