
export const blobToBase64 = (blob:Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        // resolve(reader.result);
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          console.log('Failed to convert Blob to base64.');
          throw new Error('Failed to convert Blob to base64.');
        }
      };
    });
  };