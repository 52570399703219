
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';


const FreeTimeModal = (props: {placeClass:string}) => {
    const { t } = useTranslation();

    const history = useHistory();
    
    return (
        <div className={`check-free-time p-col-12 p-md-12 p-sm-12 ${props.placeClass}`}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={() => history.push('/vapaat-pesuajat')} >{t('check_times')}</a>
        </div>
    );
};

export default FreeTimeModal
