import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Route, Redirect } from 'react-router-dom'
import { loadAdminUserAction } from '../actions/auth';
import { RootState } from '../reducers/rootReducer';


const ProtectedRoute: React.FC<{
        component: React.FC;
        path: string;
        exact: boolean;
    }> = (props: any) => {
        
        const dispatch = useDispatch();
        const {authenticated, user, error, isLoading, logout} = useSelector((store: RootState) => store.auth)
        const [authDispatched, setAuthDispatched] = useState(false);

        // Fetch user data
        useEffect(() => {
            if((!user.name && !error && !isLoading && !logout)){
                dispatch(loadAdminUserAction())
                setAuthDispatched(true)
            }
        },[dispatch, user, isLoading, error, logout])

        if(!authenticated && (isLoading || !authDispatched ) && !logout){
            return <div>loading...</div>;
        }
        return authenticated ? 
            (<Route  path={props.path}  exact={props.exact} component={props.component} />) : 
            (<Redirect  to="/admin"  />);
};
export  default  ProtectedRoute;