import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadCountersConfigAction } from '../../actions/counter'
import Layout from '../components/Layout'


const CounterConfigList: React.FC<{}> = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadCountersConfigAction())
    },[dispatch])

    return <Layout>
        list
    </Layout>
}

export default CounterConfigList