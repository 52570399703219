import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { loadCounterConfigAction } from '../actions/counter';
import { initOrder, mallSelectedAction } from '../actions/order';
import Content from '../layout/Content';
import Header from '../layout/Header';
import { RootState } from '../reducers/rootReducer';
import { getOrderInitPayload } from '../utils/customSelectors';
import Notification from '../components/Notification/Notification';
import star from '../static/img/Star.svg';
// import useWindowDimensions from '../hooks/useWindowDimension';
// import { useHistory } from 'react-router-dom';
import MallAccordion from './counter/MallAccordion';
import TopContent from './counter/TopContent';
import { Dialog } from 'primereact/dialog';
import itis_logo from '../static/img/malls/itis_logo.png'
import sello_logo from '../static/img/malls/sello_logo.png'
import jumbo_logo from '../static/img/malls/jumbo_logo.png'
import iso_omena_logo from '../static/img/malls/iso_omena_logo.png'
import muu_ostoskeskus_logo from '../static/img/malls/muu_ostoskeskus_logo.png'
import { MALL, MALL_NAME } from '../reducers/order';
import MobileFooterAction from '../layout/MobileFooterAction';

const SHOPPING_MALL_CONFIG_ID = 9999;


const ShoppingMall: React.FC<{}> = () => {
    const { config /*, isLoading, error*/ } = useSelector((state: RootState) => state.counter);
    const { windows, mall } = useSelector((state: RootState) => state.order.order);
    const [ showWelcome, setShowWelcome ] = useState(false);

    const orderInitialValues = useSelector((state: RootState) => getOrderInitPayload(state))
    const dispatch = useDispatch();
    // const history = useHistory();

    // useEffect(() => {
    //     ReactGA.pageview(getPageUrl());
    // }, [])

    useEffect(() => {
        if (!config) {
            dispatch(loadCounterConfigAction(SHOPPING_MALL_CONFIG_ID));
        }
        if (orderInitialValues && config && windows.length === 0) { // TODO: If there are no windows in config(admin), infinite loop is caused.
            dispatch(initOrder(orderInitialValues));
        }
    }, [dispatch, config, windows, orderInitialValues]);

    const handleMallSelection = (mall: MALL) => {
        setShowWelcome(false);
        dispatch(mallSelectedAction(mall));
    }

    const showMallSelection: boolean = (showWelcome || !mall?.mall_name?.length);

    const dialogHeader = `Valitse kauppakeskus`

    const dialogContent = 
        <> 
            <div className='mall-logo-wrapper' onClick={() => handleMallSelection({ name: MALL_NAME.ISO_OMENA, address: 'Iso Omena', postal_code: '02230' })} >
                <img src={iso_omena_logo} alt="Iso_Omena_logo" height="42px" />
            </div>
            <div className='mall-logo-wrapper itis' onClick={() => handleMallSelection({ name: MALL_NAME.ITIS, address: 'Itis', postal_code: '00930' })}>
                <img src={itis_logo} alt="Itis_logo" height="60px" />
            </div>
            <div className='mall-logo-wrapper' onClick={() => handleMallSelection({ name: MALL_NAME.JUMBO, address: 'Jumbo', postal_code: '01510' })} >
                <img src={jumbo_logo} alt="Jumbo_logo" height="51px" />
            </div>
            <div className='mall-logo-wrapper' onClick={() => handleMallSelection({ name: MALL_NAME.SELLO, address: 'Sello', postal_code: '02600' })} >
                <img src={sello_logo} alt="Sello_logo" height="60px" />
            </div>
            <div className='mall-logo-wrapper muu-ostoskeskus' onClick={() => handleMallSelection({ name: MALL_NAME.MUU_OSTOSKESKUS, address: 'Muu ostoskeskus', postal_code: '00930' })} >
                <img src={muu_ostoskeskus_logo} alt="Ostoskeskus_logo" height="45px" />
                <span> Muu ostoskeskus</span>
            </div>

        </>;



    return (
        <>
            <Helmet>
                <title>Ikkunanpesun hintalaskuri - Kirkaslasi</title>
                <meta name="description" content="Laske ikkunanpesun hinta helposti parissa minuutissa ja tilaa pesu" />
                <meta name="keywords" content="Ikkunanpesun hinta, Ikkunanpesun hintalaskuri, Kirkaslasi, Ikkunanpesu, Ikkunanpesu netistä" />
            </Helmet>
            <Header />
            <Content >
                <>
                    <Dialog
                        className='shopping-mall-list'
                        header={dialogHeader}
                        visible={showMallSelection}
                        footer=''
                        onHide={() => setShowWelcome(false)}
                    >
                        {dialogContent}
                    </Dialog>
                    <Notification />
                    <div className="landingpage-page-content p-grid">
                        <TopContent />
                        <MallAccordion selectMall = {() => setShowWelcome(true)}/>
                        <div className="p-col-12 bottom star">
                            <img src={star} alt="tahti" />
                        </div>
                    </div>
                </>
            </Content>
            <MobileFooterAction toPath="/ostari/yhteystiedot" />
        </>
    )
}

export default ShoppingMall;