import React, { useEffect, useState } from 'react';
import * as PrimeCalendar from 'primereact/calendar';
import { locale } from 'primereact/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { setShippingDay } from '../../actions/order';
import moment from 'moment';
import * as Moment from 'moment';

import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { CALENDAR_TYPE } from './enums';
import { extendMoment } from 'moment-range';
import { SHOW_TIME_FORMAT_H_M } from '../../utils/globals';
import { Trans, useTranslation } from 'react-i18next';

export const getTimeSlotUI = (date: any, shoppingMall = false) => {
    if (!date) {
        return '';
    }
    const d = moment(date);
    if (shoppingMall) {
        return d.format('MM YYYY'); // For some reason shows with english locale. Not in use currently.
    }
    // Round to nearest 30min and estimated time is from -1hour to +1hour from that. The same logic is used in the backend.
    const minuteRemainder = d.minute() % 30;
    const minutesToRound = minuteRemainder > 14 ? 30 - minuteRemainder : -minuteRemainder; 

    return isFirstSlotOfDay(d) ? d.format(SHOW_TIME_FORMAT_H_M) :
        moment(date)
            .add(minutesToRound, "minutes")
            .add(-60, "minutes")
            .format(SHOW_TIME_FORMAT_H_M) + " - " +
        moment(date)
            // set starting time to end 2 hours from start;
            .add(minutesToRound, "minutes")
            .add(60, "minutes")
            .format('HH:mm')
}

export const isFirstSlotOfDay = (startingDate: any) => moment(startingDate).hours() === 8;

const Calendar = ({
    type = CALENDAR_TYPE.CHOICE
}: any) => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { shippingDay, cleanerId } = useSelector((state: RootState) => state.order.order)
    const { available_dates } = useSelector((state: RootState) => state.calendar)
    const [disabledDates, setDisabledDates] = useState<Date[] | undefined>(undefined);
    const [viewDate, setViewDate] = useState(moment().add(2, 'days').toDate());
    const history = useHistory();

    const momentRange = extendMoment(Moment);
    const maxDate = moment().add(3, 'month').toDate();
    // const viewThisDateOnRendering = moment().add(5, 'days').toDate();

    const currentLang = i18n.language;
    if (['fi', 'en'].includes(currentLang)) {
        locale(currentLang);
    }

    useEffect(() => {
        const minD = minDate();
        const maxD = moment().add(3, 'month').toDate();
        const range = momentRange.range(minD, maxD);

        let datesInNextXday = Array.from(range.by('day')).map((stepMoment: any) => stepMoment.format('YYYY-MM-DD'), []);
        let dateToRender: any;
        if (available_dates !== false) {
            available_dates.map((dateSlot: any) => {
                let index = datesInNextXday.indexOf(moment(dateSlot.date).format('YYYY-MM-DD'))
                if (index !== -1) {
                    if (!dateToRender && !isWeekend(dateSlot.date)) {
                        dateToRender = moment(dateSlot.date).toDate(); // First available slot
                    }
                    delete datesInNextXday[index];
                }
                return true;
            });
        }
        const dates: Date[] = datesInNextXday.reduce((acc: any, date: any) => {
            acc.push(moment(date).toDate())
            return acc;
        }, []);
        setDisabledDates(dates);

        if (!available_dates || available_dates.length === 0) {
            dispatch(setShippingDay(undefined, undefined))
        } else {
            if (dateToRender) {
                setViewDate(dateToRender); // Calendar to open the month view on this day
            }
        }

    }, [available_dates, dispatch, momentRange])

    const dateTemplate = (date: any) => <div className="date-col">{date.day}</div>;

    /* "varoaika" muutettu kuluva pv + 1 pv jos tilaus tehty aamulla ennen klo 10. Muuten kuluva pv + 2 pv.
        Juhlapyhiä ei otettu tässä huomioon, vain viikonloput. */
    const minDate = () => {
        const currentDate = moment(),
            currentHour = currentDate.hour(),
            currentDay = currentDate.day(),
            gapDays = currentHour <= 9 ? 2 : 3,
            daysToAdd = currentDay === 5 ? gapDays + 2 : currentDay === 6 ? gapDays + 1 : gapDays;
        return currentDate.add(daysToAdd, 'days').toDate();
    }

    const isWeekend = (date: any): boolean => {
        const dayOfWeek = moment(date).day();
        return (dayOfWeek === 6) || (dayOfWeek === 0); // 6 = Saturday, 0 = Sunday
    } 

    const isAvailableDate = (date: any): boolean => {
        return Array.isArray(available_dates) ? available_dates.some((dateSlot: any) =>
            moment(date).format('YYYY-MM-DD') === moment(dateSlot.date).format('YYYY-MM-DD')
        ) : false;
    }

    const formattedShippingDay = () => {
        if (!available_dates) {
            return ''
        }
        const selectedDate = available_dates.filter((dateSlot: any) =>
            moment(shippingDay).format('YYYY-MM-DD') === moment(dateSlot.date).format('YYYY-MM-DD')
        )[0];

        const formattedDate = selectedDate ? getTimeSlotUI(selectedDate.starting) : '';

        return (
            <>
                <span className="date">{formattedDate}</span>
                {selectedDate && !isFirstSlotOfDay(selectedDate.starting) ?
                    <span className="info-text">
                        <Trans i18nKey='calendar.starting_time_guide' />
                    </span> :
                    <span className="info-text-empty"></span>
                }
            </>
        )
    }

    const getSelectedDateCleanerId = (date: any) => {
        const selectedDate = available_dates.filter((dateSlot: any) =>
            moment(date).format('YYYY-MM-DD') === moment(dateSlot.date).format('YYYY-MM-DD')
        )[0];
        return (typeof selectedDate === 'object') ? selectedDate.cleanerId : undefined
    }

    const getStartingDatetime = (date: any) => {
        const selectedDate = available_dates.filter((dateSlot: any) =>
            moment(date).format('YYYY-MM-DD') === moment(dateSlot.date).format('YYYY-MM-DD')
        )[0];
        return moment(selectedDate.starting).toString();
    }
    
    return (
        <div className="calendar-content">
            <PrimeCalendar.Calendar
                inline
                //locale={CALENDAR_FI}
                minDate={minDate()}
                maxDate={maxDate}
                dateTemplate={dateTemplate}
                value={shippingDay ? moment(shippingDay).toDate() : undefined}
                // @ts-ignore
                onChange={(e) => dispatch(setShippingDay(getStartingDatetime(e.value.toString()), getSelectedDateCleanerId(e.value.toString())))}
                disabledDays={[0, 6]}
                disabledDates={disabledDates}
                viewDate={viewDate}
                onViewDateChange={(e) => setViewDate(e.value)}
            ></PrimeCalendar.Calendar>
            {(shippingDay && cleanerId && type === CALENDAR_TYPE.CHOICE) && <div className="selected-date">
                {formattedShippingDay()}
                {/*<span className="date">{formattedShippingDay()}</span>
                <span className="time">Aikavälit ovat arvioituja <strong>aloitusaikoja</strong>. <br />
                    Eli työ yleensä jatkuu aloitusajan jälkeenkin.<br />
                    Päivän ensimmäisenä aloitusaikana <br />
                    saavumme klo 8:00, mutta muina aikoina pesijä<br />
                    soittaa pesupäivänä ennen pesua, <br />
                    kun osaa arvioida ajan tarkemmin.
                </span> */}
                {/*<span className="duration">pesun kesto riippuu ikkunoiden lukumäärästä</span>*/}
                <Button
                    onClick={() => history.push('/yhteystiedot')}
                    type="submit"
                    label={t(isAvailableDate(shippingDay) ? 'calendar.confirm_time' : 'calendar.select_free_time')}
                    disabled={!isAvailableDate(shippingDay)}
                ></Button>
            </div>}
            {type === CALENDAR_TYPE.CHECK && <div className="selected-date">
                {formattedShippingDay()}
                {/* <span className="date">{formattedShippingDay()}</span> */}
                <Button
                    onClick={() => history.push('/')}
                    type="submit"
                    label={t('calendar.continue_to_window')}
                ></Button>
            </div>}
        </div>
    );
};

export default Calendar
