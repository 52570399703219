import React from 'react'
import { COMMON_TEL } from '../../utils/globals'
import { useTranslation } from 'react-i18next';


const Terms: React.FC<{consumer: boolean, shoppingMall: boolean}> = (props: any) => {
    const {consumer, shoppingMall} = props;
    const { t } = useTranslation();

    return <>
        {// Shopping mall terms
        shoppingMall && 
        <div className="term-modal-container">
            <p>
                <span>Yleiset tiedot:</span>
                <ul>
                    <li>Vastuuvakuutuksemme on suuruudeltaan 500 000 €.</li>
                    <li>Työntekijämme ovat suomenkielisiä.</li>
                </ul>
            </p>
            <p>
                <span>Työn sisältö:</span>
                <ul>
                    <li>Työ sisältää tilattujen ikkunapintojen pesun</li>
                    <li>Pesu sisältää kertapesun perinteisellä ikkunanpesun pesurilla sekä lastalla.</li>
                    <li>Tärkeää: Kaikki pinttynyt lika ei välttämättä lähde ikkunoista. Pinttyneen tai normaalista likaantumisesta poikkeava pinttyneen lian poisto on lisätyötä, eikä se sisälly tilaukseen.</li>
                    <li>Kaikki ikkunanpesussa tarvittavat välineet ja aineet tulevat Kirkaslasilta.</li>
                    <li>Jos tilaukseen sisältyy näyteikkunoiden sisäpintojen pesu, ensimmäinen sisäpintojen pesu suoritetaan, kun sopimuksen tekohetkestä on 6kk.</li>
                </ul>
            </p>
            <p>
                <span>Muut ehdot:</span>
                <ul>
                    <li>Hintoja tarkistetaan indeksikorotuksella vuoden välein. Indeksikorotus pohjautuu tilastokeskuksen elinkustannusindeksiin.</li>
                    <li>Jos tilaus perutaan alle kuukauden aikana työn sovitusta aloitusajankohdasta, tai jos työpaikanpäällä estyy Kirkaslasista riippumattomasta syystä, tilaussummasta veloitetaan 30 % sekä työn suorittamiseen liittyvät mahdolliset muut kulut, esim. nostinkulu tai tähän verrattavat muut kulut.</li>
                    <li>Tilauksen peruutus on suoritettava sähköpostilla osoitteeseen info@kirkaslasi.fi. Jos tilaus peruutetaan, toivotaan siitä myös puhelimitse tehtyä ilmoitusta numeroon {COMMON_TEL}.</li>
                    <li>Tilauksen peruutuksissa maksunpalautus suoritetaan tilauksessa käytetylle maksutavalle.</li>
                    <li>Reklamaatiot ilmoitetaan kirjallisesti osoitteeseen info@kirkaslasi.fi. Mahdolliset reklamaatiot tulee ilmoittaa kahden (2) vuorokauden kuluessa työn valmistumisesta.
                        Työ laskutetaan tarvittaessa osittain toteutuneiden työtuntien mukaan.</li>
                    <li>Reklamaatiot hoidetaan kohtuullisessa ajassa.</li>
                    <li>Arvoesineet tulee siirtää pois pesun tieltä vahingoittumisen välttämiseksi.</li>

                    <li>Oikeus tilauksen ikkunamäärien ja -tyyppien tarkistamiseen paikanpäällä pidätetään. Samoin pidätetään Kirkaslasin oikeus perua tilaus yksipuolisesti tai muuttaa tilauksen hintaa. </li>

                    <li>Tilaajan ilmoittaessa tilauksella tai tarjouspyynnöllä väärän määrän ikkunoita, tilaaja on velvollinen korvaamaan vain tilattujen ikkunoiden pesun.</li>
                </ul>
            </p>
            <p>
                <span>Laskutusehdot:</span>
                <ul>
                    <li>Viivästyskorko lain mukaan</li>
                    <li>Maksumuistutus 10 €</li>
                    <li>10 pv netto</li>
                </ul>
            </p>
        </div>}

        {// Consumer terms
        consumer && !shoppingMall && <div className="term-modal-container">
                <section>
                    <span>{t('terms.general_title')}:</span>
                    <ul>
                        <li>{t('terms.general1')}</li>
                        <li>{t('terms.general2')}</li>
                    </ul>
                </section>
                <section>
                    <span>{t('terms.include_title')}:</span>
                    <ul>
                        <li>{t('terms.include1')}</li>
                        <li>{t('terms.include2')}</li>
                        <li>{t('terms.include3')}</li>
                    </ul>
                </section>
                <section>
                    <span>{t('terms.extra_title')}:</span><span className="normal-text">{t('terms.extra_title2')}</span>
                    <ul>
                        <li>{t('terms.extra1')}</li>
                        <li>{t('terms.extra2')}</li>
                        <li>{t('terms.extra3')}</li>
                        <li>{t('terms.extra4')}</li>
                        <li>{t('terms.extra5')}</li>
                        <li>{t('terms.extra6')}</li>
                    </ul>
                </section>
                <section>
                    <span>{t('terms.other_title')}:</span>
                    <ul>
                        <li>{t('terms.other1')}</li>
                        <li>{t('terms.other2')}</li>
                        <li>{t('terms.other3')}</li>
                        <li>{t('terms.other4')}</li>
                        <li>{t('terms.other5')}</li>
                        <li>{t('terms.other6')}</li>
                        <li>{t('terms.other7')}</li>
                        <li>{t('terms.other8')}</li>
                    </ul>
                </section>
                <section>
                    <span>{t('terms.payment_title')}:</span>
                    <ul>
                        <li>{t('terms.payment1')}</li>
                        <li>{t('terms.payment2')}</li>
                        <li>{t('terms.payment3')}</li>
                    </ul>
                </section>
            {/* <section>
                <span>Yleiset tiedot:</span>
                <ul>
                    <li>Vastuuvakuutuksemme on suuruudeltaan 500 000 €</li>
                    <li>Kommunikoimme suomeksi.</li>
                </ul>
            </section>
            <section>
                <span>Työn sisältö:</span>
                <ul>
                    <li>Työ sisältää tilattujen ikkunapintojen pesun, kaihdinten kuivaharjauksen sekä sisälle näkyvien ikkunapuitteiden kosteapyyhinnän.</li>
                    <li>Pesu sisältää kertapesun perinteisellä ikkunanpesun pesurilla sekä lastalla.</li>
                    <li>Tärkeää: Kaikki pinttynyt lika ei välttämättä lähde ikkunoista.</li>
                </ul>
            </section>
            <section>
                <span>Lisätyöt:</span><span className="normal-text">(ei. sis. tilaukseen)</span>
                <ul>
                    <li>Ikkunapeltien pesu. </li>
                    <li>Pesurilla ja lastalla irtoamaton, tai mikä tahansa muu pinttynyt lika. </li>
                    <li>Rakenteelliset valumat, laasti, tarrat ja niiden jäljet, orgaaniset tai epäorgaaniset liat, hyönteisten jätökset tai muu näihin verrattava pinttynyt lika. </li>
                    <li>Vialliset-, saranattomat-, hankalasti saavutettavat-, tai muutoin haastavat ikkunat ovat lisätyötä, ellei niistä ole tilausta tehtäessä muuta sovittu.</li>
                    <li>Aiheettomat reklamaatiokäynnit.</li>
                    <li>Lisätyön hinta 69 €/h + matkakulut. Lisätöistä sovitaan tilaajan kanssa aina etukäteen.</li>    
                </ul>
            </section>
            <section>
                <span>Muut ehdot:</span>
                <ul>
                    <li>Jos tilaus perutaan alle viiden (5) arkipäivän aikana työn sovitusta aloitusajankohdasta, tai jos työ paikanpäällä estyy, tilauksen kokonaisarvosta veloitetaan vähintään 30 %.</li>
                    <li>Reklamaatiot ilmoitetaan kirjallisesti osoitteeseen info@kirkaslasi.fi. </li> 
                    <li>Mahdolliset reklamaatiot tulee ilmoittaa kahden (2) vuorokauden kuluessa työn valmistumisesta.</li>
                    <li>Työ laskutetaan tarvittaessa osittain toteutuneiden työtuntien mukaan.</li>
                    <li>Arvoesineet tulee siirtää pois pesun tieltä vahingoittumisen välttämiseksi.</li>
                    <li>Hintoihin voidaan tehdä vuosittain indeksikorotus.</li> 
                    <li>Oikeus tilauksen ikkunamäärien ja -tyyppien tarkistamiseen paikanpäällä pidätetään. Samoin pidätetään Kirkaslasin oikeus perua tilaus yksipuolisesti tai muuttaa tilauksen hintaa. Tilaajalla on oikeus perua tilaus, hinnan muuttuessa.</li>
                </ul>
            </section>
            <section>
                <span>Laskutusehdot:</span>
                <ul>
                    <li>Viivästyskorko lain mukaan</li>
                    <li>Maksumuistutus 10 €</li>
                    <li>14 pv netto</li>
                </ul>
            </section> */}
        </div>}
        
        {// Enterprice terms
        !consumer && !shoppingMall &&
        <div className="term-modal-container">
            <section>
                <span>Yleiset tiedot:</span>
                <ul>
                    <li>Vastuuvakuutuksemme on suuruudeltaan 500 000 €</li>
                    <li>Työntekijämme ovat suomenkielisiä</li>
                </ul>
            </section>
            <section>
                <span>Työn sisältö:</span>
                <ul>
                    <li>Työ sisältää tilattujen ikkunapintojen pesun, kaihdinten kuivaharjauksen sekä sisälle näkyvien ikkunapuitteiden kosteapyyhinnän.</li>
                    <li>Pesu sisältää kertapesun perinteisellä ikkunanpesun pesurilla sekä lastalla.</li>
                    <li>Tärkeää: Kaikki pinttynyt lika ei välttämättä lähde ikkunoista. Pinttyneen tai normaalista likaantumisesta poikkeava pinttyneen lian poisto on lisätyötä, eikä se sisälly tilaukseen.</li>
                    <li>Kaikki ikkunanpesussa tarvittavat välineet ja aineet tulevat Kirkaslasilta.</li>
                </ul>
            </section>
            <section>
                <span>Lisätyöt:</span>
                <ul>
                    <li>Lisätyötä ovat esimerkiksi ikkunapeltien pesu pesurilla ja lastalla irtoamaton, tai mikä tahansa muu pinttynyt lika, rakenteelliset valumat, laasti, tarrat ja niiden jäljet, orgaaniset tai epäorgaaniset liat, hyönteisten jätökset. </li>
                    <li>Samoin vialliset-, saranattomat-, tai muutoin muutoin haastavat ikkunat ovat lisätyötä, ellei niistä ole tilausta tehtäessä muuta sovittu.</li>
                    <li>Aiheettomat reklamaatiokäynnit matkakuluineen. </li>
                    <li>Lisätyön hinta on 69 €/h. Köysi- ja erikoistöiden hinta on 70 €/h.</li>
                </ul>
            </section>
            <section>
                <span>Muut ehdot:</span>
                <ul>
                    <li>Jos tilaus perutaan alle viiden (5) arkipäivän aikana työn sovitusta aloitusajankohdasta, tai jos työpaikanpäällä estyy Kirkaslasista riippumattomasta syystä, tilaussummasta veloitetaan 30 % sekä työn suorittamiseen liittyvät mahdolliset muut kulut, esim. nostinkulu tai tähän verrattavat muut kulut.</li>
                    <li>Tilauksen peruutus on suoritettava sähköpostilla osoitteeseen info@kirkaslasi.fi. Jos tilaus peruutetaan, toivotaan siitä myös puhelimitse tehtyä ilmoitusta numeroon {COMMON_TEL}.</li>
                    <li>Tilauksen peruutuksissa maksunpalautus suoritetaan tilauksessa käytetylle maksutavalle.</li>
                    <li>Reklamaatiot ilmoitetaan kirjallisesti osoitteeseen info@kirkaslasi.fi.Mahdolliset reklamaatiot tulee ilmoittaa kahden (2) vuorokauden kuluessa työn valmistumisesta.
                            Työ laskutetaan tarvittaessa osittain toteutuneiden työtuntien mukaan.</li>
                    <li>Reklamaatiot hoidetaan kohtuullisessa ajassa.</li>
                    <li>Arvoesineet tulee siirtää pois pesun tieltä vahingoittumisen välttämiseksi.</li>
                    
                    <li>Oikeus tilauksen ikkunamäärien ja -tyyppien tarkistamiseen paikanpäällä pidätetään. Samoin pidätetään Kirkaslasin oikeus perua tilaus yksipuolisesti tai muuttaa tilauksen hintaa. </li>
                    
                    <li>Tilaajan ilmoittaessa tilauksella tai tarjouspyynnöllä väärän määrän ikkunoita, tilaaja on velvollinen korvaamaan vain tilattujen ikkunoiden pesun.</li>
                </ul>
            </section>
            <section>
                <span>Laskutusehdot:</span>
                <ul>
                    <li>Viivästyskorko lain mukaan</li>
                    <li>Maksumuistutus 10 €</li>
                    <li>10 pv netto</li>
                </ul>
            </section>
        </div>
        }
    </>

}

export default Terms;