import React, { useEffect,  useState } from 'react'

import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { validatePostalAndGetAvailableDays } from '../../actions/calendar';
import * as Yup from "yup";
import { getWashingTime } from '../../utils/customSelectors';
import { useHistory } from 'react-router-dom';
import { setPostalCode } from '../../actions/order';
import { useTranslation } from 'react-i18next';

const schema = Yup.string()
.required('Syötä postinumero')
.matches(/^[0-9]+$/, "Syötä ainoastaan numeroita")
.max(5, "");


const CalendarPostalcode = () => {

    const history = useHistory();
    const prevPage = () => history.push('/')
    const {postalCode} = useSelector((state:RootState) => state.order.order.address)
    const [currentPostalCode, setCurrentPostalCode] = useState<string|any>('')
    const [lastValidatedPostalCode, setlastValidatedPostalCode] = useState<string|any>('')
    const { available_dates } = useSelector((state:RootState) => state.calendar)

    const [errorMessage, setErrorMessage ] = useState<string>()
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const washing_time = useSelector((state:RootState) => getWashingTime(state))
    
    // if postalCode gived in counter page get available dates based on that code

    
    useEffect(() => {
        if(postalCode && postalCode.length === 5){
            setCurrentPostalCode(postalCode)
            setlastValidatedPostalCode(currentPostalCode)
            if (currentPostalCode?.length === 5) {
                dispatch(validatePostalAndGetAvailableDays(currentPostalCode, washing_time))
            }
        }
        // @ts-ignore
    },[])

    useEffect(()=> {
        if(!isNaN(washing_time) && currentPostalCode.length === 5 && lastValidatedPostalCode !== currentPostalCode){
            setErrorMessage('');
            setlastValidatedPostalCode(currentPostalCode)
            dispatch(setPostalCode(currentPostalCode))
            dispatch(validatePostalAndGetAvailableDays(currentPostalCode, washing_time))
        }
    },[lastValidatedPostalCode, currentPostalCode, washing_time, dispatch])

    useEffect(() => {
        if (!available_dates && currentPostalCode.length === 5) {
            setErrorMessage('Antamallesi postinumeroalueelle ei löydy vapaita aikoja')
        } else {
            setErrorMessage('')
        }
    }, [available_dates, currentPostalCode])

    const validateInput = (value:any) => {
        if(value.length > 0 ) {
            schema.validate(value)
            .then(function (valid) {
                setCurrentPostalCode(value)
                setErrorMessage('')
            })
            .catch(function (err:any) {
                setErrorMessage(err.errors[0])
            });
        }else{
            setCurrentPostalCode(value)
        }
    }

    return <div className="box postal-wrapper-box">

        <button className="cal-prev-btn" onClick={() => prevPage()}>
            <span className="prev-page-icon pi pi-chevron-left"></span>
        </button>
        <span className="p-float-label">
            <div className="postal-code-box">
                <InputText 
                    value={currentPostalCode}
                    onChange={(e:any) => validateInput(e.target.value)}
                    maxLength={5}
                    autoFocus={true}
                    id="postal" 
                    name="postal-code" />
                <label htmlFor="postal">{t('postal_code')}</label>
            </div>
                {errorMessage && <p className="error-messagee">{errorMessage}</p> }
        </span>
        </div>
}

export default CalendarPostalcode;