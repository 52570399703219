import {
    POST_ADMIN_LOGIN,
    GET_ADMIN_PROFILE,
    GET_ADMIN_REFRESH_AUTH,
    POST_ADMIN_LOGOUT
} from '../admin/auth/constants';


const initialState = {
    user: {},
    authenticated: false,
    needRefresh: false,
    logout: false,
    isLoading: false,
    error: false
}

export function authReducer(state = initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
        case `${GET_ADMIN_PROFILE}_PENDING`:
        case `${GET_ADMIN_REFRESH_AUTH}_PENDING`:
        case `${POST_ADMIN_LOGOUT}_PENDING`:
            return {
                ...state,
                ...{error: false, isLoading: true}
            }
        case `${POST_ADMIN_LOGIN}_PENDING`:
            return {
                ...state,
                ...{logout: false, error: false, isLoading: true}
            }
        case `${POST_ADMIN_LOGIN}_FULFILLED`:
        case `${GET_ADMIN_PROFILE}_FULFILLED`:
            return {
                ...state,
                ...{
                    user: action.payload,
                    authenticated: action.payload.id ? true : false ,
                    error: false,
                    isLoading: false, 
                    needRefresh: false
                }
            }
        case `${POST_ADMIN_LOGIN}_REJECTED`:
            return {
                ...state,
                ...{ authenticated: false, error: true, isLoading: false}
            }

        /* GET_ADMIN_PROFILE */
        case `${GET_ADMIN_PROFILE}_REJECTED`:
            return {
                ...state,
                ...{ authenticated: false, error: true, isLoading: false, needRefresh: true }
            }

        /* GET_REFRESH_ADMIN_AUTH */
        case `${GET_ADMIN_REFRESH_AUTH}_FULFILLED`:
            return {
                ...state,
                ...{
                    needRefresh: false,
                    error: false,
                    isLoading: false
                }
            }
        case `${GET_ADMIN_REFRESH_AUTH}_REJECTED`:
            return {
                ...state,
                ...{ authenticated: false, error: true, isLoading: false, needRefresh: false }
            }

        /* GET_ADMIN_LOGOUT */
        case `${POST_ADMIN_LOGOUT}_FULFILLED`:
            return {
                ...state,
                ...{
                    user: {},
                    logout: true,
                    authenticated: false,
                    error: false,
                    isLoading: false
                }
            }
        case `${POST_ADMIN_LOGOUT}_REJECTED`:
            return {
                ...state,
                ...{ authenticated: false, error: true, isLoading: false }
            }
        default:
            return state
    }
}

