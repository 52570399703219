import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import {Checkbox} from 'primereact/checkbox';
import * as Yup from "yup";
import { DISCOUNT_MIN_LENGTH} from '../../utils/globals';
import moment from 'moment';
import { Button } from 'primereact/button';
import { DISCOUNT_TYPE } from './constants';


export const discountEditSchema =  Yup.object().shape({
    code: Yup.string()
        .min(DISCOUNT_MIN_LENGTH, 'Koodi on liian lyhyt')
        .max(12, 'Koodi saa sisältää enintään 12 merkkiä')
        .required("Koodi on pakollinen tieto"),
    type: Yup.mixed<DISCOUNT_TYPE>()
        .oneOf(Object.values(DISCOUNT_TYPE)),
//    limit: Yup.number(),
    from: Yup.date()
        .required('Alkamisaika on pakollinen tieto'),
    to: Yup.date()
        .required('Päättymisaika on pakollinen tieto'),
    active: Yup.bool(),
    discount: Yup.number()
        .required('Alennuksen määrä on pakollinen')
})
export const discountCreateSchema = discountEditSchema;

const DiscountTypes = [
    {name: 'Prosenttia', value: DISCOUNT_TYPE.PERCENT},
    {name: 'Kiinteä', value: DISCOUNT_TYPE.FIXED},
]

const DiscountForm: React.FC<{}> = (props: any) => {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      isLoading,
      setFieldValue
    } = props;

    const Field = (type: string, field: string, label: string) =>  <div className="p-field p-grid">
        <label htmlFor={field} className="p-col-fixed label" >{label}</label>
        <div className="p-col-12">
            <InputText
                type={type}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[field]}
                name={field}
                id={field}
            />
            {errors[field] && touched[field] && <Message className="form-field-error" severity="error" text={errors[field]} />}
        </div> 
    </div>
    
    return (
      <form onSubmit={handleSubmit}>
        {Field("text", "code", "Koodi")}
        
        <div className="p-field p-grid">
            <label htmlFor="type" className="p-col-fixed label" >Tyyppi</label>
            <div className="p-col-12">
                <Dropdown
                    placeholder="Valitse alennuksen tyyppi"
                    optionLabel="name"
                    optionValue="value"
                    options={DiscountTypes}
                    onChange={handleChange}
                    //onBlur={handleBlur}
                    value={values.type}
                    name="type"
                    id="type"
                />
                {errors.type && touched.type && <Message className="form-field-error" severity="error" text={errors.type} />}
            </div> 
        </div>
        {Field("number", "discount", "Alennus")}
        {Field("number", "limit", "Käyttökertoja")}
        <div className="p-field p-grid">
            <label htmlFor="from" className="p-col-fixed label" >Alkaen (päivän alusta)</label>
            <div className="p-col-12">
                <Calendar 
                    dateFormat="dd.mm.yy"
                    value={values.from ? moment(values.from).toDate() : undefined}
                    // @ts-ignore
                    onChange={(e) => setFieldValue('from',moment(e.value.toString()).toDate())}
                    //locale={CALENDAR_FI}
                    id="from"
                />
                {errors.from && touched.from && <Message className="form-field-error" severity="error" text={errors.from} />}
            </div> 
        </div>
        <div className="p-field p-grid">
            <label htmlFor="to" className="p-col-fixed label" >Päättyy (valitun päivän alussa)</label>
            <div className="p-col-12">
                <Calendar 
                    dateFormat="dd.mm.yy"
                    value={values.to ? moment(values.to).toDate() : undefined}
                    // @ts-ignore
                    onChange={(e) => setFieldValue('to',moment(e.value.toString()).toDate())}
                    //locale={CALENDAR_FI}
                    id="to"
                />
                {errors.to && touched.to && <Message className="form-field-error" severity="error" text={errors.to} />}
            </div> 
        </div>

        <div className="p-field p-grid">
            <div className="p-col-12">
                <Checkbox 
                    checked={values.active}
                    onChange={(e) => setFieldValue('active',!values.active)}
                    inputId="active"
                />
                <label htmlFor="active" className="p-col-fixed label" >Aktiivinen</label>
                {errors.active && touched.active && <Message className="form-field-error" severity="error" text={errors.active} />}
            </div> 
        </div>
        
        <Button disabled={isLoading} type="submit">Tallenna</Button>
       </form>
    )
}

export default DiscountForm;