import React, { useEffect} from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch} from 'react-redux';
import queryString from 'query-string'

import { Button } from 'primereact/button'
import { loadPaymentOrderAction } from '../../actions/order';

import Header from '../../layout/Header'
import Content from '../../layout/Content';
import pesulasta from '../../static/img/pesulasta.svg';
import { Trans, useTranslation } from 'react-i18next';

/*
https://localhost:3000/payment/cancel?
checkout-account=375917&
checkout-algorithm=sha256&
checkout-amount=1390&
checkout-stamp=d2568f2a-e4c6-40ba-a7cd-1587046477669&
checkout-reference=9187445&
checkout-transaction-id=9b015970-7fec-11ea-93dc-cbeefab7a5af&
checkout-status=fail&
checkout-provider=osuuspankki&
signature=e4181d4c6b7052bd86aff5dc0765fe8f395d41cc271a16b3e06a8ba06f44b447
https://localhost:3000/payment/cancel?checkout-account=375917&checkout-algorithm=sha256&checkout-amount=1390&checkout-stamp=d2568f2a-e4c6-40ba-a7cd-1590395247033&checkout-reference=678c2893-b041-4f12-bc5b-63b3ce0d4174&checkout-transaction-id=91c4140e-9e61-11ea-aeab-d721a93e62a2&checkout-status=fail&checkout-provider=osuuspankki&signature=d9a792a30770b88096ced31ca107bd2ec1e66a7cc9e8747114fd6b079ea4edd0
*/


const CancelPayment: React.FC<{}> = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();


    const { 'checkout-reference': order_id } = queryString.parse(useLocation().search);

    //const isUUID = uuid => uuid.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$");

    // useEffect(() => ReactGA.pageview(getPageUrl(false)),[])
    
    useEffect(() => {
      if (order_id && typeof(order_id) === 'string') {
            dispatch(loadPaymentOrderAction(order_id))
        } else {
            history.push('/')
        }
    }, [dispatch, history, order_id])
    

    return (
        <div className="page-container cancel-payment">
            <Header/>
            <Content >
                <div className="success-payment-page p-grid p-justify-center">
                    <div className="page-head p-col-12">
                        <div className="box">
                            <div className="page-title">{t('error_payment')}</div>
                        </div>
                    </div>
                    
                    <div className="p-col-12">
                        <div className="page-content">
                            <img src={pesulasta} alt="lasta" />
                            <p className="not-found-message">
                                <Trans i18nKey='cancel_payment' />
                            </p>
                            <Button 
                                onClick={() => history.push('/payment/create')}
                                type="button" 
                                label={t('payment.try_again')}>
                            </Button>
                            <br/>
                            <br/>
                            <Button 
                                onClick={() => history.push('/')}
                                type="button" 
                                label={t('success.return')}> 
                            </Button>
                        </div>
                    </div>
                </div>
            </Content>
        </div>
    );
}

export default CancelPayment;