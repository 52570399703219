import { combineReducers } from 'redux'

import { CounterReducer } from './counter'
import {authReducer} from './auth'
import { userReducer } from './user';
import {PaymentReducer} from './payment'
import {OrderReducer} from './order'
import {DiscountReducer} from './discount'
import {NotificationReducer} from './notification'
import { CalendarReducer } from './calendar';
import { settingsReducer } from './settings'

const rootReducer = combineReducers({
    counter: CounterReducer,
    auth: authReducer,
    user: userReducer,
    payment: PaymentReducer,
    order: OrderReducer,
    discount: DiscountReducer,
    notification: NotificationReducer,
    calendar: CalendarReducer,
    settings: settingsReducer
})
export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>
