import { DELETE, GET, POST } from '../utils/api';
import {
    POST_CREATE_ORDER,
    POST_CREATE_MALL_ORDER,
    GET_ORDER,
    CHANGE_WINDOW_AMOUNT,
    INIT_ORDER_WINDOWS,
    CHANGE_BALCONY_AMOUNT,
    CHANGE_TERRACE_AMOUNT,
    SET_SHIPPING_DAY,
    SET_ADDRESS_TO_ORDER,
    GET_ORDERS,
    DELETE_ORDER,
    UPDATE_WINDOWS_PRICES,
    GET_PAYMENT_ORDER,
    UPDATE_ADDITIONAL_INFO,
    CHANGE_HIGH_WINDOWS,
    CHANGE_MALL_CLEANING_SET,
    CHANGE_MALL_STARTING_MONTH,
    MALL_SELECTED
} from '../pages/order/constants';
import { IOrderAddress, Iorder, MALL_CLEANING_SET, MALL } from '../reducers/order';
import { GET_VALIDATE_POSTALCODE } from '../admin/calendar/constants';
import { IReduxAction } from './types';

export interface IOrderWindow {
    windowId: number,
    name: string,
    amount: number,
    price: number,
    discount_price: number,
    easoft_item_id: string
}

/*
 * action creators
 */


export const initOrder = (orderPayload: Iorder) => ({
    type: INIT_ORDER_WINDOWS,
    payload: () => orderPayload
})

export const postCreateOrder = (body: object) => ({
    type: POST_CREATE_ORDER,
    payload: POST('order', body)
})

export const postCreateMallOrder = (body: object) => ({
    type: POST_CREATE_MALL_ORDER,
    payload: POST('mallorder', body)
})

export const loadOrdersAction = () => ({
    type: GET_ORDERS,
    payload: GET(`order`)
})

export const loadOrderAction = (order_id: string) => ({
    type: GET_ORDER,
    payload: GET(`order/${order_id}`)
})

export const loadPaymentOrderAction = (order_id: string) => ({
    type: GET_PAYMENT_ORDER,
    payload: GET(`order/payment/${order_id}`)
})

export const deleteOrderAction = (order_id: string) => ({
    type: DELETE_ORDER,
    payload: DELETE(`order/${order_id}`)
})

export const changeAmountAction = (id: number, newAmount: number) => ({
    type: CHANGE_WINDOW_AMOUNT,
    payload: () => ({
        id: id, 
        newAmount: newAmount
    })
})

export const mallSelectedAction = (payload: MALL) => ({
    type: MALL_SELECTED,
    payload: () => payload
})
export const changeCleaningSetAction = (payload: MALL_CLEANING_SET) => ({
    type: CHANGE_MALL_CLEANING_SET,
    payload: () => payload
})
export const changeStartingMonthAction = (payload: number) => ({
    type: CHANGE_MALL_STARTING_MONTH,
    payload: () => payload
})

export const changeBalconyAmountAction = (payload: Object) => ({
    type: CHANGE_BALCONY_AMOUNT,
    payload: () => payload
})

export const changeTerraceAmountAction = (terraceGlassAmount: number, terraceRoof: boolean) => ({
    type: CHANGE_TERRACE_AMOUNT,
    payload: () => ({
        terraceGlassAmount: terraceGlassAmount, 
        terraceRoof: terraceRoof
    })
})

export const updateHighWindows = (payload: Object) => ({
    type: CHANGE_HIGH_WINDOWS,
    payload: () => payload
})


export const setShippingDay = (shippingDay: string|undefined, cleanerId:number|boolean = false) => ({
    type: SET_SHIPPING_DAY,
    payload: () => ({
        shippingDay: shippingDay,
        cleanerId: cleanerId
    })
})

export const setAddressToOrder = (address: IOrderAddress) => ({
    type: SET_ADDRESS_TO_ORDER,
    payload: () => ({
        address: address
    })  
})

export const updateWindowsPrices = (windows: Array<IOrderWindow>) => ({
    type: UPDATE_WINDOWS_PRICES,
    payload: () => ({
        windows: windows
    })  
})

export const updateAdditionalInfo = (info: string) => ({
    type: UPDATE_ADDITIONAL_INFO,
    payload: () => ({
        additionalInfo: info
    })  
})

export const validatePostalcode = (postalCode: string): IReduxAction => ({
    type: GET_VALIDATE_POSTALCODE,
    payload: GET(`calendar/validatePostalcode/${postalCode}`)
})

export const setPostalCode = (postalCode: string) => ({
    type: `${GET_VALIDATE_POSTALCODE}_FULFILLED`,
    payload: () => ({
        validCode: postalCode
    })  
})