import {
    GET_DISCOUNTS,
    GET_DISCOUNT,
    POST_CREATE_DISCOUNT,
    PUT_UPDATE_DISCOUNT,
    DELETE_DISCOUNT,
    VALIDATE_DISCOUNT_CODE,
    SET_DISCOUNT_CODE,
    DISCOUNT_TYPE,
    DISCOUNT_CODE_STATES
} from '../admin/discount/constants';
import { DISCOUNT_MIN_LENGTH } from '../utils/globals';


export interface Idiscount {
    id: number,
    code: string,
    type: DISCOUNT_TYPE,
    discount: number,
    limit: number,
    applied: number,
    from: Date,
    to: Date,
    active: boolean,
    created?: Date,
    modified?: Date,
    user_id?: number
}

export interface IdiscountInitialState {
    discounts: Array<Idiscount>, 
    discount: Partial<Idiscount>,
    discountUpdated: boolean,
    isLoading: boolean,
    error: boolean,
    deleted: boolean,
    updated: boolean,
    created: boolean,
    discountCode: string,
    valid: DISCOUNT_CODE_STATES
}


export const initialState: IdiscountInitialState = {
    discounts: [],
    discount: {},
    discountUpdated: false,
    isLoading: false,
    error: false,
    deleted: false,
    updated: false,
    created: false,
    discountCode: '',
    valid: DISCOUNT_CODE_STATES.NO_CODE
}


export function DiscountReducer(state:IdiscountInitialState = initialState, action: {type: string, payload: any}) {

    switch (action.type) {
        case `${GET_DISCOUNT}_PENDING`:
        case `${POST_CREATE_DISCOUNT}_PENDING`:
        case `${PUT_UPDATE_DISCOUNT}_PENDING`:
        case `${DELETE_DISCOUNT}_PENDING`:
            return {
                ...state,
                error: false,
                isLoading: true,
                updated: false,
                deleted: false,
                created: false,
            }
        case `${GET_DISCOUNTS}_PENDING`:
            return {
                ...state,
                discount: {},
                error: false,
                isLoading: true,
                updated: false,
                deleted: false,
                created: false,
            }
        case `${GET_DISCOUNTS}_REJECTED`:
        case `${GET_DISCOUNT}_REJECTED`:
        case `${POST_CREATE_DISCOUNT}_REJECTED`:
        case `${PUT_UPDATE_DISCOUNT}_REJECTED`:
        case `${DELETE_DISCOUNT}_REJECTED`:
            return {
                ...state,
                error: true,
                isLoading: false,
            }
        case `${GET_DISCOUNTS}_FULFILLED`:
            return {
                ...state,
                discounts: action.payload,
                error: false,
                isLoading: false
            }
        case `${GET_DISCOUNT}_FULFILLED`:
            return {
                ...state,
                discount: action.payload,
                error: false,
                isLoading: false
            }
        case `${PUT_UPDATE_DISCOUNT}_FULFILLED`:
            return {
                ...state,
                discount: action.payload,
                error: false,
                isLoading: false,
                updated: true
            }
        case `${POST_CREATE_DISCOUNT}_FULFILLED`:
            return {
                ...state,
                discount: action.payload,
                error: false,
                isLoading: false,
                created: true
            }
        case `${DELETE_DISCOUNT}_FULFILLED`:
            return {
                ...state,
                discounts: state.discounts.filter((discount: any, i) => Number(discount.id) !== Number(action.payload.id) ),
                error: false,
                isLoading: false,
                deleted: true
            }

        //Front
        case `${SET_DISCOUNT_CODE}`:
            return {
                ...state,
                discountCode: action.payload,
                discount: action.payload.length > DISCOUNT_MIN_LENGTH ? state.discount : {}
            }
        case `${VALIDATE_DISCOUNT_CODE}_PENDING`:
            return {
                ...state,
                isLoading: true,
                error: false,
            }
        case `${VALIDATE_DISCOUNT_CODE}_REJECTED`:
            return {
                ...state,
                isLoading: false,
                discount: {},
                error: true,
                valid: DISCOUNT_CODE_STATES.INVALID
            }
        case `${VALIDATE_DISCOUNT_CODE}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                error: false,
                discount: action.payload,
                valid: action.payload ? 
                    DISCOUNT_CODE_STATES.VALID : 
                    DISCOUNT_CODE_STATES.INVALID 
            }
        default:
            return state
    }
}

