import React, { useState } from 'react'
import from3to6Mopen from '../../static/img/ikkuna_korkealla_avattava.jpg'
import from3to6Msolid from '../../static/img/ikkuna_korkealla_kiintea.jpg'
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { updateHighWindows } from '../../actions/order';
import { Dialog } from 'primereact/dialog';
import { COMMON_TEL2 } from '../../utils/globals';
import { useTranslation } from 'react-i18next';

// @ts-ignore
enum HIGH_VALUE_TYPES {
    HIGH = "HIGH",
    HIGH_OPENING = "HIGH_OPENING",
    HIGH_ACCESSIBILITY = "HIGH_ACCESSIBILITY"
}

const HighWindows = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { highGlassAmount, highOpeningAmount } = useSelector((state: RootState) => state.order.order)

    const [cantCalculateModal, setCantCalculateModal] = useState<boolean>(false)
    const [showOverSixMDialog, setShowOverSixMDialog] = useState<boolean>(false)

    const amountStarClass = highGlassAmount !== 0 ? 'high-window-amount-wrapper' : '';

    const handleValues = (type: string, newValue: number | boolean) => {
        const payload = {
            highGlassAmount: highGlassAmount,
            highOpeningAmount: highOpeningAmount
        }

        switch (type) {
            case HIGH_VALUE_TYPES.HIGH:
                payload.highGlassAmount = newValue;
                break;
            case HIGH_VALUE_TYPES.HIGH_OPENING:
                if (highOpeningAmount === 0 && (newValue as number) > 0) {
                    setCantCalculateModal(true);
                }
                payload.highOpeningAmount = newValue;
                break;
            default: break;
        }

        dispatch(updateHighWindows(payload))
    }

    const dialogHeader = () => cantCalculateModal ? t('tabs.high_dialog_title2') : showOverSixMDialog ? t('tabs.high_dialog_title') : '';

    const dialogContent = () => {
        const link = <a className="telephone link" href="//wa.me/358442771284" target="_blank" rel="noreferrer">{t('tabs.from_link')} </a>
        const telLink = <a className="telephone link" href={'tel:' + COMMON_TEL2}> {COMMON_TEL2}</a>
        return cantCalculateModal ?
            <>
                <div style={{ textAlign: 'left' }}>
                    {t('tabs.high_dialog_part1')}
                    <ul>
                        <li>{t('tabs.high_dialog_example1')}</li>
                        <li>{t('tabs.high_dialog_example2')}</li>
                        <li>{t('tabs.high_dialog_example3')}</li>
                    </ul>
                    <span>{t('tabs.high_dialog_part2')} {telLink}{', '}{t('tabs.high_dialog_part3')}</span>
                    <br />
                    <span>{t('tabs.high_dialog_part4')}</span>
                    <p>{t('tabs.high_dialog_part5')} {link}</p>
                </div>
            </> : showOverSixMDialog ?
                <>
                    <p>{t('tabs.high_dialog2_part1')}</p>
                    <p>{t('tabs.high_dialog2_part2')}</p>
                    <p>{t('tabs.high_dialog2_part3')} {link}</p>
                    <p>{t('tabs.high_dialog2_part4')}</p>
                </> : 
                <></>
    }
    const dialogFooter = () => {
        return (
            <div>
                <Button label={t('ok')} onClick={() => cantCalculateModal ? setCantCalculateModal(false) : setShowOverSixMDialog(false)} autoFocus />
            </div>
        );
    }

    return <>
        <Dialog
            header={dialogHeader}
            visible={cantCalculateModal || showOverSixMDialog}
            footer={dialogFooter}
            onHide={() => cantCalculateModal ? setCantCalculateModal(false) : setShowOverSixMDialog(false)}
        >
            {dialogContent()}
        </Dialog>

        <div className="accordion-tab-col p-col" >
            <div className="title window high-window">{t('tabs.unopening_high_window')}</div>
            <span className="window-over-six-meters" onClick={() => setShowOverSixMDialog(true)}>
                <span className="pi pi-info-circle"></span>
                {' ' + t('tabs.unopening_very_high')}
            </span>
            <div className="image">
                <img src={from3to6Msolid} alt="tikapuut" />
            </div>
            <div className={`ac-tab-content-action-wrapper ${amountStarClass}`}>
                <Button
                    onClick={() => highGlassAmount > 0 ?
                        handleValues(HIGH_VALUE_TYPES.HIGH, highGlassAmount - 1) : false}
                    className="minus-btn btn"
                    type="button"
                >
                    <i className="pi pi-minus" ></i>
                </Button>
                <input
                    className="amount high-window-amount"
                    // type needs to be text(default) to let setSelection work. Will there be some downsides for this change??
                    maxLength={4}
                    onClick={(event: any) => {
                        event.target.setSelectionRange(0, event.target.value.length)
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = parseInt(e.target.value)
                        handleValues(HIGH_VALUE_TYPES.HIGH, (!isNaN(value) || value > 0) ? value : 0);
                    }}
                    // type="number"
                    value={highGlassAmount}
                >
                </input>
                <Button
                    onClick={() => handleValues(HIGH_VALUE_TYPES.HIGH, highGlassAmount + 1)}
                    className={`plus-btn btn`}
                >
                    <i className="pi pi-plus" ></i>
                </Button>
            </div>
        </div>

        <div className={`accordion-tab-col p-col`} >
            <div className="title window high-window">{t('tabs.opening_high_window')}</div>
            <span className="window-over-six-meters" onClick={() => setShowOverSixMDialog(true)}>
                <span className="pi pi-info-circle"></span>
                {' ' + t('tabs.opening_very_high')}
            </span>
            <div className="image">
                <img src={from3to6Mopen} alt="tikapuut" />
            </div>
            <div className={`ac-tab-content-action-wrapper`}>
                <Button
                    onClick={() => highOpeningAmount > 0 ?
                        handleValues(HIGH_VALUE_TYPES.HIGH_OPENING, (highOpeningAmount - 1)) : false}
                    className="minus-btn btn"
                >
                    <i className="pi pi-minus" ></i>
                </Button>
                <input
                    className="amount "
                    // type needs to be text(default) to let setSelection work. Will there be some downsides for this change??
                    maxLength={4}
                    onClick={(event: any) => {
                        event.target.setSelectionRange(0, event.target.value.length)
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = parseInt(e.target.value)
                        handleValues(HIGH_VALUE_TYPES.HIGH_OPENING, (!isNaN(value) || value > 0) ? value : 0);
                    }}
                    // type="number"
                    value={highOpeningAmount}
                />
                <Button
                    onClick={() => handleValues(HIGH_VALUE_TYPES.HIGH_OPENING, highOpeningAmount + 1)}
                    className="plus-btn btn"
                >
                    <i className="pi pi-plus" ></i>
                </Button>
            </div>
        </div>
        {/* { highOpeningAmount !== 0 && 
            <div className={`accordion-tab-col p-col`} >
                <div className="title window high-window">Tarviiko avattu ikkuna tukea?</div>
                <div className="ac-tab-content-action-wrapper select">
                    <Button
                        onClick={() => {console.log('clicked');}}
                        // className={`selectButton ${balconyRailings && 'checked'}`}
                        label="Kyllä"
                        // {...showTrueSuccessIcon}
                    ></Button>
                    <Button
                        onClick={() => console.log('clicked') }
                        // className={`selectButton ${!balconyRailings && 'checked'}`}
                        label="Ei"
                        // {...showFalseSuccessIcon}
                    ></Button>
                </div>

                <div className="image">
                    <img src={from3to6Mopen} alt="tikapuut" />
                </div>
            </div>
        } */}
    </>
}

export default HighWindows;