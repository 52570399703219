import React from 'react';

const SlotEffect = (oldTotal:number | string, total:number | string, discountClassName: boolean = false ) => {
    const oldValue = oldTotal.toString().split('');
    const coolEffect =  total.toString().split('').map((numStr: string, i:number) => {
        
        const duration = 0.5 + i * 0.25;
        const startValue = numStr === (oldValue[i] || 0) ? 0 : oldValue[i];

        const style = { 
            "--data-start": `-${startValue}em`, 
            "--data-end": `-${numStr}em`,
            "--data-duration": `${duration}s`
        } as React.CSSProperties;
            
            return <ul key={numStr + "" + i} style={style} className={`digits luckie `} > 
                {Array.from(Array(10).keys()).map((num:number) =><li key={`slot${num}${i}`}>{num}</li>)} 
             </ul>
    })
    return <div id="counter" className={`animated `}>{coolEffect} <span>€</span></div>
}

export default SlotEffect;