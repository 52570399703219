import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postCreatePayment, updatePaymentProvider } from '../../actions/payment';
import { ProgressSpinner } from 'primereact/progressspinner';
import useDynamicRefs from 'use-dynamic-refs';
import { RootState } from '../../reducers/rootReducer';
import Content from '../../layout/Content';
import Header from '../../layout/Header';
import { useHistory } from 'react-router-dom';
import Notification from '../../components/Notification/Notification';
import { Helmet } from 'react-helmet';
import { COMMON_TEL } from '../../utils/globals';
import { useTranslation } from 'react-i18next';

const NUMBER_PROVIDERS_VISIBLE = 30

const CreatePayment = () => {

    const {payment, providerUpdated, isLoading, error } = useSelector((state: RootState) => state.payment);
    const {created } = useSelector((state: RootState) => state.order);
    
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const history = useHistory();

    const [notDefaultVisibleClassName, setNotDefaultClassName] = useState('hide');
    const [showBtnClassName, setshowBtnClassName] = useState('');
    const [selectedForm, setSelectedForm] = useState('');
    const [getRef, setRef] =  useDynamicRefs();

    // useEffect(() => ReactGA.pageview(getPageUrl()),[])

    useEffect(() => {
        if(created){

            const domain = /(https?:\/\/.*?)\//.exec(window.location.href)?.[1];
            const createPaymentPayload = {
                "customer_email": created.customer_id.email,
                "order_id": created.id,
                "successRedirectUrl": domain + '/payment/success',
                "cancelRedirectUrl": domain + '/payment/cancel',
            }
            
            dispatch(postCreatePayment(createPaymentPayload))
        }else {
            history.push('/')
        }
    }, [dispatch, created, history])


    useEffect(()=> {
        if(providerUpdated && selectedForm){
            const form = getRef(selectedForm)
            // @ts-ignore
            form.current.submit();
        }
    },[getRef, providerUpdated, selectedForm])

    const showAll = () => {
        setNotDefaultClassName('')
        setshowBtnClassName('hide')
    }

    const parameterToInput = (param: any) => <input type='hidden' name={param.name} value={param.value} key={Math.floor(Math.random() * 100000)} />;

    const getProviderClassName = (alreadyVisible: number) => alreadyVisible > NUMBER_PROVIDERS_VISIBLE ? notDefaultVisibleClassName : ''

    const handleSubmit = (
        e: React.FormEvent<HTMLFormElement>, 
        providerName: React.SetStateAction<string>) => {
            e.preventDefault();
            setSelectedForm(providerName);
            dispatch(updatePaymentProvider({
                order_id: created.id,
                provider_name: providerName
            }));
    }

    const responseToHtml = (response: any) =>
        response.providers
            .reduce((acc: any , provider: any) => {
                acc.push(
                    <form
                        className={`provider-form ${getProviderClassName(acc.length)}`}
                        onSubmit={(e) => handleSubmit(e, provider.name)}
                        method='POST' 
                        action={provider.url} 
                        key={provider.id + provider.name}
                        // @ts-ignore
                        ref={setRef(provider.name)}>
                        {provider.parameters.map(parameterToInput)}
                        <button>
                            <img src={provider.svg} alt={provider.name} />
                        </button>
                    </form>)
                if (acc.length === NUMBER_PROVIDERS_VISIBLE && response.providers.length > NUMBER_PROVIDERS_VISIBLE)
                    acc.push(<div key={'show-all'} className={`p-col-12 ${showBtnClassName}`} >
                        <button className={`p-field p-col-12 p-md-2 show-all `} onClick={() => showAll()}>{t('payment.show_all')}</button>
                    </div>)
                return acc
            }, []);

    return <>
        <Helmet>
            <title>Valitse maksutapa - Ikkunanpesun hintalaskuri</title>
            <meta name="description" content="Laske ikkunanpesun hinta helposti parissa minuutissa ja tilaa pesu" />
            <meta name="keywords" content="Ikkunanpesun hinta, Ikkunanpesun hintalaskuri, Kirkaslasi, Ikkunanpesu, Ikkunanpesu netistä" />
        </Helmet>
        <Header/>
            <Content>
                <>
                <Notification />
                <div className="create-payment-wrapper p-grid p-justify-start">
                    <div className="page-head p-col-12">
                        <div className="box">
                            <div className="page-title">{t('payment.select_payment')}</div>
                        </div>
                    </div>
                    {isLoading &&
                        <div className="isLoading-spinner">
                            <ProgressSpinner/>
                        </div>
                    }
                    {(payment && !isLoading) &&
                        <div className="p-col-12">
                            <div className="payment-wrapper">
                                <div className="provider-forms p-grid p-justify-center">
                                    {responseToHtml(payment)}
                                </div>
                                <div className="payment-terms">
                                    <div dangerouslySetInnerHTML={{ __html: payment.terms + ` ${t('payment.terms_start')} <a href="https://kirkaslasi.fi/tietosuojaseloste/" target="_blank">${t('payment.terms_end')}</a>.` }} />
                                </div>
                            </div>
                        </div>
                    }
                    {error &&
                        <div className="p-grid p-justify-center error-message">
                            <span>{t('payment.start_failed')}</span>
                            <p>{t('payment.start_failed2')} {COMMON_TEL}</p>
                        </div>
                    }
                </div>
                </>
            </Content>
    </>
}

export default CreatePayment;