import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { withFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import Layout from '../components/Layout';
import Title from '../components/Title';
import NotificationForm, { notificationCreateSchema } from './NotificationForm';
import { Messages } from 'primereact/messages';
import { NOTIFICATION_TYPE } from './NotificationList';
import { createNotificationAction } from '../../actions/notification';


//TODO: How to prevent form reset when discount creation failed????
const NotificationCreate: React.FC<{}> = () => {
    
    const dispatch = useDispatch()
    //const {id: admin_user_id } = useSelector((store: RootState) => store.auth.user);
    const {notification, isLoading, error, created } = useSelector((store: RootState) => store.notification);
    const [initialValues, setInitialValues] = useState({ 
        type: NOTIFICATION_TYPE.NORMAL, 
        start_showing: '', 
        stop_showing: '',
        message: '',
    }); 
    const messagesRef:MutableRefObject<any> = useRef(); 
    
    useEffect(() => {
        if(created && notification){
            pushMessage('success', `Notification #${notification.id} created successfully`)
            // set initial values again to refresh form.
            setInitialValues({
                type: NOTIFICATION_TYPE.NORMAL, 
                start_showing: '', 
                stop_showing: '',
                message: ''
            })
        }
    },[notification, created])
    
    useEffect(() => {
        if(error){
            pushMessage('error', `Notification creation failed. Try again! Sorry I cleared all fields :(`)
        }
    },[error])

    const pushMessage = (severity: string, msg: string) => {
        messagesRef.current.show({
            severity: severity,
            detail: msg,
            life: 15000
        });
    }

    const DiscountEnhancedForm = withFormik({
        //enableReinitialize: true,
        mapPropsToValues: () => initialValues,
        validationSchema: notificationCreateSchema,
        handleSubmit: (values, { setSubmitting }) => {
            dispatch(createNotificationAction(values))
            setSubmitting(isLoading)
        },
        displayName: 'NotificationCreateForm',
    })(NotificationForm);

    return (
        <Layout>
            <Messages ref={messagesRef} />
            <Title type="h1">Create New Notification</Title>
            <DiscountEnhancedForm />
            {isLoading && 
                <div>Creating...</div>
            }
        </Layout>
    )
}

export default NotificationCreate;
