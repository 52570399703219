import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ErrorBoundary from '../middleware/ErrorBoundary'

import Dashboard from '../admin/Dashboard'
import Login from '../admin/auth/Login'
import Kirkaslaskuri from '../pages/Kirkaslaskuri'
import Notfound from '../pages/Notfound'
//import PrimeReact from 'primereact/utils'
import PrimeReact from 'primereact/api';
import ProtectedRoute from './ProtectedRoute'
import UserCreate from '../admin/user/UserCreate'
import UserEdit from '../admin/user/UserEdit'
import UserList from '../admin/user/UserList'

import CounterConfigCreate from '../admin/counter/CounterConfigCreate'
import CounterConfigEdit from '../admin/counter/CounterConfigEdit'
import CounterConfigList from '../admin/counter/CounterConfigList'

import DiscountList from '../admin/discount/DiscountList';
import DiscountEdit from '../admin/discount/DiscountEdit';
import DiscountCreate from '../admin/discount/DiscountCreate';

import CreatePayment from '../pages/payment/CreatePayment';
import SuccessPayment from '../pages/payment/SuccessPayment';
import CancelPayment from '../pages/payment/CancelPayment';
import CalendarPage from '../pages/CalendarPage';
import AddressPage from '../pages/AddressPage';
import OrderSuccessPage from '../pages/OrderSuccessPage';
import CheckFreeTimes from '../pages/CheckFreeTimes';

// Primereact styles
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-orange/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import OrderList from '../admin/order/OrderList';
import OrderEdit from '../admin/order/OrderEdit';
import NotificationList from '../admin/notification/NotificationList';
import NotificationCreate from '../admin/notification/NotificationCreate';
import NotificationEdit from '../admin/notification/NotificationEdit';
import AdminCalendar from '../admin/calendar/AdminCalendar';
import SettingsPage from '../admin/settings/SettingsPage';
import ShoppingMall from '../pages/ShoppingMall';

// Enable ripple effect
PrimeReact.ripple = true;

function App() {

  return (
    <ErrorBoundary>
      <Router>
        <Switch>
            <Route exact path="/" component={Kirkaslaskuri} />
            <Route exact path="/ostari" component={ShoppingMall} />
            <Route exact path="/ostari/yhteystiedot" component={AddressPage} />
            <Route exact path="/tilauskalenteri" component={CalendarPage} />
            <Route exact path="/vapaat-pesuajat" component={CheckFreeTimes} />
            <Route exact path="/yhteystiedot" component={AddressPage} />
            <Route exact path="/payment/create" component={CreatePayment} />
            <Route exact path="/payment/success" component={SuccessPayment} />
            <Route exact path="/payment/cancel" component={CancelPayment} />
            <Route exact path="/kiitos-tilauksesta/:reference?" component={OrderSuccessPage} />
            <Route exact path="/admin" component={Login} />
            <ProtectedRoute exact={false} path="/admin/dashboard" component={Dashboard} />
            <ProtectedRoute exact={false} path="/admin/calendar" component={AdminCalendar} />
            <ProtectedRoute exact={false} path="/admin/user/list" component={UserList} />
            <ProtectedRoute exact={false} path="/admin/user/create" component={UserCreate} />
            <ProtectedRoute exact={false} path="/admin/user/edit/:userId" component={UserEdit} />
            <ProtectedRoute exact={false} path="/admin/counter/list" component={CounterConfigList} />
            <ProtectedRoute exact={false} path="/admin/counter/create" component={CounterConfigCreate} />
            <ProtectedRoute exact={false} path="/admin/counter/edit/:counterId" component={CounterConfigEdit} />
            <ProtectedRoute exact={false} path="/admin/discount/list" component={DiscountList} />
            <ProtectedRoute exact={false} path="/admin/discount/create" component={DiscountCreate} />
            <ProtectedRoute exact={false} path="/admin/discount/edit/:discountId" component={DiscountEdit} />
            <ProtectedRoute exact={false} path="/admin/order/list" component={OrderList} />
            <ProtectedRoute exact={false} path="/admin/order/edit/:orderId" component={OrderEdit} />
            <ProtectedRoute exact={false} path="/admin/notification/list" component={NotificationList} />
            <ProtectedRoute exact={false} path="/admin/notification/create" component={NotificationCreate} />
            <ProtectedRoute exact={false} path="/admin/notification/edit/:notificationId" component={NotificationEdit} />
            <ProtectedRoute exact={false} path="/admin/settings/" component={SettingsPage} />
            <Route path="*" component={Notfound} />
        </Switch>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
