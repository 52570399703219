
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { RootState } from '../../reducers/rootReducer'
import Layout from '../components/Layout'
import Title from '../components/Title'
import moment from 'moment';
import { SHOW_TIME_FORMAT_H_M } from '../../utils/globals'
import { Dialog } from 'primereact/dialog'
import { Messages } from 'primereact/messages'
import { deleteNotificationAction, loadNotificationsAction } from '../../actions/notification'

export enum NOTIFICATION_TYPE {
    NORMAL = "NORMAL",
    URGENT = "URGENT",
    INFO = "INFO"
}

const NotificationList: React.FC<{}> = () => {

    const {notifications, deleted} = useSelector((state:RootState) => state.notification)

    const [displayDeleteConfirmation, setDisplayDeleteConfirmation] = useState(false);
    const [deleteNotification, setDeleteNotification] = useState({id:NaN})
    const messagesRef:MutableRefObject<any> = useRef(); 
    const dispatch = useDispatch()
    const history = useHistory()
    
    useEffect(() => {
        dispatch(loadNotificationsAction());
    },[dispatch])

    const createDiscountLinkBtn = () => history.push('create')

    const renderDeleteDialogFooter = () => <div>
            <Button label="No" icon="pi pi-times" onClick={() => setDisplayDeleteConfirmation(false) } className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={() => {
                setDisplayDeleteConfirmation(false); 
                dispatch(deleteNotificationAction(deleteNotification.id));
                }} 
                autoFocus />
        </div>

        useEffect(() => {
            if(deleted){
                pushMessage('success', `Notification deleted successfully!`)
            }
        },[deleted])

        const pushMessage = (severity: string, msg: string) => {
            messagesRef.current.show({
                severity: severity,
                detail: msg,
                life: 15000
            });
        }

    //Custom columns
    const actionColumn = (rowData: any) => <div>
            <Link to={`edit/${rowData.id}`} title="Edit"><i className="pi pi-cog" /></Link>
            <i onClick={()=> { setDeleteNotification(rowData); setDisplayDeleteConfirmation(true) }} className="pi pi-trash" title="Delete"/>
        </div>

    const timeColumn = (datetime: string) => <div>{moment(datetime).format(SHOW_TIME_FORMAT_H_M )}</div>
    const fromColumn = (rowData: any) => timeColumn(rowData.start_showing)
    const toColumn = (rowData: any) => timeColumn(rowData.stop_showing)

    return (
        <Layout>

            <Messages ref={messagesRef} />
            <Dialog 
                modal
                header="Delete Confirmation" 
                visible={displayDeleteConfirmation}  
                style={{ width: '450px' }} 
                footer={renderDeleteDialogFooter()} 
                onHide={() => setDisplayDeleteConfirmation(false)
            }>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span>Are you sure you want to delete notification <span className="delete-email">{deleteNotification.id}</span>?</span>
                </div>
            </Dialog>
            <div className="content-header">
                <Title type="h2">Notifikaatiot</Title>
                <Button className="create-button" onClick={() => createDiscountLinkBtn()}>Luo notifikaatio</Button> 
            </div>
            <div className="discount-list">
                <div className="card">
                    <DataTable value={notifications}>
                        <Column field="id" header="#id"></Column>
                        <Column field="type" header="type"></Column>
                        <Column field="start_showing" header="Alkaa" body={fromColumn}></Column>
                        <Column field="stop_showing" header="Päättyy" body={toColumn}></Column>
                        <Column field="action" header="Actions" body={actionColumn}/>
                    </DataTable>
                </div>
            </div>
        </Layout>
    )
}

export default NotificationList;