import {
    POST_CREATE_PAYMENT,
    POST_SUCCESS_PAYMENT,
    POST_UPDATE_PROVIDER
} from '../pages/payment/constants';

const initialState = {
    payment: false,
    paymentValid: undefined,
    providerUpdated: false,
    isLoading: false,
    error: false,
}

export function PaymentReducer(state = initialState, action: {type: string, payload: any}) {

    switch (action.type) {
        case `${POST_CREATE_PAYMENT}_PENDING`:
        case `${POST_SUCCESS_PAYMENT}_PENDING`:
        case `${POST_UPDATE_PROVIDER}_PENDING`:
            return {
                ...state,
                error: false,
                isLoading: true,
            }

        case `${POST_SUCCESS_PAYMENT}_REJECTED`:
        case `${POST_CREATE_PAYMENT}_REJECTED`:
        case `${POST_UPDATE_PROVIDER}_REJECTED`:
            return {
                ...state,
                error: true,
                isLoading: false,
            }

        // POST_CREATE_PAYMENT
        case `${POST_CREATE_PAYMENT}_FULFILLED`:
            return {
                ...state,
                payment: action.payload,
                error: false,
                isLoading: false
            }

        // POST_SUCCESS_PAYMENT
        case `${POST_SUCCESS_PAYMENT}_FULFILLED`:
            return {
                ...state,
                paymentValid: action.payload,
                error: false,
                isLoading: false
            }

        // POST_UPDATE_PROVIDER
        case `${POST_UPDATE_PROVIDER}_FULFILLED`:
            return {
                ...state,
                providerUpdated: action.payload,
                error: false,
                isLoading: false
            }
        default:
            return state
    }
}

