import React from 'react';
import Layout from './components/Layout'

const Dashboard: React.FC<{}>  = () => {
    
    return (
        <Layout>
            <div>
                Content
            </div>
        </Layout>
    )
}

export default Dashboard;
