import React from 'react';

import Header from '../layout/Header'
import Content from '../layout/Content';
import pesulasta from '../static/img/pesulasta.svg';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Helmet } from 'react-helmet';

const Notfound: React.FC<{}> = () => {

    // useEffect(() => ReactGA.pageview(getPageUrl()),[])
    
    const history = useHistory();
    return (
        <div>
            <Helmet>
                <title>Hupsista - Ikkunanpesun hintalaskuri</title>
                <meta name="description" content="Laske ikkunanpesun hinta helposti parissa minuutissa ja tilaa pesu" />
                <meta name="keywords" content="Ikkunanpesun hinta, Ikkunanpesun hintalaskuri, Kirkaslasi, Ikkunanpesu, Ikkunanpesu netistä" />
            </Helmet>
            <Header/>
            <Content >
                <div className="success-payment-page p-grid p-justify-center">
                    <div className="page-head p-col-12">
                        <div className="box">
                            <div className="page-title">Hups, jotakin sattui!</div>
                        </div>
                    </div>
                    
                    <div className="p-col-12">
                        <div className="page-content">
                            <img src={pesulasta} alt="lasta" />
                            <p className="not-found-message">Sivua ei löytynyt</p>
                            <Button 
                                onClick={()=> history.push('/')}
                                type="button" 
                                label="Palaa etusivulle" >
                            </Button>
                        </div>
                    </div>
                </div>
            </Content>
        </div>
    )
}

export default Notfound;
