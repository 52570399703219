import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { Provider } from 'react-redux';
import configureStore from './store';
//import reportWebVitals from './app/reportWebVitals';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

import './i18n/config'; // Language service

import './static/sass/main.scss'
const store = configureStore();

const { REACT_APP_GA_ID, REACT_APP_PIXEL_ID } = process.env;

if(REACT_APP_GA_ID) {
  ReactGA.initialize(REACT_APP_GA_ID);
}

if(REACT_APP_PIXEL_ID){
  // Initialize facebook pixel
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  
  ReactPixel.init(REACT_APP_PIXEL_ID, undefined, options);
}

ReactDOM.render(
  <Provider store={store} >
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
