import { GET, POST, DELETE } from '../utils/api';

import {
    GET_SETTINGS,
    POST_CREATE_SETTINGS,
    DELETE_SETTINGS,
    GET_SUGGESTIONS,
    GET_POSTAL_AREAS,
    CREATE_POSTAL_AREAS,
    DELETE_POSTAL_AREA,
    GET_CLEANERS,
    CREATE_CLEANERS,
    DELETE_CLEANERS
} from '../admin/settings/constants';
import { IcleanerRow } from '../admin/settings/cleaners/CleanersSettings';

/*
 * Counter action creators
 */

export const loadSettingsAction = () => ({
    type: GET_SETTINGS,
    payload: GET(`settings`)
})

export const createSettingsAction = (body: Array<IcleanerRow>) => ({
    type: POST_CREATE_SETTINGS,
    payload: POST(`settings`, body)
})

export const deleteSettingAction = (settingId: number) => ({
    type: DELETE_SETTINGS,
    payload: DELETE(`settings/${settingId}`)
})

export const getPossibleMaintainers = () => ({
    type: GET_SUGGESTIONS,
    payload: GET(`settings/suggestions`)
})

// Postal areas
export const getPostalAreas = () => ({
    type: GET_POSTAL_AREAS,
    payload: GET(`settings/postalareas`)
})

export const createPostalAreas = (payload:object) => ({
    type: CREATE_POSTAL_AREAS,
    payload: POST(`settings/postalareas`, payload)
})

export const removePostalArea = (id:number) => ({
    type: DELETE_POSTAL_AREA,
    payload: DELETE(`settings/postalarea/${id}`)
})

// Cleaners
export const getCleaners = () => ({
    type: GET_CLEANERS,
    payload: GET(`settings/cleaners`)
})

export const createCleaners = (payload:object) => ({
    type: CREATE_CLEANERS,
    payload: POST(`settings/cleaners`, payload)
})

export const removeCleaner = (id:number) => ({
    type: DELETE_CLEANERS,
    payload: DELETE(`settings/cleaner/${id}`)
})