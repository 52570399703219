import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Layout from '../components/Layout';
import Title from '../components/Title';
import { deleteUserAction, loadUsersAction } from '../../actions/user';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';

const UserList: React.FC<{}> = (/*userId: number*/) => {
    
    const dispatch = useDispatch()
    const history = useHistory()
    const { users, deleted } = useSelector((store: RootState) => store.user)
    const [displayDeleteConfirmation, setDisplayDeleteConfirmation] = useState(false);
    const [deleteUser, setDeleteUser] = useState({id:NaN, email:''})
    const messagesRef:MutableRefObject<any> = useRef(); 
    
    useEffect(() => {
        dispatch(loadUsersAction())
    },[dispatch])

    const actionColumn = (rowData: any) => <div>
            <Link to={`edit/${rowData.id}`} title="Edit"><i className="pi pi-user-edit" /></Link>
            <i onClick={()=> { setDeleteUser(rowData); setDisplayDeleteConfirmation(true) }} className="pi pi-trash" title="Delete"/>
        </div>

    const createUserLinkBtn = () => history.push('create') 

    const renderDeleteDialogFooter = () => <div>
            <Button label="No" icon="pi pi-times" onClick={() => setDisplayDeleteConfirmation(false) } className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={() => {
                setDisplayDeleteConfirmation(false); 
                dispatch(deleteUserAction(deleteUser.id));
                }} 
                autoFocus />
        </div>

        useEffect(() => {
            if(deleted){
                pushMessage('success', `User deleted successfully!`)
            }
        },[deleted])

        const pushMessage = (severity: string, msg: string) => {
            messagesRef.current.show({
                severity: severity,
                detail: msg,
                life: 15000
            });
        }


    return (
        <Layout>
            <Messages ref={messagesRef} />
            <Dialog 
                modal
                header="Delete Confirmation" 
                visible={displayDeleteConfirmation}  
                style={{ width: '450px' }} 
                footer={renderDeleteDialogFooter()} 
                onHide={() => setDisplayDeleteConfirmation(false)
            }>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span>Are you sure you want to delete user <span className="delete-email">{deleteUser.email}</span>?</span>
                </div>
            </Dialog>

            <div className="content-header">
                <Title type="h2">Admin users</Title>
                <Button className="create-button" onClick={() => createUserLinkBtn()}>Create User</Button> 
            </div>
            <div>
                <div className="card">
                    <DataTable value={users}>
                        <Column field="id" header="#id"></Column>
                        <Column field="name" header="Name"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="action" header="Actions" body={actionColumn}/>
                    </DataTable>
                </div>
            </div>
        </Layout>
    )
}

export default UserList;
