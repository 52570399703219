import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { Button } from 'primereact/button';
import terrace_class from '../../static/img/terassi_hitaampi.gif'
import terrace_roof_class from '../../static/img/terassi_katto_hitaampi.gif'
import { changeTerraceAmountAction } from '../../actions/order';
import { ChangeEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
//import icon_ready from '../../static/img/Icon_valmis.svg';

const Terrace = () => {

    const {order, isLoading, error} = useSelector((state:RootState) => state.order);
    const {terraceGlassAmount, terraceRoof} = order
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [showRoofInfoDialog, setShowRoofInfoDialog] = useState<boolean>(false)


    const successIconHtmlAttr = {
        icon:"pi pi-check", 
        iconPos:"right"
    }
    const showTrueSuccessIcon = terraceRoof ? successIconHtmlAttr : {};
    const showFalseSuccessIcon = terraceRoof ? {} : successIconHtmlAttr;

    const dialogContent = () => {
        return <>
                <div style={{ textAlign: 'left' }}>
                    <Trans i18nKey='tabs.terrace_topside_info' />
                </div>
            </>
    }

    const dialogFooter = () => {
        return (
            <div>
                <Button label={t('ok')} onClick={() => setShowRoofInfoDialog(false)} autoFocus />
            </div>
        );
    }

    return <>
        <Dialog
            header={t('tabs.terrace_topside')}
            visible={showRoofInfoDialog}
            footer={dialogFooter}
            onHide={() => setShowRoofInfoDialog(false)}
        >
            {dialogContent()}
        </Dialog>

        {!isLoading &&
        <div className="ac-tab-content-wrapper">
            <div className="p-grid p-justify-even">
                <div className="accordion-tab-col p-col">
                    <div className="title terrace">{t('tabs.terrace_amount')}</div>
                    <div className="image info">
                        <img src={terrace_class} alt="Terassilasit"/>
                    </div>
                    <div className="window-info-text"></div>
                    <div className="ac-tab-content-action-wrapper">
                        <Button 
                            onClick={() => terraceGlassAmount > 0 && dispatch(changeTerraceAmountAction((terraceGlassAmount-1), terraceRoof))}
                            className="minus-btn btn"
                        >
                             <i className="pi pi-minus" ></i>
                        </Button>
                        <input 
                            className="amount" 
                            // type="number" 
                            // type needs to be text(default) to let setSelection work. Will there be some downsides for this change??
                            maxLength={4}
                            onClick={(event: any) => {
                                event.target.setSelectionRange(0, event.target.value.length)
                            }}
                            onChange={(e:ChangeEvent<HTMLInputElement>) => {
                                const value = parseInt(e.target.value)
                                dispatch(changeTerraceAmountAction(
                                    ((!isNaN(value) || value > 0) ? value: 0), terraceRoof)
                                )}}
                            value={order.terraceGlassAmount} 
                        />
                        <Button 
                            onClick={() => dispatch(changeTerraceAmountAction((terraceGlassAmount+1), terraceRoof))}
                            className="plus-btn btn"
                        >
                            <i className="pi pi-plus" ></i>
                        </Button>
                    </div>
                </div>
                <div className="accordion-tab-col p-col">
                    <div className="title terrace">{t('tabs.terrace_topside')}</div>
                    <div className="image info">
                        <img src={terrace_roof_class} alt="Terassin lasikatto"/>
                    </div>
                    <div className="window-info-text">{t('tabs.no_obstacles')}</div>
                    <div className="ac-tab-content-action-wrapper select">
                        <Button 
                            onClick={() => {
                                if (!terraceRoof) {
                                    dispatch(changeTerraceAmountAction(order.terraceGlassAmount, true));
                                    setShowRoofInfoDialog(true);
                                } 
                            }} 
                            className={`selectButton ${terraceRoof && 'checked'}`}
                            label={t('yes')}
                            {...showTrueSuccessIcon}
                        ></Button>
                        <Button 
                            onClick={() => terraceRoof && dispatch(changeTerraceAmountAction(order.terraceGlassAmount, false))}
                            className={`selectButton ${!terraceRoof && 'checked'}`}
                            label={t('no')}
                            {...showFalseSuccessIcon}
                        ></Button>
                   </div>
                </div>    
            </div>
            {/*<div className="p-grid p-align-center p-justify-center marketing-block ">
                <div className="p-col-12 p-sm-12 p-lg-12 image" >
                    <div className="box">
                        <img className="" src={icon_ready} alt="Siivouslasta" />
                    </div>
                </div>
                <div className="p-col-12 p-sm-12  p-lg-12 text" >
                    <div className="box">
                        <span className="text">Valmista tuli, tilaa pesu!</span>    
                    </div>
                </div>
            </div>*/}
            </div>
        }
        {isLoading && <div className="loading-content-block">{t('loading')}</div>}
        {(!isLoading && error) && <div className="loading-content-block">{t('error')}</div>}
        
        </>
};

export default Terrace
