import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { loadActiveNotificationsAction } from '../../actions/notification';
import { Animated } from "react-animated-css";
import { RootState } from '../../reducers/rootReducer';
import { Inotification } from '../../reducers/notification';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const Notification = () => {
    const dispatch = useDispatch()
    const { notifications /*, isLoading, error */ } = useSelector((state: RootState) => state.notification)
    const [hideIds, setHideIds] = useState<any[]>([])
    const [popupContent, setPopupContent] =  useState<string | undefined>('');
    const [showTerms, setShowTerms] = useState<boolean | undefined>(false)

    let notificationStorage:number[] = localStorage.getObj('kirkaslasi_notifications') || [];

    useEffect(() => {
        if(!notifications){
            dispatch(loadActiveNotificationsAction())
        }
    }, [dispatch, notifications]);

    const closeNotification = (id: number) => {
        // close notification and add id to local storage
        localStorage.setObj('kirkaslasi_notifications', [...notificationStorage, id]);
        setHideIds([...hideIds, id]);
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="Kiitos tiedosta" onClick={() => setShowTerms(false)} autoFocus />
            </div>
        );
    }

    const notificationElements = notifications ? notifications.map((notification: Inotification) => {
        if(!notificationStorage.includes(notification.id) && !hideIds.includes(notification.id)) {
            return <Animated
                animationIn="fadeInDown"
                animationOut="fadeOutUp"
                isVisible={true}
                key={notification.id}
            >
                <div id={`notification-${notification.id}`} className={`notification-row ${notification.type}`}>
                    <div>
                        {notification.message} {` `}
                        {notification.extra_info && <>
                            <span onClick={() =>{
                                setPopupContent(notification.extra_info); 
                                setShowTerms(true)}
                            }>Lue lisää</span>
                        </>}
                    </div>
                    <div className="close-button" onClick={()=> closeNotification(notification.id) } ></div>
                </div>
            </Animated>
        }
        return ''
    }): '';

    return <div className="notification-wrapper">
        {notificationElements}
        <Dialog
            header="Lisätietoja" 
            visible={showTerms} 
            footer={renderFooter()} 
            onHide={() => setShowTerms(false)}
            className="term-modal"
        >
            <div className="term-modal-container">
                <div dangerouslySetInnerHTML={{ __html: ''+popupContent}}></div>
            </div>
        </Dialog>
    </div>
    
}

export default Notification;