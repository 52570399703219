import { POST } from '../utils/api';
import {
    POST_CREATE_PAYMENT,
    POST_SUCCESS_PAYMENT,
    POST_UPDATE_PROVIDER,
} from '../pages/payment/constants';


/*
 * action creators
 */

export const postCreatePayment = (body: object) => ({
    type: POST_CREATE_PAYMENT,
    payload: POST('payment', body)
})

export const postSuccessPayment = (body: object) => ({
    type: POST_SUCCESS_PAYMENT,
    payload: POST('payment/success', body)
})


export const updatePaymentProvider = (body: object) => ({
    type: POST_UPDATE_PROVIDER,
    payload: POST('payment/update', body)
})
