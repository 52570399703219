import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { withFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import Layout from '../components/Layout';
import Title from '../components/Title';
import DiscountForm, { discountCreateSchema } from './DiscountForm';
import { Messages } from 'primereact/messages';
import { createDiscountAction } from '../../actions/discount';
import { DISCOUNT_TYPE } from './constants';


//TODO: How to prevent form reset when discount creation failed????
const DiscountCreate: React.FC<{}> = () => {
    
    const dispatch = useDispatch()
    const {id: admin_user_id } = useSelector((store: RootState) => store.auth.user);
    const {discount, isLoading, error, created } = useSelector((store: RootState) => store.discount);
    const [initialValues, setInitialValues] = useState({ 
        code: '', 
        type: DISCOUNT_TYPE.FIXED, 
        discount: '', 
        limit: '',
        active: false
    }); 
    const messagesRef:MutableRefObject<any> = useRef(); 
    
    useEffect(() => {
        if(created && discount){
            pushMessage('success', `Discount #${discount.id} ${discount.code} created successfully`)
            // set initial values again to refresh form.
            setInitialValues({
                code: '', 
                type: DISCOUNT_TYPE.FIXED, 
                discount: '', 
                limit: '',
                active: false
            })
        }
    },[discount, created])
    
    useEffect(() => {
        if(error){
            pushMessage('error', `Discount creation failed. Try again! Sorry I cleared all fields :(`)
        }
    },[error])

    const pushMessage = (severity: string, msg: string) => {
        messagesRef.current.show({
            severity: severity,
            detail: msg,
            life: 15000
        });
    }

    const DiscountEnhancedForm = withFormik({
        //enableReinitialize: true,
        mapPropsToValues: () => initialValues,
        validationSchema: discountCreateSchema,
        handleSubmit: (values, { setSubmitting }) => {
            const payload = {
                ...values,
                user_id: admin_user_id
            }
            dispatch(createDiscountAction(payload))
            setSubmitting(isLoading)
        },
        displayName: 'DiscountCreateForm',
    })(DiscountForm);

    return (
        <Layout>
            <Messages ref={messagesRef} />
            <Title type="h1">Create New Discount</Title>
            <DiscountEnhancedForm />
            {isLoading && 
                <div>Creating...</div>
            }
        </Layout>
    )
}

export default DiscountCreate;
