import React, { MutableRefObject, useEffect, useRef } from 'react';
import { withFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import Layout from '../components/Layout';
import Title from '../components/Title';
import NotificationForm, { notificationCreateSchema } from './NotificationForm';
import { Messages } from 'primereact/messages';
import { loadNotificationAction, updateNotificationAction } from '../../actions/notification';
import { useParams } from 'react-router-dom';


//TODO: How to prevent form reset when notifications creation failed????
const NotificationEdit: React.FC<{}> = () => {
        
    const params: any = useParams();
    const dispatch = useDispatch()
    const {notification, isLoading, updated, error } = useSelector((state: RootState) => state.notification); 
    const messagesRef:MutableRefObject<any> = useRef(); 

    useEffect(() => {
        dispatch(loadNotificationAction(params.notificationId));
    },[dispatch, params.notificationId])

    useEffect(() => {
        if(error){
            pushMessage('error', `Notifications save failed. Try again!`)
        }
        if(updated){
            pushMessage('success', `Notifications updated succesfully.`)
        }
    },[error, updated])
         
    const NotificationsEnhancedForm = withFormik({
        mapPropsToValues: () => (notification),
        validationSchema: notificationCreateSchema,
        handleSubmit: (values, { setSubmitting, setFieldError }) => {
            dispatch(updateNotificationAction(notification.id, values))
            
        },
        displayName: 'NotificationsEditForm',
    })(NotificationForm);
    

    const pushMessage = (severity: string, msg: string) => {
        messagesRef.current.show({
            severity: severity,
            detail: msg,
            life: 15000
        });
    }
    return (
        <Layout>
            <Messages ref={messagesRef} />
            <Title type="h1">Edit Notifications #{params.notificationsId}</Title>
            <NotificationsEnhancedForm />
            {isLoading && 
                <div>Loading..</div>
            }
        </Layout>
    )
}

export default NotificationEdit;
