import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATION,
    POST_CREATE_NOTIFICATION,
    PUT_UPDATE_NOTIFICATION,
    DELETE_NOTIFICATION
} from '../admin/notification/constants';
import { NOTIFICATION_TYPE } from '../admin/notification/NotificationList';


export interface Inotification {
    id: number,
    start_showing: string,
    stop_showing: string,
    created?: Date,
    modified?: Date,
    message: string,
    extra_info?: string,
    type: NOTIFICATION_TYPE
    //user_id?: number
}

export interface InotificationInitialState {
    notifications: Array<Inotification> | undefined, 
    notification: Partial<Inotification>,
    notificationUpdated: boolean,
    isLoading: boolean,
    error: boolean,
    deleted: boolean,
    updated: boolean,
    created: boolean,
}


export const initialState: InotificationInitialState = {
    notifications: undefined,
    notification: {},
    notificationUpdated: false,
    isLoading: false,
    error: false,
    deleted: false,
    updated: false,
    created: false,
}


export function NotificationReducer(state:InotificationInitialState = initialState, action: {type: string, payload: any}) {

    switch (action.type) {
        case `${GET_NOTIFICATION}_PENDING`:
        case `${POST_CREATE_NOTIFICATION}_PENDING`:
        case `${PUT_UPDATE_NOTIFICATION}_PENDING`:
        case `${DELETE_NOTIFICATION}_PENDING`:
            return {
                ...state,
                error: false,
                isLoading: true,
                updated: false,
                deleted: false,
                created: false,
            }
        case `${GET_NOTIFICATIONS}_PENDING`:
            return {
                ...state,
                notification: {},
                error: false,
                isLoading: true,
                updated: false,
                deleted: false,
                created: false,
            }
        case `${GET_NOTIFICATIONS}_REJECTED`:
        case `${GET_NOTIFICATION}_REJECTED`:
        case `${POST_CREATE_NOTIFICATION}_REJECTED`:
        case `${PUT_UPDATE_NOTIFICATION}_REJECTED`:
        case `${DELETE_NOTIFICATION}_REJECTED`:
            return {
                ...state,
                error: true,
                isLoading: false,
            }
        case `${GET_NOTIFICATIONS}_FULFILLED`:
            return {
                ...state,
                notifications: action.payload,
                error: false,
                isLoading: false
            }
        case `${GET_NOTIFICATION}_FULFILLED`:
            return {
                ...state,
                notification: action.payload,
                error: false,
                isLoading: false
            }
        case `${PUT_UPDATE_NOTIFICATION}_FULFILLED`:
            return {
                ...state,
                notification: action.payload,
                error: false,
                isLoading: false,
                updated: true
            }
        case `${POST_CREATE_NOTIFICATION}_FULFILLED`:
            return {
                ...state,
                notification: action.payload,
                error: false,
                isLoading: false,
                created: true
            }
        case `${DELETE_NOTIFICATION}_FULFILLED`:
            return {
                ...state,
                notifications: (
                    state.notifications ? 
                        state.notifications.filter((notification: any, i) => Number(notification.id) !== Number(action.payload.id)) : 
                        state.notifications
                ),
                error: false,
                isLoading: false,
                deleted: true
            }
        default:
            return state
    }
}

