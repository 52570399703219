import React, { MutableRefObject, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadUserAction, updateUserAction } from '../../actions/user';
import Layout from '../components/Layout';
import Title from '../components/Title';
import {withFormik } from 'formik';

import UserForm, { userEditSchema } from './UserForm';
import { RootState } from '../../reducers/rootReducer';
import { Messages } from 'primereact/messages';
  

const UserEdit: React.FC<{}> = () => {
    
    const params: any = useParams();
    const dispatch = useDispatch()
    const {user, isLoading, updated, error } = useSelector((store: RootState) => store.user); 
    const messagesRef:MutableRefObject<any> = useRef(); 

    useEffect(() => {
        dispatch(loadUserAction(params.userId));
    },[dispatch, params.userId])

    useEffect(() => {
        if(error){
            pushMessage('error', `User save failed. Try again!`)
        }
        if(updated){
            pushMessage('success', `User updated succesfully.`)
        }
    },[error, updated])
         
    const UserEnhancedForm = withFormik({
        mapPropsToValues: () => ({ name: user.name, email: user.email, password: '', passwordConfirmation: '' }),
        validationSchema: userEditSchema,
        handleSubmit: (values, { setSubmitting, setFieldError }) => {
            if(values.password === values.passwordConfirmation){
                dispatch(updateUserAction(user.id, values))
                setSubmitting(isLoading)
            }else{
                setFieldError('passwordConfirmation', 'Salasanojen täytyy täsmätä');
            }
        },
        displayName: 'UserEditForm',
    })(UserForm);
    

    const pushMessage = (severity: string, msg: string) => {
        messagesRef.current.show({
            severity: severity,
            detail: msg,
            life: 15000
        });
    }
    return (
        <Layout>
            <Messages ref={messagesRef} />
            <Title type="h1">Edit User #{params.userId}</Title>
            <UserEnhancedForm />
            {isLoading && 
                <div>Loading..</div>
            }
        </Layout>
    )
}

export default UserEdit;
