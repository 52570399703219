import { GET, POST, PUT, DELETE } from '../utils/api';

import {
    GET_USER,
    GET_USERS,
    POST_CREATE_USER,
    PUT_UPDATE_USER,
    DELETE_USER
} from '../admin/user/constants';

/*
 * Counter action creators
 */

export const loadUserAction = (userId: number) => ({
    type: GET_USER,
    payload: GET(`users/${userId}`)
})

export const loadUsersAction = () => ({
    type: GET_USERS,
    payload: GET(`users`)
})

export const createUserAction = (body: object) => ({
    type: POST_CREATE_USER,
    payload: POST(`auth/register`, body)
})

export const updateUserAction = (userId: number, body: object) => ({
    type: PUT_UPDATE_USER,
    payload: PUT(`users/${userId}`, body)
})

export const deleteUserAction = (userId: number) => ({
    type: DELETE_USER,
    payload: DELETE(`users/${userId}`)
})

