import {
    GET_DISABLED_DATES,
    POST_DISABLED_DATE,
    DELETE_DISABLED,
    GET_AVAILABE_DATES_AND_VALIDATE
} from '../admin/calendar/constants';
import { GET, POST, DELETE } from '../utils/api';
import { IReduxAction } from './types';

export const loadDisabledDatesAction = (): IReduxAction => ({
    type: GET_DISABLED_DATES,
    payload: GET(`calendar/disabled_dates`)
})

export const saveDisabledDateTimes = (body: object, id:string): IReduxAction => ({
    type: POST_DISABLED_DATE,
    payload: POST(`calendar/${id}`, body)
})

export const deleteDisabledDateAction = (disabledDate: string): IReduxAction => ({
    type: DELETE_DISABLED,
    payload: DELETE(`calendar/${disabledDate}`)
})

export const validatePostalAndGetAvailableDays = (postalCode: string, washingTime:string): IReduxAction => ({
    type: GET_AVAILABE_DATES_AND_VALIDATE,
    payload: GET(`calendar/validateAndGetAvailableDays/${postalCode}/${washingTime}`)
})
