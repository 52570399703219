import { withFormik } from "formik";
import { Messages } from "primereact/messages";
import React, { MutableRefObject, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loadDiscountAction, updateDiscountAction } from "../../actions/discount";
import { RootState } from "../../reducers/rootReducer";
import Layout from "../components/Layout";
import Title from "../components/Title";
import DiscountForm, { discountEditSchema } from "./DiscountForm";

const DiscountEdit: React.FC<{}> = () => {
    
    const params: any = useParams();
    const dispatch = useDispatch()
    const {discount, isLoading, updated, error } = useSelector((state: RootState) => state.discount); 
    const messagesRef:MutableRefObject<any> = useRef(); 

    useEffect(() => {
        dispatch(loadDiscountAction(params.discountId));
    },[dispatch, params.discountId])

    useEffect(() => {
        if(error){
            pushMessage('error', `Discount save failed. Try again!`)
        }
        if(updated){
            pushMessage('success', `Discount updated succesfully.`)
        }
    },[error, updated])
         
    const DiscountEnhancedForm = withFormik({
        mapPropsToValues: () => (discount),
        validationSchema: discountEditSchema,
        handleSubmit: (values, { setSubmitting, setFieldError }) => {
            dispatch(updateDiscountAction(discount.id, values))
            
        },
        displayName: 'DiscountEditForm',
    })(DiscountForm);
    

    const pushMessage = (severity: string, msg: string) => {
        messagesRef.current.show({
            severity: severity,
            detail: msg,
            life: 15000
        });
    }
    return (
        <Layout>
            <Messages ref={messagesRef} />
            <Title type="h1">Edit Discount #{params.discountId}</Title>
            <DiscountEnhancedForm />
            {isLoading && 
                <div>Loading..</div>
            }
        </Layout>
    )
}

export default DiscountEdit;
