import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import './login.scss';
import { loginAction } from '../../actions/auth';
import { RootState } from '../../reducers/rootReducer';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useHistory } from 'react-router-dom';

const Login: React.FC<{}>  = () => {

    const {authenticated, error, isLoading} = useSelector((store: RootState) => store.auth)
    const dispatch = useDispatch();
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showError, setShowError] = useState(false);

    const handleSubmit = () => {dispatch(loginAction({'email': username, "password": password})); setShowError(true)}

    useEffect(()=>{
        if(authenticated && !error && !isLoading){
            history.push('/admin/dashboard')
        }
    },[history, authenticated, error, isLoading])

    return (
        <>
        <div className="p-grid p-justify-center p-align-center login-container">
            <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 ">
                <div className="box p-shadow-21" >
                    <div className="p-d-flex p-flex-column login-box">
                        <div className="p-mb-2 row">
                            <label htmlFor="username1" className="p-d-block">Username</label>
                            <InputText id="username1" value={username} onChange={ (e: any) => setUsername(e.target.value)} aria-describedby="username1-help" className={`p-d-block p-invalid`}/>
                        </div>
                        <div className="p-mb-2 row">
                            <label htmlFor="password" className="p-d-block">Password</label>
                            <InputText type="password" id="password" value={password} onChange={ (e: any) => setPassword(e.target.value)} aria-describedby="username1-help" className="p-d-block"/>
                        </div>
                        <div className="p-mb-2 row">
                            <Button label="Kirjaudu" onClick={() => handleSubmit()}  disabled={isLoading ?? 'disabled'}/>
                        </div>
                        {(showError && error) && 
                            <div className="login-error p-mb-2 row">
                                <p>Username or password incorrect</p>
                            </div>}
                        {isLoading  && <div><ProgressSpinner/></div>}
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Login;
