
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeAmountAction, IOrderWindow } from '../../actions/order';
import { RootState } from '../../reducers/rootReducer';

import Kirkaslaskuri_logo from '../../static/img/Hintalaskuri_logo_new.svg';
import { getMallOrderTotal, getOrderDisabled, getOrderTotal, VERKKOTILAAJAN_ETU } from '../../utils/customSelectors';
import DiscountBlock from './DiscountBlock';
import FreeTimeModal from './FreeTimeModal';
import star from '../../static/img/Star.svg'
import usePrevious from '../../hooks/usePrevious';
import SlotEffect from '../../components/SlotEffect';
import { priceAfterHouseholdDeduction } from '../../utils/globals';
import HouseHoldDeductionContent from '../../components/HouseHoldDeductionContent';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { setBasePriceInfoBlockSeen } from '../../actions/counter';
import { Trans, useTranslation } from 'react-i18next';


const TopContent: React.FC<{}> = () => {
    const {total, discountTotal, rawTotal} = useSelector((state:RootState) => getOrderTotal(state))
    const { mall_total } = useSelector((state: RootState) => getMallOrderTotal(state))
    const {orderDisabled} = useSelector((state:RootState) => getOrderDisabled(state))
    const {order} = useSelector((state:RootState) => state.order);
    const { base_price } = useSelector((state:RootState) => state.counter.config);
    const {base_price_info_block} = useSelector((state: RootState) => state.counter)
    const [showHouseholdDeduction, setShowHouseHoldDeduction] = useState<boolean>(false)
    
    const prevTotal = usePrevious(total || 0);
    const prevDiscountTotal = usePrevious(discountTotal || 0);
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    const isShoppingMall: boolean = !!order.mall;


    const validateAndOrder = () => {
        //change Nan window amounts values to zero
        order.windows.map((window: IOrderWindow) => {
            if(isNaN(window.amount)){
                dispatch(changeAmountAction(window.windowId, 0))
            }
            return true;
        })
        history.push(isShoppingMall ? '/ostari/yhteystiedot' : '/tilauskalenteri');
    }

    useEffect(() => {
        if(base_price_info_block && orderDisabled) {
            dispatch(setBasePriceInfoBlockSeen({seen: false}))
        }
    }, [orderDisabled, base_price_info_block, dispatch])

    const discountClassName = isNaN(discountTotal) ? 'price' : 'discount-applied';    
    const totalToShow = isShoppingMall ? mall_total : rawTotal === (base_price - VERKKOTILAAJAN_ETU) ? 0 : total //< minimum_price ? minimum_price : total;
    const discountTotalToShow = totalToShow === 0 ? 0 : discountTotal //< minimum_price ? minimum_price : discountTotal;


    const getLabelForButton = () => isShoppingMall ? "Siirry osoitesivulle" : t('summary_part.choose_time');

    const renderFooter = () => {
        return (
            <div>
                <Button label={t('thank')} onClick={() => setShowHouseHoldDeduction(false)} autoFocus />
            </div>
        );
    }

    return (
        <div className="top-content p-col-12 p-lg-3 p-md-12 p-sm-12">
            <Dialog
                header={t('household_text.title')}
                visible={showHouseholdDeduction}
                footer={renderFooter()}
                onHide={() => setShowHouseHoldDeduction(false)}
            ><HouseHoldDeductionContent /></Dialog>
            <div className="top-content-wrapper">
                <div className="kirkaslaskuri-logo">
                    <img src={Kirkaslaskuri_logo} alt="Hintalaskuri logo" />
                </div>
                <div className="top-content-text" >
                    { t('summary_part.calculate_price') }
                </div>
                <div className="price-wrapper">
                    { !isShoppingMall ?
                        <span className="price-text">
                            <Trans i18nKey="summary_part.total_price"/>
                        </span>
                    :
                    <>
                        <span className="price-text">Hinta / pesukerta</span>
                        <Tooltip target=".tooltip-target-icon" />
                        <span className="tooltip-target-icon" 
                            data-pr-tooltip="Paketin perushinta 49 e sisältää 1 - 10 näyteikkunaa"
                            data-pr-showdelay={ 300 }>
                            <i className="pi pi-question-circle"></i>
                        </span>
                    </>
                    }
                    <span className="price-text vat">{!isShoppingMall ? t('summary_part.include_vat') : '(Alv 0 %)' }</span>
                    <div className="divider-top" />
                    {!isNaN(discountTotal) && 
                        <div className="price">
                            { SlotEffect(prevDiscountTotal, discountTotalToShow)}
                        </div>
                    }
                    <div className={`${discountClassName} `}>
                        {isNaN(+discountTotalToShow) ? SlotEffect(prevTotal, totalToShow) : (totalToShow + `€`)}
                        
                    </div>
                    <div className="divider-bottom" />
                </div>
                {!isShoppingMall && 
                <div className="kotitalousvahennys">
                    <span>
                            <span className="hdc-link" onClick={() => setShowHouseHoldDeduction(true)}>{t('summary_part.household_deduction')}</span>
                    {` `} 
                        {priceAfterHouseholdDeduction( isNaN(discountTotal) ? totalToShow : discountTotalToShow) + '€'}
                    </span>
                </div>
                }
                
                <div className="actions">
                    <Button 
                        onClick={()=> validateAndOrder()}
                        disabled={orderDisabled }
                        className="order-button" 
                        type="submit" 
                        label={getLabelForButton()}></Button>
                    <img src={star} alt="tahti" className="star" />
                    {
                        !isShoppingMall &&
                        <>
                            <FreeTimeModal placeClass="sidebar" />
                            <DiscountBlock placeClass="sidebar" />
                        </>
                    }
                </div>


            </div>
        </div>
    )
}

export default TopContent;

                   