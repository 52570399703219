import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../../reducers/rootReducer'
import Layout from '../components/Layout'
import Title from '../components/Title'
import { Dialog } from 'primereact/dialog'
import { Messages } from 'primereact/messages'
import { deleteOrderAction, loadOrdersAction } from '../../actions/order'
import moment from 'moment'
import { SHOW_TIME_FORMAT } from '../../utils/globals'
import { PAYMENT_TYPE } from '../../reducers/order'
import { Checkbox } from 'primereact/checkbox'


const OrderList: React.FC<{}> = () => {

    const {orders , deleted} = useSelector((state:RootState) => state.order)

    const [displayDeleteConfirmation, setDisplayDeleteConfirmation] = useState(false);
    const [deleteOrderId, setDeleteOrderId] = useState('')
    const [onlyRepeated, setOnlyRepeated] = useState(false)
    const [onlyInternational, setOnlyInternational] = useState(false) 
    const messagesRef:MutableRefObject<any> = useRef(); 
    const dispatch = useDispatch()
    //const history = useHistory()
    
    useEffect(() => {
        dispatch(loadOrdersAction());        
    },[dispatch])

    //const createDiscountLinkBtn = () => history.push('create')

    const renderDeleteDialogFooter = () => <div>
            <Button label="No" icon="pi pi-times" onClick={() => setDisplayDeleteConfirmation(false) } className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={() => {
                setDisplayDeleteConfirmation(false); 
                dispatch(deleteOrderAction(deleteOrderId));
                }} 
                autoFocus />
        </div>

        useEffect(() => {
            if(deleted){
                pushMessage('success', `Order deleted successfully!`)
            }
        },[deleted])

        const pushMessage = (severity: string, msg: string) => {
            messagesRef.current.show({
                severity: severity,
                detail: msg,
                life: 15000
            });
        }

    //Custom columns
    const actionColumn = (rowData: any) => <div>
            <Link to={`edit/${rowData.id}`} title="Edit"><i className="pi pi-cog" /></Link>
            <i onClick={()=> { setDeleteOrderId(rowData.id); setDisplayDeleteConfirmation(true) }} className="pi pi-trash" title="Delete"/>
        </div>
    const paymentColumn = (rowData: any) =>  <div>{rowData.payment_type === PAYMENT_TYPE.BILL ? 'Lasku' : 'Verkkomaksu'}</div>
    const shippingDayColumn = (rowData: any) => <div>{moment(rowData.shipping_day).format(SHOW_TIME_FORMAT)}</div>
    const orderDayColumn = (rowData: any) => <div>{moment(rowData.created).format(SHOW_TIME_FORMAT)}</div>
    // const isRepeatedOrderColumn = (rowData: any) => <div>{ rowData.repeated_order ? 'Kyllä' : 'Ei' }</div>
    const repeatInterval = (rowData: any) => <div>{ rowData.clean_interval }</div>

    const getRepeatedOrders = () => orders.filter((o: any) => o.repeated_order);
    const getOrdersWithInternationalCleaner = (orders: any) => orders.filter((o: any) => o.international_cleaner);
    // Get orders that are created after 5.10.2023
    const getOrdersAfterDate = () => orders.filter((o: any) => moment(o.created).isAfter('2023-10-05'));
    // Get orders inside of dates 6.10.2023 - 31.7.2024. The checkbox has been active that time period.
    const getOrdersBetweenDates = () => orders.filter((o: any) => moment(o.created).isBetween('2023-10-06', '2024-7-31', 'day', '[]'));
    const getPercentageOfInternationalCleaners = () => {
        const totalOrders = getOrdersBetweenDates();
        if (!totalOrders) {
            return 0;
        }
        return (getOrdersWithInternationalCleaner(totalOrders).length / totalOrders.length * 100).toFixed(1);
    };

    const getData = () => onlyRepeated && onlyInternational ? getOrdersWithInternationalCleaner(orders).filter((o: any) => o.repeated_order) : onlyRepeated ? getRepeatedOrders() : onlyInternational ? getOrdersWithInternationalCleaner(orders) : orders
 /*
   const fromColumn = (rowData: any) => timeColumn(rowData.from)
    const toColumn = (rowData: any) => timeColumn(rowData.to)

    const amountColumn = (rowData: any) => <div>
        {rowData.discount} {rowData.type === DISCOUNT_TYPE.FIXED ? <>&euro;</> : '%'}
    </div>

    const usedColumn = (rowData: any) => <div>
        {rowData.applied} / {rowData.limit}
    </div>

    const activeColumn = (rowData: any) => <div>
        {rowData.active ? <i className="pi pi-check"/> : <i className="pi pi-ban" />}
    </div>
*/
    return (
        <Layout>

            <Messages ref={messagesRef} />
            <Dialog 
                modal
                header="Delete Confirmation" 
                visible={displayDeleteConfirmation}  
                style={{ width: '450px' }} 
                footer={renderDeleteDialogFooter()} 
                onHide={() => setDisplayDeleteConfirmation(false)
            }>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span>Are you sure you want to delete Order <span className="delete-email">{deleteOrderId}</span>?</span>
                </div>
            </Dialog>
            <div className="content-header">
                <Title type="h2">Tilaukset</Title>
                <br></br>
                <Checkbox onChange={(e) => setOnlyRepeated(e.checked)} checked={onlyRepeated} />       
                <label> Vain toistuvat</label>
                <Checkbox onChange={(e) => setOnlyInternational(e.checked)} checked={onlyInternational} style={{ marginLeft: '10px' }}/>       
                <label> &quot;Minulle käy eng kielinen&quot; täpätty</label>
                <br></br>
                {(!onlyRepeated && onlyInternational) && <div className="p-mt-2"><span style={{ marginRight: '5px' }}><b>{getPercentageOfInternationalCleaners()}%.</b> </span> {`Määrä: ${getOrdersWithInternationalCleaner(getOrdersBetweenDates()).length} / ${getOrdersBetweenDates().length}.  (6.10.2023 - 31.7.2024)`}</div>}
            </div>
            <div className="discount-list">
                <div className="card">
                    <DataTable value={getData()} sortField={'created'} sortOrder={-1}>
                        <Column field="id" header="Order ID" sortable></Column>
                        {onlyRepeated && <Column field="repeated_order" header="Toistuvan pesuväli" body={repeatInterval} sortable></Column> }
                        <Column field="customer_id.name" header="Tilaaja" sortable></Column>
                        <Column field="easoft_order_id" header="Easoft order ID" sortable></Column>
                        <Column field="shipping_day" header="Pesupäivä" body={shippingDayColumn} sortable></Column>
                        <Column field="payment_type" header="Maksutapa" body={paymentColumn} sortable></Column>
                        <Column field="created" header="Tilauspäivä" body={orderDayColumn} sortable></Column>
                        <Column field="action" header="Actions" body={actionColumn} sortable/>
                    </DataTable>
                </div>
            </div>
        </Layout>
    )
}

export default OrderList;

/*
                        <Column field="discount" header="Määrä" body={amountColumn}></Column>
                        <Column field="applied" header="Käytetty/max" body={usedColumn}></Column>
                        <Column field="from" header="Alkaa" body={fromColumn}></Column>
                        <Column field="to" header="Päättyy" body={toColumn}></Column>
                        <Column field="active" header="Aktiivinen" body={activeColumn}></Column>
                        */