import { DELETE, GET, POST, PUT } from '../utils/api';

import {
    LOAD_COUNTER_CONFIG,
    LOAD_COUNTERS_CONFIG,
    CREATE_COUNTER_CONFIG,
    UPDATE_COUNTER_CONFIG,
    DELETE_COUNTER_CONFIG,
    SET_BASE_PRICE_INFO_BLOCK,
    SET_SHOW_VIDEO_GUIDE,
} from '../admin/counter/constants';
import { IReduxAction } from './types';


/*
 * Counter action creators
 */

export const loadCountersConfigAction = ():IReduxAction => ({
    type: LOAD_COUNTERS_CONFIG,
    payload: GET(`counter`)
})

export const loadCounterConfigAction = (id: number):IReduxAction => ({
    type: LOAD_COUNTER_CONFIG,
    payload: GET(`counter/${id}`)
})

export const createCounterAction = (body: object):IReduxAction => ({
    type: CREATE_COUNTER_CONFIG,
    payload: POST(`counter`, body)
})

export const updateCounterAction = (userId: number, body: object):IReduxAction => ({
    type: UPDATE_COUNTER_CONFIG,
    payload: PUT(`counter/${userId}`, body)
})

export const deleteCounterAction = (userId: number):IReduxAction => ({
    type: DELETE_COUNTER_CONFIG,
    payload: DELETE(`counter/${userId}`)
})

export const setBasePriceInfoBlockSeen = (payload: Object) => ({
    type: SET_BASE_PRICE_INFO_BLOCK,
    payload: () => payload
})

export const setShowVideoGuide = (payload: number) => ({
    type: SET_SHOW_VIDEO_GUIDE,
    payload: () => payload
})