import {
    GET_USER,
    GET_USERS,
    POST_CREATE_USER,
    PUT_UPDATE_USER,
    DELETE_USER
} from '../admin/user/constants';


const initialState = {
    user: {}, 
    users: [],
    created: false,
    deleted: false,
    updated: false,
    isLoading: false,
    error: false
}

export function userReducer(state = initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
        case `${GET_USER}_PENDING`:
        case `${GET_USERS}_PENDING`:
        case `${DELETE_USER}_PENDING`:
        case `${PUT_UPDATE_USER}_PENDING`:
            return {
                ...state,
                ...{ error: false, isLoading: true, updated: false, deleted: false, created: false }
            }
        case `${GET_USER}_FULFILLED`:
            return {
                ...state,
                ...{
                    user: action.payload,
                    error: false,
                    isLoading: false
                }
            }
        case `${PUT_UPDATE_USER}_FULFILLED`:
            return {
                ...state,
                ...{
                    user: action.payload,
                    updated: true,
                    error: false,
                    isLoading: false
                }
            }
        case `${POST_CREATE_USER}_PENDING`:
            return {
                ...state,
                ...{ error: false, isLoading: true, created: false }
            }
        case `${POST_CREATE_USER}_FULFILLED`:
                return {
                    ...state,
                    ...{
                        user: action.payload,
                        created: true,
                        error: false,
                        isLoading: false
                    }
                }
        case `${GET_USERS}_FULFILLED`:
            return {
                ...state,
                ...{
                    users: action.payload,
                    error: false,
                    isLoading: false
                }
            }
        case `${DELETE_USER}_FULFILLED`:
            return {
                ...state,
                ...{
                    users: state.users.filter((user: any, i) => Number(user.id) !== Number(action.payload.id) ),
                    deleted: action.payload.message,
                    error: false,
                    isLoading: false
                }
            }
        case `${GET_USER}_REJECTED`:
        case `${GET_USERS}_REJECTED`:
        case `${POST_CREATE_USER}_REJECTED`:
        case `${PUT_UPDATE_USER}_REJECTED`:
        case `${DELETE_USER}_REJECTED`:
            return {
                ...state,
                ...{ error: true, isLoading: false }
            }
        default:
            return state
    }
}

