import { GET, POST, PUT, DELETE } from '../utils/api';

import {
    GET_NOTIFICATION,
    GET_NOTIFICATIONS,
    POST_CREATE_NOTIFICATION,
    PUT_UPDATE_NOTIFICATION,
    DELETE_NOTIFICATION
} from '../admin/notification/constants';
import { IReduxAction } from './types';

export const loadActiveNotificationsAction = (): IReduxAction => ({
    type: GET_NOTIFICATIONS,
    payload: GET(`notification`)
})

export const loadNotificationAction = (notificationId: number): IReduxAction => ({
    type: GET_NOTIFICATION,
    payload: GET(`notification/${notificationId}`)
})

export const loadNotificationsAction = (): IReduxAction => ({
    type: GET_NOTIFICATIONS,
    payload: GET(`notification/list`)
})

export const createNotificationAction = (body: object): IReduxAction => ({
    type: POST_CREATE_NOTIFICATION,
    payload: POST(`notification`, body)
})

export const updateNotificationAction = (notificationId: number, body: object): IReduxAction => ({
    type: PUT_UPDATE_NOTIFICATION,
    payload: PUT(`notification/${notificationId}`, body)
})

export const deleteNotificationAction = (notificationId: number): IReduxAction => ({
    type: DELETE_NOTIFICATION,
    payload: DELETE(`notification/${notificationId}`)
})