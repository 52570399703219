
import { InputText } from 'primereact/inputtext';
import React, { useState, ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDiscountCode, validateDiscountCode } from '../../actions/discount';
import { IOrderWindow, updateWindowsPrices } from '../../actions/order';
import { DISCOUNT_CODE_STATES, DISCOUNT_TYPE } from '../../admin/discount/constants';
import { initialState } from '../../reducers/discount';
import { RootState } from '../../reducers/rootReducer';
import { DISCOUNT_MIN_LENGTH } from '../../utils/globals';
import { useTranslation } from 'react-i18next';


const DiscountBlock: React.FC<{placeClass: string}> = (props: any) => {
    const dispatch = useDispatch();
    const {discount, discountCode, valid, isLoading} = useSelector((state:RootState) => state.discount)
    const {order} = useSelector((state:RootState) => state.order);
    const [showDiscount, setShowDiscount] = useState<boolean>(discountCode !== initialState.discountCode ? true : false)
    const [validate, setValidate] = useState<boolean>(false);
    const [updatePrices, setUpdatePrices] = useState<boolean>(false);

    const { t } = useTranslation();

    useEffect(() => {
        if(validate && discountCode && discountCode.length > DISCOUNT_MIN_LENGTH){
            setValidate(false);
            dispatch(validateDiscountCode({code: discountCode}));
            setUpdatePrices(true);
        }
    }, [discountCode, dispatch, validate])


    // update windows prices if discount code valid
    useEffect(() => {
        if(updatePrices && valid === DISCOUNT_CODE_STATES.VALID && discount.type === DISCOUNT_TYPE.PERCENT) {
            setUpdatePrices(false);
            const windowsWithDiscount = order.windows.map((window: IOrderWindow): IOrderWindow => ({
                ...window, 
                discount_price: Math.floor(window.price - (window.price * discount.discount / 100))
            }),[])
            dispatch(updateWindowsPrices(windowsWithDiscount));
        }

    }, [discount.discount, discount.type, dispatch, order.windows, updatePrices, valid])


    return (
        <div className={`discount-block p-col p-md-12 p-sm-12 ${props.placeClass}`}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {(!showDiscount && discountCode.length === 0 ) && <button onClick={() => setShowDiscount(true)} >{t('campaign_code')}</button>}
            {(showDiscount || discountCode.length > 0 )  && 
                <div className="discount-action">
                    <div className="title">{t('campaign_code')}</div>
                    <label htmlFor="discount-field">{t('campaign_text')}</label>
                    <span className="p-input-icon-right">
                        {isLoading && <i className="pi pi-spin pi-spinner"></i>}
                        {(discountCode && discountCode.length > 4) && 
                        <>
                            {valid === DISCOUNT_CODE_STATES.VALID && <i className="pi pi-check"></i>}
                            {(valid === DISCOUNT_CODE_STATES.INVALID && !isLoading ) && <i className="pi pi-ban"></i>}
                        </>}
                        <InputText  
                            type="text"
                            value={discountCode}
                            name="discount"
                            onChange={(e:ChangeEvent<HTMLInputElement>) => {
                                // Discountblock is used in 2 places so restrict code double validation
                                setValidate(true);
                                dispatch(setDiscountCode(e.target.value));
                            }}
                            id={`discount-field ${props.placeClass}`}
                        />
                    </span>
                </div>}

        </div>
    );
};

export default DiscountBlock


                        