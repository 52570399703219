import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'

import monitorReducersEnhancer from './middleware/monitorReducers'
import loggerMiddleware from './middleware/reduxLogger'
import IsPromise from './middleware/IsPromise'
import rootReducer from './reducers/rootReducer'
import promise from 'redux-promise-middleware'

export default function configureStore() {

    const composeEnhancers =
        typeof window === 'object' &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
          (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                latency: 0
                // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
            }) : compose;

    let middlewares = [thunkMiddleware, IsPromise, promise];
    let enhancers = [];

    if (process.env.NODE_ENV !== 'production' && (module as any).hot) {
        middlewares.push(loggerMiddleware);
        enhancers.push(monitorReducersEnhancer);
    }

    enhancers.push(applyMiddleware(...middlewares));

    const persistedState = localStorage.getItem('reduxState')
        ? { 'questionnaire': JSON.parse(localStorage.getItem('reduxState') || '') }
        : {};

    if (Object.keys(persistedState).length !== 0) {
       localStorage.removeItem('reduxState');
    }

    const store = createStore(rootReducer, /*persistedState, preloadedState,*/ composeEnhancers(...enhancers));

    /*if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
    }*/

    return store;
}
