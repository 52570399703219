import React, { ReactChildren, ReactChild }  from 'react';
import Footer from './Footer';


export interface ContentProps  { 
    children: ReactChild | ReactChildren;
 }

const Content: React.FC<ContentProps> = ({ children }: ContentProps) => {
    
    return (
        <>
        <div className="page-container">
                {children}
        </div>
        <Footer />
        </>
    )
}

export default Content;
