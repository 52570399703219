import React from 'react'

import phone from '../static/img/phone.svg'
import comment from '../static/img/comment.svg'
//import credit_card from '../static/img/credit_card.svg'
//import tripleA from '../static/img/Kirkaslasi-AAA.png'
import SV from '../static/img/sv-2021.jpeg';
import luotettavaKumppani from '../static/img/luotettava_kumppani.png'
import { COMMON_TEL } from '../utils/globals';
import { useTranslation } from 'react-i18next';

const Footer = () => {

    const { t } = useTranslation();

    return <>
        <div className="new-footer">
            <div className="icon-text">
                <img src={phone} alt="phone" />
                <span><a href={'tel:' + COMMON_TEL}>{t('support')}: {COMMON_TEL}</a></span>
            </div>

            <div className="icon-text">
                <img src={comment} alt="phone" />
                <span><a href="mailto:info@kirkaslasi.fi?subject=Palaute Kirkaslaskurista" >{t('give_feedback')}</a></span>
            </div>
            {/*<div className="icon-text">
                <img src={credit_card} alt="phone" />
                <span>Maksutavat</span>
            </div>*/}
            <div className="right">
                <img className="AAA" src={luotettavaKumppani} alt="Luotettava kumppani" />
                <img className="SV" src={SV} alt="Suomen Vahvimmat 2021" />
            </div>
        </div>
    </>
}

export default Footer;