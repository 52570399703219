import {
    GET_SUGGESTIONS,
    GET_POSTAL_AREAS,
    CREATE_POSTAL_AREAS,
    DELETE_POSTAL_AREA,
    GET_CLEANERS,
    CREATE_CLEANERS,
    DELETE_CLEANERS
} from '../admin/settings/constants';


const initialState = {
    suggestions: [], 
    cleaners: [],
    postalAreas: [],
    deleted: undefined,
    isLoading: false,
    error: false
}

export function settingsReducer(state = initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
        case `${GET_SUGGESTIONS}_PENDING`:
        case `${GET_CLEANERS}_PENDING`:
        case `${CREATE_CLEANERS}_PENDING`:
        case `${DELETE_CLEANERS}_PENDING`:
        case `${GET_POSTAL_AREAS}_PENDING`:
        case `${CREATE_POSTAL_AREAS}_PENDING`:
        case `${DELETE_POSTAL_AREA}_PENDING`:
            return {
                ...state,
                ...{ error: false, isLoading: true}
            } 
        case `${GET_SUGGESTIONS}_FULFILLED`:
            return {
                ...state,
                ...{
                    suggestions: action.payload,
                    error: false,
                    isLoading: false
                }
            }
        case `${DELETE_CLEANERS}_FULFILLED`:
        case `${DELETE_POSTAL_AREA}_FULFILLED`:
            return {
                ...state,
                ...{
                    deleted: action.payload ,
                    error: false,
                    isLoading: false
                }
            }
        case `${GET_POSTAL_AREAS}_FULFILLED`:
        case `${CREATE_POSTAL_AREAS}_FULFILLED`:
            return {
                ...state,
                ...{
                    postalAreas: action.payload,
                    error: false,
                    isLoading: false
                }
            }            
        case `${GET_CLEANERS}_FULFILLED`:
        case `${CREATE_CLEANERS}_FULFILLED`:
            return {
                ...state,
                ...{
                    cleaners: action.payload,
                    error: false,
                    isLoading: false
                }
            }
        case `${GET_SUGGESTIONS}_REJECTED`: 
        case `${GET_POSTAL_AREAS}_REJECTED`:
        case `${CREATE_POSTAL_AREAS}_REJECTED`:
        case `${DELETE_POSTAL_AREA}_REJECTED`:
        case `${GET_CLEANERS}_REJECTED`:
        case `${CREATE_CLEANERS}_REJECTED`:
        case `${DELETE_CLEANERS}_REJECTED`:
            return {
                ...state,
                ...{ error: true, isLoading: false }
            }
        default:
            return state
        }
    }

