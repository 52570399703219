import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { deleteDiscountAction, loadDiscountsAction } from '../../actions/discount'
import { RootState } from '../../reducers/rootReducer'
import Layout from '../components/Layout'
import Title from '../components/Title'
import moment from 'moment';
import { SHOW_TIME_FORMAT } from '../../utils/globals'
import { Dialog } from 'primereact/dialog'
import { Messages } from 'primereact/messages'
import { DISCOUNT_TYPE } from './constants'


const DiscountList: React.FC<{}> = () => {

    const {discounts, deleted} = useSelector((state:RootState) => state.discount)

    const [displayDeleteConfirmation, setDisplayDeleteConfirmation] = useState(false);
    const [deleteDiscount, setDeleteDiscount] = useState({id:NaN, code:''})
    const messagesRef:MutableRefObject<any> = useRef(); 
    const dispatch = useDispatch()
    const history = useHistory()
    
    useEffect(() => {
        dispatch(loadDiscountsAction());
    },[dispatch])

    const createDiscountLinkBtn = () => history.push('create')

    const renderDeleteDialogFooter = () => <div>
            <Button label="No" icon="pi pi-times" onClick={() => setDisplayDeleteConfirmation(false) } className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={() => {
                setDisplayDeleteConfirmation(false); 
                dispatch(deleteDiscountAction(deleteDiscount.id));
                }} 
                autoFocus />
        </div>

        useEffect(() => {
            if(deleted){
                pushMessage('success', `Discount deleted successfully!`)
            }
        },[deleted])

        const pushMessage = (severity: string, msg: string) => {
            messagesRef.current.show({
                severity: severity,
                detail: msg,
                life: 15000
            });
        }

    //Custom columns
    const actionColumn = (rowData: any) => <div>
            <Link to={`edit/${rowData.id}`} title="Edit"><i className="pi pi-cog" /></Link>
            <i onClick={()=> { setDeleteDiscount(rowData); setDisplayDeleteConfirmation(true) }} className="pi pi-trash" title="Delete"/>
        </div>

    const timeColumn = (datetime: string) => <div>{moment(datetime).format(SHOW_TIME_FORMAT)}</div>
    const fromColumn = (rowData: any) => timeColumn(rowData.from)
    const toColumn = (rowData: any) => timeColumn(rowData.to)

    const amountColumn = (rowData: any) => <div>
        {rowData.discount} {rowData.type === DISCOUNT_TYPE.FIXED ? <>&euro;</> : '%'}
    </div>

    const usedColumn = (rowData: any) => <div>
        {rowData.applied} / {rowData.limit}
    </div>

    const activeColumn = (rowData: any) => <div>
        {rowData.active ? <i className="pi pi-check"/> : <i className="pi pi-ban" />}
    </div>

    return (
        <Layout>

            <Messages ref={messagesRef} />
            <Dialog 
                modal
                header="Delete Confirmation" 
                visible={displayDeleteConfirmation}  
                style={{ width: '450px' }} 
                footer={renderDeleteDialogFooter()} 
                onHide={() => setDisplayDeleteConfirmation(false)
            }>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span>Are you sure you want to delete discount <span className="delete-email">{deleteDiscount.code}</span>?</span>
                </div>
            </Dialog>
            <div className="content-header">
                <Title type="h2">Alennukset</Title>
                <Button className="create-button" onClick={() => createDiscountLinkBtn()}>Luo alennus</Button> 
            </div>
            <div className="discount-list">
                <div className="card">
                    <DataTable value={discounts}>
                        <Column field="id" header="#id"></Column>
                        <Column field="code" header="Koodi"></Column>
                        <Column field="discount" header="Määrä" body={amountColumn}></Column>
                        <Column field="applied" header="Käytetty/max" body={usedColumn}></Column>
                        <Column field="from" header="Alkaa" body={fromColumn}></Column>
                        <Column field="to" header="Päättyy" body={toColumn}></Column>
                        <Column field="active" header="Aktiivinen" body={activeColumn}></Column>
                        <Column field="action" header="Actions" body={actionColumn}/>
                    </DataTable>
                </div>
            </div>
        </Layout>
    )
}

export default DiscountList;