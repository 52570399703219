import {
    GET_DISABLED_DATES,
    POST_DISABLED_DATE,
    DELETE_DISABLED,
    GET_AVAILABE_DATES_AND_VALIDATE,
} from '../admin/calendar/constants';


export interface ICalendar {
    disabled_dates: any,
    date_created: boolean,
    available_dates: any,
    error: boolean,
    isLoading: boolean,
    deleted: boolean,
}

export interface IDisabledDate {
    id: number, 
    disabled_date: Date ,
    modifier: string,
    created: Date,
    modified: Date
}
const initialState = {
    disabled_dates: [],
    date_created: false,
    available_dates: [],
    deleted: false,
    error: false,
    isLoading: false
}


export function CalendarReducer(state:ICalendar = initialState, action: {type: string, payload: any}) {

    switch (action.type) {
        case `${GET_DISABLED_DATES}_PENDING`:
        case `${POST_DISABLED_DATE}_PENDING`:
        case `${DELETE_DISABLED}_PENDING`:
        case `${GET_AVAILABE_DATES_AND_VALIDATE}_PENDING`:
            return {
                ...state,
                error: false,
                isLoading: true,
                date_created: false,
                deleted: false,
            }
        case `${GET_DISABLED_DATES}_REJECTED`:
        case `${POST_DISABLED_DATE}_REJECTED`:
        case `${DELETE_DISABLED}_REJECTED`:
        case `${GET_AVAILABE_DATES_AND_VALIDATE}_REJECTED`:
            return {
                ...state,
                error: true,
                isLoading: false,
                date_created: false,
                deleted: false,
            }
        /*case `${GET_VALIDATE_POSTALCODE}_REJECTED`:
            return {
                ...state,
                error: true,
                isLoading: false,
                valid_postalcode: false,
                date_created: false,
                deleted: false,
            }
        case `${GET_VALIDATE_POSTALCODE}_FULFILLED`:
            return {
                ...state,
                valid_postalcode: action.payload.validCode,
                error: false,
                isLoading: false
            }*/
        case `${GET_DISABLED_DATES}_FULFILLED`:
            return {
                ...state,
                disabled_dates: action.payload,
                error: false,
                isLoading: false
            }
        case `${POST_DISABLED_DATE}_FULFILLED`:
            return {
                ...state,
                disabled_dates: action.payload,
                error: false,
                isLoading: false,
                date_created: true
            }
        case `${DELETE_DISABLED}_FULFILLED`:
            return {
                ...state,
                discount: action.payload,
                error: false,
                isLoading: false,
                deleted: true
            }
        case `${GET_AVAILABE_DATES_AND_VALIDATE}_FULFILLED`:
            return {
                ...state,
                available_dates: action.payload,
                error: false,
                isLoading: false,
                deleted: true
            }
        default:
            return state
    }
}

