import encodeQueryParams from './encodeUrlParams';
const { REACT_APP_API_URL } = process.env;

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: any) {
    if (response) {
        if (response.status === 204 || response.status === 205) {
            return null;
        }

        return response.json();
    }
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response: any) {
    if (response && response.status >= 200 && response.status < 300) {
        return response;
    }
    
    
    if (response.status === 401) {
      window.location.href = '/admin';
    } 

    const error = new Error(response.statusText)
    //error.response = response
    const promise = Promise.reject(error)
    return promise
}

enum RequestMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
  }

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} path       The path we want to request
 * @param  {string} method
 *
 * @return {object}           The response data
 */
async function request(path: string, method: RequestMethod, body?: any, isRefreshReq = false): Promise<any> {
    let url = REACT_APP_API_URL + path;
    // url = addLanguageToUrl(url)
    const headers = {
        'Content-Type': 'application/json; charset=utf-8'
    };

    let response  = await fetch(url, {
        headers,
        method,
        credentials: 'include',
        body: body || undefined,
    })
    
    // Refresh Auth httponly cookie if request unauthorized and send original request again
    if(response.status === 401 && !isRefreshReq){
        await request('auth/refresh', RequestMethod.GET, null, true);
        return request(path, method, body, true);
    }

    const statusResponse = await checkStatus(response);
    const parseResponse = await parseJSON(statusResponse)

    return parseResponse;
}

export function GET(path: string, params = {}) {
    return request(path + encodeQueryParams(params),  RequestMethod.GET, null);
}

export function POST(path: string, body: any) {
    return request(path,  RequestMethod.POST, JSON.stringify(body));
}

export function PUT(path: string, body: any) {
    return request(path, RequestMethod.PUT, JSON.stringify(body));
}

export function DELETE(path: string) {
    return request(path, RequestMethod.DELETE, null);
}

/*
function addLanguageToUrl(url) {
    //check if url contains params already
    let joinMark = url.includes('?') ? '&' : '?'
    return `${url}${joinMark}lang=${languageId}`
}
*/