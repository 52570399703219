import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import * as Yup from "yup";
import { SHOW_TIME_FORMAT} from '../../utils/globals';
import moment from 'moment';
import Title from '../components/Title';
import { FieldArray } from 'formik';
import { ADDRESS_TYPE, INVOICE_CHANNEL, PAYMENT_TYPE } from '../../reducers/order';


export const orderEditSchema =  Yup.object().shape({
    id: Yup.string()
        .required("pakollinen tieto"),
    shipping_day: Yup.date()
        .required('Alkamisaika on pakollinen tieto'),
    customer_id: Yup.object().shape({
        name: Yup.string()
            .required("Tarvittava tieto"),
        phone: Yup.string()
            .required("Tarvittava tieto")
    }),
    addresses: Yup.array().of(
        Yup.object().shape({
            street_address: Yup.string()
                .required("Tarvittava tieto"),
            name: Yup.string()
                .required("Tarvittava tieto"),
            postal_code: Yup.string()
                .required("Tarvittava tieto"),
            city: Yup.string()
                .required("Tarvittava tieto"),
            message: Yup.string()
                
        }),
    ),
});

export const orderCreateSchema = orderEditSchema;

const addressTypes = [
    {name: 'Postiosoite', value: ADDRESS_TYPE.SHIPPING},
    {name: 'Laskutusosoite', value: ADDRESS_TYPE.BILLING},
]

const paymentTypes = [
    {name: 'Lasku', value: PAYMENT_TYPE.BILL},
    {name: 'Verkkomaksu', value: PAYMENT_TYPE.ONLINE},
]

const invoiceChannelTypes = [
    {name: 'Ei käytössä', value: INVOICE_CHANNEL.UNDEFINED},
    {name: 'Email', value: INVOICE_CHANNEL.EMAIL},
    {name: 'kirje', value: INVOICE_CHANNEL.PAPER},
    {name: 'verkkolaskutus', value: INVOICE_CHANNEL.EINVOICE},
]

const OrderForm: React.FC<{}> = (props: any) => {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      //isLoading,
      setFieldValue
    } = props;

    const Field = (
        type: string, 
        fieldName: string, 
        fieldPath: string, 
        valuePath: Array<string>, 
        label: string, 
        f_errors: any = errors,
        f_touched: any = touched,
        ) => {
        return <div className="p-field p-col-2">
            <label htmlFor={fieldPath + "."+ fieldName} className="p-col-fixed label" >{label}</label>
            <div className="">
                <InputText
                    type={type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={valuePath}
                    name={fieldPath ? fieldPath + "."+ fieldName : fieldName}
                    id={fieldPath + "."+ fieldName}
                    className={'form-control' + (f_errors[fieldName] && f_touched[fieldName]? ' p-invalid' : '' )} 
                />
                {f_errors[fieldName] && f_touched[fieldName] && <small id="" className="p-invalid">{f_errors[fieldName]}</small>}
            </div> 
        </div>
    }
    
    return (
      <form onSubmit={handleSubmit} className="admin-order-form">

        <div className="p-field p-grid general">
            <div className="p-col-12">
                <span>Tilauksen ID:</span> {values.id}
            </div>
            <div className="p-col-12">
                <span>Easoft order ID: </span>{values.easoft_order_id}
            </div>

            <div className="p-col-12">
                <span>Tilauspäivä: </span>{values.created ? moment(values.created).format(SHOW_TIME_FORMAT) : ''}
            </div>
        </div>

        <Title type='h2'>Asiakas</Title>

        {values.customer_id && <div className="p-grid">
                {Field("text","name", "customer_id", values.customer_id.name, "Nimi", errors.customer_id, touched.customer_id )}
                {Field("text", "phone", "customer_id", values.customer_id.phone, "Puhelin", errors.customer_id, touched.customer_id )}
                {Field("text", "email","customer_id", values.customer_id.email, "Sähköposti", errors.customer_id, touched.customer_id )}
            </div>
        }

        <Title type='h2'>Osoitteet</Title>
        <FieldArray
            name="addresses"
            render={arrayHelpers => (
            <>
              { (values.addresses && values.addresses.length > 0 ) ?
                    ( values.addresses.map((address:any, i:number) => {
                        const addressErrors = (errors.addresses?.length && errors.addresses[i]) ? errors.addresses[i] : {};
                        const addressTouched = (touched.addresses?.length && touched.addresses[i]) ? touched.addresses[i] : {};

                        return <div className="p-grid addresses"  key={i}> 
                            <div className="p-field p-col-2">
                                <label htmlFor="type" className="p-col-fixed label" >Tyyppi</label>
                                <div className="">
                                    <Dropdown
                                        placeholder="Osoitteen tyyppi"
                                        optionLabel="name"
                                        optionValue="value"
                                        options={addressTypes}
                                        onChange={handleChange}
                                        value={address.type}
                                        name="type"
                                        id="type"
                                    />
                                    {errors.type && touched.type && <Message className="form-field-error" severity="error" text={errors.type} />}
                                </div> 
                            </div>
                            {Field("text", "name", `addresses.${i}` , values.addresses[i].name, "Nimi", addressErrors, addressTouched)}
                            {Field("text", "street_address", `addresses.${i}` , values.addresses[i].street_address, "Postiosoite", addressErrors, addressTouched)}
                            {Field("text", "postal_code", `addresses.${i}` , values.addresses[i].postal_code, "Postinumero", addressErrors, addressTouched)}
                            {Field("text", "city", `addresses.${i}` , values.addresses[i].city, "Kaupunki", addressErrors, addressTouched)}
                            {Field("text", "message", `addresses.${i}` , values.addresses[i].message, "Viesti", addressErrors, addressTouched)}
                        </div>
                })) : ''
            }</>)}
            />

        <Title type='h2'>Tilatut tuotteet</Title>
        <FieldArray
            name="order_items"
            render={arrayHelpers => (
            <>
              { (values.order_items && values.order_items.length > 0 ) ?
                    ( values.order_items.map((item:any, i:number) => {
                        const addressErrors = (errors.order_items?.length && errors.order_items[i]) ? errors.order_items[i] : {};
                        const addressTouched = (touched.order_items?.length && touched.order_items[i]) ? touched.order_items[i] : {};

                        return <div className="p-grid order_items"  key={i}> 
                            {Field("text", "name", `order_items.${i}` , values.order_items[i].name, "Nimi", addressErrors, addressTouched)}
                            {Field("number", "amount", `order_items.${i}` , values.order_items[i].amount, "Määrä", addressErrors, addressTouched)}
                            {Field("number", "price", `order_items.${i}` , values.order_items[i].price, "Hinta", addressErrors, addressTouched)}
                            {Field("number", "discount_price", `order_items.${i}` , values.order_items[i].discount_price, "Alennushinta", addressErrors, addressTouched)}

                        </div>
                })) : ''}
            </>)}
        />
        
        <div className="p-field p-grid">
            <label htmlFor="shipping_day" className="p-col-fixed label" >Pesupäivä</label>
            <div className="p-col-12">
                <Calendar 
                    dateFormat="dd.mm.yy"
                    value={values.shipping_day ? moment(values.shipping_day).toDate() : undefined}
                    // @ts-ignore
                    onChange={(e) => setFieldValue('shipping_day',moment(e.value.toString()).toDate())}
                    //locale={CALENDAR_FI}
                    id="shipping_day"
                />
                {errors.to && touched.to && <Message className="form-field-error" severity="error" text={errors.to} />}
            </div> 
        </div>

        <Title type='h2'>Yritys</Title>
        
        <div className="p-grid">
            {Field("text","company_name", "", values.company_name, "Yrityksen nimi", errors, touched )}
            {Field("text","company_vat", "", values.company_vat, "Y-tunnus", errors, touched )}
            {Field("text","company_einvoice_address", "", values.company_einvoice_address, "Verkkolaskuosoite", errors, touched )}
            {Field("text","company_operator_id", "", values.company_operator_id, "Operaattorin tunnus", errors, touched )}
        </div>

        <div className="p-grid">
            <div className="p-field p-col-2">
                <label htmlFor="type" className="p-col-fixed label" >Maksutapa</label>
                    <Dropdown
                        placeholder="Maksutapa"
                        optionLabel="name"
                        optionValue="value"
                        options={paymentTypes}
                        onChange={handleChange}
                        value={values.payment_type}
                        name="type"
                        id="type"
                    />
                    {errors.payment_type && touched.payment_type && <Message className="form-field-error" severity="error" text={errors.payment_type} />}
            </div>
            <div className="p-field p-col-2">
                <label htmlFor="type" className="p-col-fixed label" >Laskutuskanava</label>
                    <Dropdown
                        placeholder="Laskutuskanava"
                        optionLabel="name"
                        optionValue="value"
                        options={invoiceChannelTypes}
                        onChange={handleChange}
                        value={values.invoice_channel}
                        name="invoice_channel"
                        id="invoice_channel"
                    />
                    {errors.payment_type && touched.payment_type && <Message className="form-field-error" severity="error" text={errors.payment_type} />}
            </div>
        </div>

        <Title type='h2'>Alennukset</Title>

        <div className="p-grid">
            
            {Field("text","discount_code", "", values.discount_code, "Käytetty alennuskoodi", errors, touched )}
            {Field("text","discount_amount", "", values.discount_amount, "Alennus ( Euroa )", errors, touched )}
        </div>

        <Title type='h2'>Yhteensä</Title>
        <div className="p-grid">
            {Field("text","base_price", "", values.base_price, "Pohjahinta", errors, touched )}
            {Field("text","total_price", "", values.total_price, "Tilauksen loppusumma", errors, touched )}
            {Field("text","total_price_after_discount", "", values.total_price_after_discount, "Tilauksen loppusumma (Alennusten jälkeen)", errors, touched )}
        </div>

        {/*<Button disabled={isLoading} type="submit">Tallenna</Button>*/}
       </form>
    )
}

export default OrderForm;