import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from '../../actions/auth';
import { RootState } from '../../reducers/rootReducer';
import { useHistory } from 'react-router-dom';

const LogoutButton: React.FC<{}> = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const {user, authenticated} = useSelector((store: RootState) => store.auth)

    useEffect(() => {
        if(!authenticated){
            history.push('/admin')
        }
    }, [history, authenticated])

    const handleLogout = () => dispatch(logoutAction(user))

    return <div className='logout-btn-wrapper'>
            <div className='user-name'>{user.name}</div>
            <Button icon='pi pi-fw pi-power-off' type="button" onClick={() => handleLogout()} title='Log Out' />
        </div>
}

export default LogoutButton