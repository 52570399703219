
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";

import Title from '../../components/Title'
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers/rootReducer';
import { Dropdown } from 'primereact/dropdown';
import { createCleaners, getCleaners, getPossibleMaintainers, getPostalAreas, removeCleaner } from '../../../actions/settings';

export interface IcleanerRow {
        id: number | string
        name: string
        address: string
        maintain_group_id: string
        postalAreas: Array<string>
}

const CleanersSettings: React.FC<{}> = () => {

    const {postalAreas, cleaners, suggestions} = useSelector((state:RootState) => state.settings)
    const [suggestSelection, setSuggestSelection] = useState(false);
    const [cleanersRows, setCleanersRows] = useState<Array<IcleanerRow>>([]);
    const dispatch = useDispatch();

    useEffect(() => {setCleanersRows(cleaners)}, [cleaners])
    useEffect(() => {
        dispatch(getPossibleMaintainers()) 
        dispatch(getCleaners())
        dispatch(getPostalAreas())
    }
    ,[dispatch])
    
    let postalOptionValues = postalAreas.map((postalArea:any) =>  
        ({
            label: postalArea.name, 
            value: postalArea.id
        }),[])
    
        const addNewCleaner = () => {
            const newRowData = suggestions.filter((item:any) => item.id === suggestSelection)[0]
            setCleanersRows([
                ...cleanersRows, 
                {
                    id: "new-" + new Date().getTime(), // unique value
                    name: newRowData.name,
                    address: "",
                    maintain_group_id: newRowData.id,
                    postalAreas: []
                } 
            ])
        }
    
        const removeRow = (id:any) => {
            if((id + "").lastIndexOf('new-', 0) !== 0) {
                dispatch(removeCleaner(id));
            }
            const newRows = cleanersRows.filter((row:IcleanerRow, i:number) => row.id !== id)
            setCleanersRows(newRows);
        }
    
        const handleChange = (key:string, value:string|Array<string>, id:string|number) => {
            let newRows = cleanersRows.map((row:IcleanerRow, i:number) => {
                if(row.id === id ) { 
                    // @ts-ignore
                    row[`${ key }`] = value 
                }
                return row;
            })
            setCleanersRows(newRows)
        }

    const getAreaRows = (rowsData:Array<IcleanerRow> ) => rowsData.map((row:IcleanerRow, index: number) => 
        <div className="p-grid p-justify-start" key={row.id}>
            <div className="p-field">
                <label htmlFor="name-field" className="label" >Nimi</label>
                <InputText
                    className="name-field"
                    name="name"
                    value={row.name}
                    onChange={(e) => handleChange('name', e.target.value, row.id)}
                />
            </div>

            <div className="p-field">
                <label htmlFor="address-field" className="label" >Osoite</label>
                <InputText
                    className="address-field"
                    value={row.address}
                    name="address"
                    onChange={(e) => handleChange('address', e.target.value, row.id)}
                />
            </div>
            <div className="p-field">
                <label htmlFor="maintain-group-field" className="label" >Kalenteririvin id</label>
                <InputText
                    className="maintain-group-field"
                    value={row.maintain_group_id}
                    name="maintain_group_id"
                    onChange={(e) => handleChange('maintain_group_id', e.target.value, row.id)}
                />
            </div>
            <div className="p-field" >
                <label htmlFor="postal-areas" className="label" >Postinumeroalueet</label>
                <MultiSelect 
                    value={row.postalAreas}
                    options={postalOptionValues}
                    onChange={(e) =>handleChange('postalAreas', e.value, row.id)} 
                    optionLabel="label"
                    filter
                />
            </div>
            <div className="p-field">
                <Button className="remove-row" onClick={() => removeRow(row.id)} label="-" /> 
            </div>
        </div> ,[])
        
        const getSuggestions = () => suggestions.map((item:any) => ({
            label: item.name,
            value: item.id
        }), [])

    return ( 
        <div>
            <div className="content-header">
                <Title type="h2">Pesijöiden hallinta</Title>
            </div>
            <div className="tab-content">
                {getAreaRows(cleanersRows)}
                <div className="p-grid p-justify-start action-grid">
                    <div className="p-field">
                        <Button onClick={() => addNewCleaner()} label="Add new" />
                        <Dropdown
                            value={suggestSelection}
                            onChange={(e) => setSuggestSelection(e.value)} 
                            optionLabel="label" 
                            placeholder="Select new maintainer"                    
                            options={getSuggestions()}
                        />
                        <br/>
                        <Button onClick={() => dispatch(createCleaners(cleanersRows))} label="Tallenna Muutokset" />
                    </div>
                    
                </div>
            </div>
        </div>
    )
}


export default CleanersSettings;