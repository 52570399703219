import { withFormik } from 'formik'
import { Messages } from 'primereact/messages'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { loadCounterConfigAction, updateCounterAction } from '../../actions/counter'
import { RootState } from '../../reducers/rootReducer'
import Layout from '../components/Layout'
import Title from '../components/Title'
import CounterConfigForm from './CounterConfigForm'
import { CCInitialValues,CCFormValidationSchema } from './CounterConfigFormSchemas'

const ADMIN_CONFIG_ID = 8888; // AWFUL HACK FOR SHOPPING MALL


const CounterConfigEdit: React.FC<{}> = () => {
    
    const params: any = useParams();
    const dispatch = useDispatch()
    const messagesRef:MutableRefObject<any> = useRef();
    
    const {config, error, isLoading, updated } = useSelector((store: RootState) => store.counter)
    const [configDefaultValues, setConfigDefaultValues] = useState(CCInitialValues)

    useEffect(() => {
        messagesRef.current.show([])
        const configId = params.counterId <= 1 ? ADMIN_CONFIG_ID : params.counterId;
        dispatch(loadCounterConfigAction(configId))
    },[dispatch, params.counterId])

    // update form values only after success load/update request
    useEffect(() => {
        if(!error && !isLoading){
            setConfigDefaultValues(config)
        }
    },[config, error, isLoading ])

    // set Notification messages
    useEffect(() => {
        if(error){
            pushMessage('error', `Counter config save failed. Try again!`)
        }
        if(updated){
            pushMessage('success', `Counter config updated succesfully.`)
        }
    },[error, updated])

        
    const CoutnerConfigEnhancedForm = withFormik({
        mapPropsToValues: () => (configDefaultValues),
        validationSchema: CCFormValidationSchema,
        handleSubmit: async (values, { setSubmitting, setFieldError }) => {
            // @ts-ignore
            const {translate, ...rest} = values;
            setConfigDefaultValues(values);
            // console.log('FRONT: ', rest.minimum_price, values.minimum_price, 'base: ', rest.base_price) // Some bug causes these values to differ. Usually by -1.
            dispatch(updateCounterAction(params.counterId, rest))
        },
        displayName: 'CounterConfigEditForm',
    })(CounterConfigForm);
    

    const pushMessage = (severity: string, msg: string) => {
        messagesRef.current.show({
            severity: severity,
            detail: msg,
            life: 15000
        });
    }
    return <Layout>
        <Messages ref={messagesRef} />
        {config.id &&
        <>
            <div className="content-header">
                <Title type="h2">Edit: #{config?.id} {config?.translate?.name}</Title>
            </div>
            <div className="counter-content">
                <CoutnerConfigEnhancedForm />
            </div>
        </>}
    </Layout>
}

export default CounterConfigEdit