import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { withFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { createUserAction } from '../../actions/user';
import { RootState } from '../../reducers/rootReducer';
import Layout from '../components/Layout';
import Title from '../components/Title';
import UserForm, { userCreateSchema } from './UserForm';
import { Messages } from 'primereact/messages';


//TODO: How to prevent form reset when user creation failed????
const UserCreate: React.FC<{}> = () => {
    
    const dispatch = useDispatch()
    const {user, isLoading, error, created } = useSelector((store: RootState) => store.user);
    const [initialValues, setInitialValues] = useState({ name: '', email: '', password: '', passwordConfirmation: '' }); 
    const messagesRef:MutableRefObject<any> = useRef(); 
    
    useEffect(() => {
        if(created && user){
            pushMessage('success', `User #${user.id} ${user.name} created successfully`)
            // set initial values again to refresh form.
            setInitialValues({ name: '', email: '', password: '', passwordConfirmation: '' })
        }
    },[user, created])
    
    useEffect(() => {
        if(error){
            pushMessage('error', `User creation failed. Try again! Sorry I cleared all fields :(`)
        }
    },[error])

    const pushMessage = (severity: string, msg: string) => {
        messagesRef.current.show({
            severity: severity,
            detail: msg,
            life: 15000
        });
    }

    const UserEnhancedForm = withFormik({
        //enableReinitialize: true,
        mapPropsToValues: () => initialValues,
        validationSchema: userCreateSchema,
        handleSubmit: (values, { setSubmitting }) => {
            const { passwordConfirmation, ...rest} = values;
            dispatch(createUserAction(rest))
            setSubmitting(isLoading)
        },
        displayName: 'UserCreateForm',
    })(UserForm);

    return (
        <Layout>
            <Messages ref={messagesRef} />
            <Title type="h1">Create New User</Title>
            <UserEnhancedForm />
            {isLoading && 
                <div>Creating...</div>
            }
        </Layout>
    )
}

export default UserCreate;
