import React from 'react';

const Progressbar = (props: {step: number}) => {
    
    return (
        <div className="progressbar-component">
            <div className="progressbar-wrapper">
                <div className="dot filled" />
                <div className="line" />
                <div className={`dot ${ props.step > 1 && 'filled'}`} />
                <div className="line" />
                <div className={`dot ${ props.step > 2 && 'filled'}`} />
            </div>
        </div>
    );
};

export default Progressbar
