import React from 'react';
import Helmet from 'react-helmet'
import Header from '../layout/Header'
import Content from '../layout/Content';
//import Footer from '../layout/Footer';
import Progressbar from '../components/Progressbar';
import Summarize from './calendar/Summarize';
import Calendar from './calendar/Calendar';
import Notification from '../components/Notification/Notification'
import CalendarPostalcode from './calendar/CalendarPostalcode';
import { useTranslation } from 'react-i18next';

const CalendarPage: React.FC<{}> = () => {

    // useEffect(() => ReactGA.pageview(getPageUrl()),[])
    const { t } = useTranslation();


    return (
        <>
            <Helmet>
                <title>{t('title_calendar')}</title>
                <meta name="description" content="Laske ikkunanpesun hinta helposti parissa minuutissa ja tilaa pesu" />
                <meta name="keywords" content="Ikkunanpesun hinta, Ikkunanpesun hintalaskuri, Kirkaslasi, Ikkunanpesu, Ikkunanpesu netistä" />
            </Helmet>
            <Header />
            <Content >
                <>
                    <Notification />
                    <div className="calendar-page p-grid p-justify-center  p-col-fixed">

                        <div className="page-head p-col-12">
                            <div className="box">
                                <Progressbar step={1} />
                                <div className="calendar-page-title">
                                    {t('choose_day')}
                                </div>
                            </div>
                        </div>
                        <Summarize />
                        <div className="calendar-postalcode p-col-12">
                            <CalendarPostalcode />
                        </div>
                        <div className="calendar p-col-fixed">
                            <div className="box">
                                <Calendar />
                            </div>
                        </div>
                    </div>
                </>
            </Content>
            {/*<Footer toPath="/yhteystiedot"/>*/}
        </>
    )
}

export default CalendarPage;
