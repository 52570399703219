import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import HouseHoldDeductionContent from '../../components/HouseHoldDeductionContent';
import { RootState } from '../../reducers/rootReducer';
import { getCombinedOrderWindows, getMallOrderTotal, getOrderTotal, MAKSUTAPA_ALENNUS, REPEATED_ORDER_DISCOUNT, VERKKOTILAAJAN_ETU } from '../../utils/customSelectors';
import { priceAfterHouseholdDeduction } from '../../utils/globals';
import { getMallOrderDetails } from '../address/Address';
import DeliveryBlock from '../address/DeliveryBlock';
import DiscountBlock from '../counter/DiscountBlock';
import { useTranslation } from 'react-i18next';

export const PAYMENT_TYPE_DISCOUNT_CLASS = 'sum-payment-type-discount';
export const REPEATED_ORDER_DISCOUNT_CLASS = 'sum-repeated-order-discount';


const Summarize = (props: {page?:string}) => {

    const { 
        highGlassAmount, 
        highOpeningAmount, 
        balconyGlassAmount, 
        terraceGlassAmount,
        balconyRailings,
        balconyRailingMeters,
        terraceRoof,
        mall
    } = useSelector((state:RootState) => state.order.order);
    const orderWindows = useSelector((state:RootState) => getCombinedOrderWindows(state)); 
    const {total, discountTotal} = useSelector((state:RootState) => getOrderTotal(state));
    const {mall_total} = useSelector((state:RootState) => getMallOrderTotal(state));
    const [showHouseholdDeduction, setShowHouseHoldDeduction] = useState<boolean>(false);

    const { i18n, t } = useTranslation();
    const isMall = !!mall;
    const currentLanguage = i18n.language;
    

    let windowRows = orderWindows.map((window: any) => window.amount !== 0 ?
        <div key={window.windowId} className="window-sum-row">
            <span className="window-name">{(currentLanguage === 'en' && window.name_eng?.length) ? window.name_eng : window.name }</span>
            <span className="window-amount">{window.amount + ' ' + t('summary_part.pcs')}</span>
        </div> : false
    );

    // Filter null rows out
    windowRows = windowRows.filter((row:React.ReactNode | boolean) => row !== false)     

    const totalToShow = isMall ? mall_total : total; //< minimum_price ? minimum_price : total;
    const discountTotalToShow = totalToShow === 0 ? 0 : discountTotal //< minimum_price ? minimum_price : discountTotal;


    const renderFooter = () => {
        return (
            <div>
                <Button label={t('thank')} onClick={() => setShowHouseHoldDeduction(false)} autoFocus />
            </div>
        );
    }
    
    return (
    <div className="summarize p-col-12 p-md-6 p-sm-8 p-lg-5">
        <Dialog
            header={t('household_text.title')} 
            visible={showHouseholdDeduction} 
            footer={renderFooter()} 
            onHide={() => setShowHouseHoldDeduction(false)}
        >
            <HouseHoldDeductionContent />
        </Dialog>
        <div className="box">
            <div className="summarize-content">
                <div className="title">
                    <span>{t('summary_part.summary')}</span>
                </div>
                <div className="window-amounts">
                    {isMall &&
                        <div className="window-sum-row">
                            <span className="window-name">{`Pesupaketti (${getMallOrderDetails(mall.cleaning_set).name})`}</span>
                            <span className="window-amount">1 {t('summary_part.pcs')}</span>
                        </div>
                    }
                    {windowRows}
                    {highGlassAmount > 0 && 
                        <div className="window-sum-row">
                            <span className="window-name">{t('summary_part.unopening_high')}</span>
                            <span className="window-amount">{highGlassAmount} {t('summary_part.pcs')}</span>
                        </div>
                    }
                    {highOpeningAmount > 0 && 
                        <div className="window-sum-row">
                            <span className="window-name">{t('summary_part.opening_high')}</span>
                            <span className="window-amount">{highOpeningAmount} {t('summary_part.pcs')}</span>
                        </div>
                    }
                    {balconyGlassAmount > 0 &&
                        <div className="window-sum-row">
                            <span className="window-name">{t('summary_part.balcony_glasses')}</span>
                            <span className="window-amount">{balconyGlassAmount} {t('summary_part.pcs')}</span>
                        </div>
                    }
                    {(balconyRailings || balconyRailingMeters > 0 ) &&
                        <div className="window-sum-row">
                            <span className="window-name">{t('summary_part.balcony_railing')}</span>
                            {balconyRailingMeters > 0  &&
                                <span className="window-amount">{balconyRailingMeters} m</span>
                            }
                        </div>
                    }
                    {terraceGlassAmount > 0 &&
                        <div className="window-sum-row">
                            <span className="window-name">{t('summary_part.terrace_glasses')}</span>
                            <span className="window-amount">{terraceGlassAmount} {t('summary_part.pcs')}</span>
                        </div>
                    }
                    {terraceRoof &&
                        <div className="window-sum-row">
                            <span className="window-name">{t('summary_part.terrace_roof')}</span>
                        </div>
                    }
                    { !isMall && 
                        <>
                        <div className="window-sum-row">
                            <span className="window-name">{t('summary_part.discount_online')}</span>
                            <span className="window-amount">-{VERKKOTILAAJAN_ETU} €</span>
                        </div>
 
                        <div className={`window-sum-row ${PAYMENT_TYPE_DISCOUNT_CLASS}`} style={{ display: 'none' }}>
                            <span className="window-name">{t('summary_part.discount_payment')}</span>
                            <span className="window-amount">-{MAKSUTAPA_ALENNUS} €</span>
                        </div>

                        <div className={`window-sum-row ${REPEATED_ORDER_DISCOUNT_CLASS}`} style={{ display: 'none' }}>
                            <span className="window-name">{t('summary_part.repeated_order')}</span>
                            <span className="window-amount">-{REPEATED_ORDER_DISCOUNT} €</span>
                        </div>
                        </>
                    }

                </div>

                <div className="total-row">
                    <span className="total-text">{t('summary_part.price')} {isMall && ' (alv 0%)'}</span>
                    <span className="total-sum total-sum-total">{totalToShow} &euro;</span>
                </div>

                {!isNaN(discountTotal) &&
                    <div className="total-row">
                        <span className="total-text">{t('summary_part.after_discount')}</span>
                        <span className="total-sum total-sum-discount">{discountTotalToShow} &euro;</span>
                    </div>
                    }
                { !isMall &&
                    <div className="discount-row">
                            <span className="discount-text hhd" onClick={() => setShowHouseHoldDeduction(true)}>{t('summary_part.with_household')}</span>
                        <span className="discount-sum">
                                {(priceAfterHouseholdDeduction(isNaN(discountTotal) ? totalToShow : discountTotalToShow) + ' €' )}
                        </span>
                    </div>
                }
                {(props.page === "address") && <DeliveryBlock shoppingMall = {isMall}/>}
                { !isMall && <div className="alv-info">{t('summary_part.price_info')}</div> }
            </div>
            { !isMall && 
                <DiscountBlock placeClass={`sidebar ${props.page}`} />
            }
        </div>
    </div>
    );
};

export default Summarize
