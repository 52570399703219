import { Button } from 'primereact/button';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeAmountAction, IOrderWindow } from '../../actions/order';
import { RootState } from '../../reducers/rootReducer';

import pesuri_img from '../../static/img/Icon_pesuri.svg';
import sauna_img from '../../static/img/sauna.gif';
import HighWindows from './HighWindows';
import { useTranslation } from 'react-i18next';

const Windows = () => {

    const { config: { windows }, isLoading, error } = useSelector((state: RootState) => state.counter);
    const { order, isLoading: orderIsLoading } = useSelector((state: RootState) => state.order);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;


    const WindowComponents = (typeof (windows) === 'object' && !isLoading && !orderIsLoading && !error && order?.windows?.length > 0) ?
        windows.map((window: any) => {
            const windowSelections = order.windows.filter((orderWin: IOrderWindow) => window.id === orderWin.windowId)[0];
            let currentAmount: number = windowSelections.amount;
            if (window.title.toLowerCase().startsWith("kylpyhuone")) {
                window.image = sauna_img;
            }

            return <div className="accordion-tab-col p-col" key={window.id}>
                <div className="title window">{currentLang === 'en' ? window.title_eng : window.title}</div>
                <div className="image">
                    <img src={window.image} alt={currentLang === 'en' ? window.title_eng : window.title} />
                </div>
                <div className="ac-tab-content-action-wrapper">
                    <Button
                        onClick={() => currentAmount > 0 ?
                            dispatch(changeAmountAction(windowSelections.windowId, (currentAmount - 1))) : false}
                        className="minus-btn btn"
                        type="button"
                        label=""
                    >
                        <i className="pi pi-minus" ></i>
                    </Button>
                    <input
                        className="amount"
                        // type needs to be text(default) to let setSelection work. Will there be some downsides for this change??
                        maxLength={4}
                        onClick={(event: any) => {
                            event.target.setSelectionRange(0, event.target.value.length)
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = parseInt(e.target.value)
                            dispatch(changeAmountAction(windowSelections.windowId, ((!isNaN(value) || value > 0) ? value : 0)));
                        }}
                        // type="number" 
                        value={windowSelections.amount}
                    />
                    <Button
                        onClick={() => dispatch(changeAmountAction(windowSelections.windowId, (currentAmount + 1)))}
                        className="plus-btn btn"
                        type="button"
                    >
                        <i className="pi pi-plus" ></i>
                    </Button>
                </div>
            </div>
        }) : <></>


    return (
        <div className="ac-tab-content-wrapper">
            <div className="p-grid p-justify-start">
                {WindowComponents}
                <HighWindows />
                {isLoading && <div className="loading-content-block">{t('loading')}</div>}
                {(!isLoading && error) && <div className="loading-content-block">{t('error')}</div>}
            </div>
            <div className="p-grid p-align-center p-justify-center marketing-block ">
                <div className="p-col-12 p-sm-12 p-lg-12 image" >
                    <div className="box">
                        <img className="" src={pesuri_img} alt="Siivouslasta" />
                    </div>
                </div>
                <div className="p-col-12 p-sm-12  p-lg-12 text" >
                    <div className="box">
                        <span className="text">{t('tabs.info_text_windows')} </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Windows
