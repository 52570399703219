import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { Button } from 'primereact/button';
import balcony_class from '../../static/img/parveke_hitaampi.gif'
import balcony_class_railings from '../../static/img/kaide_hitaampi.gif'
import balcony_class_railings_obstacle from '../../static/img/kaide_este_nopeampi.gif'
import { changeBalconyAmountAction } from '../../actions/order';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

enum BALCONY_VALUE_TYPES {
    AMOUMNT = "AMOUMNT",
    RAILINGS = "RAILINGS",
    RAILING_METERS = "RAILING_METER",
    RAILINGS_OBSTACLES = "RAILINGS_OBSTACLES" 
} 

const Balcony = () => {

    const {order, isLoading, error} = useSelector((state:RootState) => state.order);
    const {balconyGlassAmount, balconyRailings, balconyRailingsObstacle, balconyRailingMeters} = order
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const successIconHtmlAttr = {
        icon:"pi pi-check", 
        iconPos:"right"
    }

    const showTrueSuccessIcon = balconyRailings ? successIconHtmlAttr : {};
    const showFalseSuccessIcon = balconyRailings ? {} : successIconHtmlAttr;

    const showTrueSuccessIconObstacles = balconyRailingsObstacle ? successIconHtmlAttr : {};
    const showFalseSuccessIconObstacles = balconyRailingsObstacle !== false ? {} : successIconHtmlAttr;

    const handleBalconyClicks = (type: string, value: number | boolean) => {
        const payload = {
            balconyGlassAmount: balconyGlassAmount, 
            balconyRailings: balconyRailings, 
            balconyRailingsObstacle: balconyRailingsObstacle,
            balconyRailingMeters: balconyRailingMeters
        }

        switch(type){
            case BALCONY_VALUE_TYPES.AMOUMNT: 
                payload.balconyGlassAmount = value;
                if(value === 0){
                    if(payload.balconyRailings === true){
                        payload.balconyRailingMeters = 1;
                        payload.balconyRailings = false;
                    }else {
                        payload.balconyRailingsObstacle = undefined;
                    }
            
                }
                // railings
                if((value as number) > 0 && payload.balconyRailingMeters > 0){
                    payload.balconyRailingMeters = 0
                    payload.balconyRailings = true
                }
                if(value === 0 && payload.balconyRailings === true){
                    payload.balconyRailingMeters = 1;
                }
                break;
            case BALCONY_VALUE_TYPES.RAILINGS: 
                if(balconyGlassAmount > 0){
                    payload.balconyRailings = value;
                    if(value === false) {
                        payload.balconyRailingsObstacle = undefined;
                    }
                }
                break;
            case BALCONY_VALUE_TYPES.RAILINGS_OBSTACLES: 
                payload.balconyRailingsObstacle = value;
                break;
            case BALCONY_VALUE_TYPES.RAILING_METERS: 
                payload.balconyRailingMeters = value;
                if(value === 0){
                    payload.balconyRailingsObstacle = false
                }
                break;
            default: break;
        }

        dispatch(changeBalconyAmountAction(payload))

    }

    return <>
        {(!isLoading && !error) &&
        <div className="ac-tab-content-wrapper">
            <div className="p-grid p-justify-start">
                <div className="accordion-tab-col p-col">
                    <div className="title">{t('tabs.turning_glasses')}</div>
                    <div className="image">
                        <img src={balcony_class} alt="Parvekelasi"/>
                    </div>
                    <div className="ac-tab-content-action-wrapper">
                        <Button 
                            onClick={() => balconyGlassAmount > 0 && handleBalconyClicks(BALCONY_VALUE_TYPES.AMOUMNT, (balconyGlassAmount-1))}
                            className="minus-btn btn"
                        >
                             <i className="pi pi-minus" ></i>
                        </Button>
                        <input 
                            className="amount" 
                            // type="number"
                            // type needs to be text(default) to let setSelection work. Will there be some downsides for this change??
                            maxLength={4}
                            onClick={(event: any) => {
                                event.target.setSelectionRange(0, event.target.value.length)
                            }}
                            onChange={(e:ChangeEvent<HTMLInputElement>) => {
                                const value = parseInt(e.target.value);
                                handleBalconyClicks(BALCONY_VALUE_TYPES.AMOUMNT, (!isNaN(value) || value > 0) ? value : 0);
                            }}
                            value={balconyGlassAmount} 
                        />
                        <Button 
                            onClick={() => handleBalconyClicks(BALCONY_VALUE_TYPES.AMOUMNT, (balconyGlassAmount+1))}
                            className="plus-btn btn"
                        >
                            <i className="pi pi-plus" ></i>
                        </Button>
                    </div>
                </div>
                <div className="accordion-tab-col p-col">
                    <div className="title">{t('tabs.glass_railing')}</div>
                    <div className="image">
                        <img src={balcony_class_railings} alt="Parvekelasi"/>
                    </div>
                {balconyGlassAmount === 0 && 
                    <div className="ac-tab-content-action-wrapper balcony-meter-wrapper ">
                        <Button 
                            onClick={() => balconyRailingMeters > 0 && handleBalconyClicks(BALCONY_VALUE_TYPES.RAILING_METERS, (balconyRailingMeters-1))}
                            className="minus-btn btn"
                        >
                            <i className="pi pi-minus" ></i>
                        </Button>
                        <input 
                            className="amount" 
                            // type="number" 
                            // type needs to be text(default) to let setSelection work. Will there be some downsides for this change??
                            maxLength={3}
                            onClick={(event: any) => {
                                event.target.setSelectionRange(0, event.target.value.length)
                            }}
                            onChange={(e:ChangeEvent<HTMLInputElement>) => {
                                const value = parseInt(e.target.value);
                                handleBalconyClicks(BALCONY_VALUE_TYPES.RAILING_METERS, (!isNaN(value) || value > 0) ? value : 0);
                            }}
                            value={balconyRailingMeters} 
                        />
                        <Button 
                            onClick={() => handleBalconyClicks(BALCONY_VALUE_TYPES.RAILING_METERS, (balconyRailingMeters+1))}
                            className="plus-btn btn"
                        >
                            <i className="pi pi-plus" ></i>
                        </Button>
                    </div>
                }
                {balconyGlassAmount !== 0 && 
                    <div className="ac-tab-content-action-wrapper select">
                        <Button 
                            onClick={() => !balconyRailings && handleBalconyClicks(BALCONY_VALUE_TYPES.RAILINGS, true)} 
                            className={`selectButton ${balconyRailings && 'checked'}`}
                            label={t('yes')}
                            {...showTrueSuccessIcon}
                        ></Button>
                        <Button 
                            onClick={() => balconyRailings && handleBalconyClicks(BALCONY_VALUE_TYPES.RAILINGS, false)}
                            className={`selectButton ${!balconyRailings && 'checked'}`}
                            label={t('no')}
                            {...showFalseSuccessIcon}
                        ></Button>
                   </div>
                }
                </div>
                <div className={`accordion-tab-col p-col ${(!balconyRailings && balconyRailingMeters === 0) && 'hide'}`}>
                    <div className="title">{t('tabs.glass_railing_obstacles')}</div>
                    <div className="image">
                        <img src={balcony_class_railings_obstacle} alt="Parvekelasi esteellinen"/>
                    </div>
                    <div className="ac-tab-content-action-wrapper  select">
                        <Button 
                            onClick={() => !balconyRailingsObstacle && handleBalconyClicks(BALCONY_VALUE_TYPES.RAILINGS_OBSTACLES, true)} 
                            className={`selectButton ${balconyRailingsObstacle && 'checked'}`}
                            label={t('yes')}
                            {...showTrueSuccessIconObstacles}
                        ></Button>
                        <Button 
                            onClick={() => handleBalconyClicks(BALCONY_VALUE_TYPES.RAILINGS_OBSTACLES, false)}
                            className={`selectButton ${balconyRailingsObstacle === false && 'checked'}`}
                            label={t('no')}
                            {...showFalseSuccessIconObstacles}
                        ></Button>
                   </div>
                </div>    
            </div>
        </div>}
        {isLoading && <div className="loading-content-block">{t('loading')}</div>}
        {(!isLoading && error) && <div className="loading-content-block">{t('error')}</div>}
        </>
};

export default Balcony
