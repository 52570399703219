import React from 'react';

const Title = (props: {type: string, children: React.ReactNode } ) => {
    
    const getClassName = () => {
        switch(props.type){
            case 'h1': return 'title-h1';
            case 'h2': return 'title-h2';
            case 'h3': return 'title-h3';
            default: return 'title-h2'
        }
    }      
    return (
        <div className={getClassName()}>{props.children}</div>
    )
}

export default Title;
