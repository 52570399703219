import { GET, POST } from '../utils/api';

import {
    POST_ADMIN_LOGIN,
    GET_ADMIN_PROFILE,
    POST_ADMIN_LOGOUT
} from '../admin/auth/constants';
import { IReduxAction } from './types';


/*
 * admin user action creators
 */

export const loginAction = (body: object):IReduxAction => ({
    type: POST_ADMIN_LOGIN,
    payload: POST(`auth/login`, body)
})

export const logoutAction = (body: object):IReduxAction => ({
    type: POST_ADMIN_LOGOUT,
    payload: POST(`auth/logout`, body)
})


export const loadAdminUserAction = (id: number = NaN):IReduxAction => ({
    type: GET_ADMIN_PROFILE,
    payload: GET(`auth${!isNaN(id) ? ('/' + id) : '' }`)
})

